import { createSlice } from "@reduxjs/toolkit";
import { fetchAllAppointmentData } from "../../Thunk/Appointment/Api_Services/getAllAppointmentsThunk";

export interface AppState {
    AllAppointmentData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    AllAppointmentData: [],
    isLoading: false,
    error: null,
};

const AllProviderAppointmentSlice = createSlice({
    name: "AppointmentData",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAppointmentData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllAppointmentData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AllAppointmentData = action.payload;
            })
            .addCase(fetchAllAppointmentData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })

    },
});

export const { resetState } = AllProviderAppointmentSlice.actions;

export default AllProviderAppointmentSlice;
