
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface FetchLeaveRequestByDateArgs {
    from_date_time: string;
    to_date_time: string;
    authToken: string;
}
interface LeaveEvent {
    leave_posted_datetime: string;
    status: string;
    event_id: string;
    image_path: string;
    provider_id: string;
    leave_description: string;
    provider_name: string;
    to_date_time: string;
    notification_type: string;
    from_date_time: string;
}

// Define the return type for the Thunk action
interface LeaveRequestByDateResponse {
    data: LeaveEvent[];
    messages: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchLeaveRequestByDate = createAsyncThunk<LeaveRequestByDateResponse, FetchLeaveRequestByDateArgs, { state: RootState }>(
    'admin/getLeaveRequestByDate',
    async ({ from_date_time, to_date_time, authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.leave_requests.get_leave_request_by_date}?from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
            const response = await get(ApiEndPoint, authToken);

            if (response) {
                return response.data; // This value will be the payload of the fulfilled action
            } else {
                throw new Error('Failed to Fetch Leave Requests');
            }
        } catch (error: any) {
            throw error; // This will trigger the rejected action
        }
    }
);
