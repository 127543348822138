import { createSlice } from "@reduxjs/toolkit";
import { fetchAppointmentData } from "../../Thunk/Appointment/getAppointmentsThunk";
import { fetchAppointmentByServiceThunk } from "../../Thunk/Appointment/Api_Services/GetAppointmentByServiceThunkThunk";

export interface AppState {
    AppointmentData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    AppointmentData: [],
    isLoading: false,
    error: null,
};

const AppointmentSlice = createSlice({
    name: "AppointmentData",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppointmentData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAppointmentData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AppointmentData = action.payload;
            })
            .addCase(fetchAppointmentData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(fetchAppointmentByServiceThunk.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAppointmentByServiceThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AppointmentData = action.payload;
            })
            .addCase(fetchAppointmentByServiceThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });

    },
});

export const { resetState } = AppointmentSlice.actions;

export default AppointmentSlice;
