import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { get } from "../../../Network/ApiService";

interface FetchNotificationPreferencesArgs {
    accountId: string;
    authToken:string;
}

interface NotificationPreferences {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}
export const fetchNotificationPreferences = createAsyncThunk<NotificationPreferences, FetchNotificationPreferencesArgs, { state: RootState }>(
    "scheduling/fetchNotificationPreferences",
    async ({ accountId,authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.notificationPreferences.get_notification_preferences}?accountId=${accountId}`;

            const response = await get(ApiEndPoint, authToken);
            if (response) {               
                return response.data;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
)