import { faAngleDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import "./cbSelect.scss";
import { USAStatesCB } from "../../utils";

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  defaultOption?: Option;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
  seletedItem?: any;
  type?: string;
}

const CBSelectState: React.FC<Props> = (props) => {
  const { options, defaultOption, onChange, placeholder, className, seletedItem } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [stateError, setStateError] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState({ label: seletedItem, value: seletedItem });
  const [filterText, setFilterText] = useState<string>("" + seletedItem);
  const dropdownRef: any = useRef<any>(null);
  const inputRef: any = useRef<HTMLInputElement>(null);

  if (props.type === "Specialities") {
    options.sort((a: any, b : any) => {
      const lableA = a.label.toLowerCase();
      const lableB = b.label.toLowerCase();
      if (lableA < lableB) {
        return -1;
      }
      if (lableA > lableB) {
        return 1;
      }
      return 0;
    })
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (option: Option) => {
    setSelectedOption(() => option);
    const matchingState = USAStatesCB.find(state => state?.value?.toUpperCase() === option?.value?.toUpperCase() || state?.label?.toUpperCase() === option?.value?.toUpperCase());
    if (!matchingState) {
      setStateError(true)
    } {
      setStateError(false)
    }
    !!onChange && onChange(option?.value);
    setFilterText(option?.value);
    if (option?.label === '') {

      setOpen(true);

    }
    else {
      setOpen(false);
    }
  };

  const handClick = () => {
    setOpen(!open);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setFilterText(event.target.value.charAt(0));
    if (event.target.value === '') {
      handleChange({ label: '', value: '' })

    } else {

    }
    const inputValue = event.target.value.toUpperCase(); // Convert input value to uppercase for case-insensitive comparison
    setFilterText(inputValue);

    const matchingState = USAStatesCB.find(state => state?.value?.toUpperCase() === inputValue?.toUpperCase());
    const matchingStateLabel = USAStatesCB.find(state => state?.label?.toUpperCase() === inputValue?.toUpperCase());
    if (!matchingState && inputValue !== '' && inputValue?.length === 2) {
      handleChange({ label: '', value: inputValue })
      setStateError(true)
    }
    else if (!matchingStateLabel && inputValue !== '' && inputValue?.length > 2) {
      handleChange({ label: '', value: inputValue })
      setStateError(true)
    } else {
      handleChange({ label: '', value: inputValue })
      setStateError(false)

    }
    // if (!matchingStateLabel && inputValue !== ''&& inputValue?.length>2) {

    // } else {
    //   handleChange({ label:'',value: inputValue})
    //   setStateError(false)

    // }
    setFilterText(event.target.value);
  };
  const filteredOptions = options.filter((option) => {
    if (props.type !== "Specialities") {
      if (seletedItem?.length === 2) {
        if (selectedOption?.value == filterText) {
          return option.label.toLowerCase()
        } else {
          return option?.label?.toLowerCase()?.startsWith(filterText?.toLowerCase())
        }
      } else {
        if (!filterText) {
          return option?.label?.toLowerCase()
        }
        if (filterText) {
          return option?.label?.toLowerCase()?.startsWith(filterText?.toLowerCase())
        }
      }
    } else {
      if (selectedOption?.value == filterText) {
        return option.label.toLowerCase()
      } else {
        return option?.label?.toLowerCase()?.includes(filterText?.toLowerCase())
      }
    }
  }
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      // event.preventDefault();
      inputRef.current.blur();
      if (filteredOptions[0]) {
        setStateError(false)
      }
      handleChange(filteredOptions[0]);
      // Prevent the default behavior (opening file dialog)
      // inputRef.current.focus();
      // setOpen(false);
    }
  };
  return (
    <div ref={dropdownRef} className={`cb-select-wrapper ${className}`}>

      <input
        ref={inputRef}
        type="text"
        value={filterText}
        className={` cb-select-wrapper ${className} cb-select ${open ? "cb-select-active" : ""} ${stateError ? 'error-border' : ''}`}
        onChange={handleInputChange}
        onClick={handClick}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        style={{ outline: "none" }}
        autoComplete="new-select"
      />
      {/* {selectedOption ? selectedOption.label : placeholder} */}
      {/* <div className="icon-div">
          {className === "break-dropdown" ? (
            <FontAwesomeIcon icon={faAngleDown} color="#000000" size="sm" />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} color="#000000" />
          )}
        </div> */}

      {open && (
        <div className={`cb-select-options-wrapper `}>
          {filteredOptions.map((option: Option, index: number) => (
            <li
              key={`${index}-${option.value}-cb-select-box`}
              onClick={() => handleChange(option)}
            >
              {option.label}
            </li>
          ))}
        </div>
      )}
    </div>
  );
};


export default CBSelectState;
