
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../../index';

interface FetchProviderRoomsDataArgs {
    authToken: string;
    userId:string;
}

// Define the return type for the Thunk action
interface ProviderRoomsData {
    userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchProviderRooms = createAsyncThunk<ProviderRoomsData, FetchProviderRoomsDataArgs, { state: RootState }>(
    'profile/fetchproviderRooms',
    async ({ userId,authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.user_profile.get_providers_details_in_account}?adminId=${userId}`;
            const response = await get(ApiEndPoint, authToken);

            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch Provider Rooms Data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
