import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../Store';
import { reset, setIsUserSignIn, setLanguage, setPlan } from '../../../../Store/Slicers/commonSlicer';

// Socket
import { useWebSocket } from '../../../../SocketConnection/WebSocketContext';

// utils
import { logoutHandler } from '../../Utils/AuthHandlers';

export const useHeader = () => {
    const { themeColor, plan } = useSelector((state: RootState) => state.app);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { disconnectWebSocket } = useWebSocket();

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(setPlan(event.target.value));
    };
    const langaugeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(setLanguage(e.target.value));
    };

    const logout = () => {
        disconnectWebSocket();
        logoutHandler();
        console.log("Patient logout successfull!")
        dispatch(setIsUserSignIn(false))
        dispatch(reset())
    }

    const handleSet = (value: string) => {
        navigate('/home')
        dispatch(setPlan(value));
    }
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handleClinic = (value: string) => {
        navigate('/patient/meeting-screen')
        dispatch(setPlan(value));
    }

    return {
        navigate,
        themeColor,
        plan,
        handleSelectChange,
        langaugeHandler,
        logout,
        handleSet,
        capitalizeFirstLetter,
        handleClinic
    }
}
