import React, { useEffect } from 'react'
import Loader from './Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ToastMessage } from './ToastMessage'

const NavigateComponent = () => {

    const navigate = useNavigate()

    const currunt_url = new URL(window.location.href)
    const hostname = currunt_url.hostname
    const validHostnames = ["clinic.centiblickconnect.com", "provider.centiblickconnect.com",'localhost', 'a086-2401-4900-1f3f-d70e-7132-7ef7-95f7-9b8c.ngrok-free.app', 'troymedical.centiblickconnect.com'];

    const dynamicUrl = (hostname: any) => {
        return hostname.includes('ngrok-free.app');
    };

    const pathname = currunt_url.pathname;

    const pathSegments = pathname.split('/');

    const code = pathSegments[pathSegments.length - 1];

    useEffect(() => {
        // Check if redirect_url is already set
        const redirectUrl = sessionStorage.getItem('redirect_url');
        const room_id = sessionStorage.getItem('room_id');

        if ((validHostnames.includes(hostname) || dynamicUrl(hostname)) && (pathname.startsWith('/provider/') || pathname.startsWith('/patient/')) && code !== 'login') {
            // Set redirect_url only if it's not already set
            if (!redirectUrl) {
                sessionStorage.setItem('redirect_url', currunt_url.href);
            }
            if (!room_id) {
                sessionStorage.setItem('room_id', code);
            }
            navigate('/patient/waiting-screen');
        } else if (!code || code === 'login') {
            navigate('/waiting-room');
            ToastMessage("error", 'No meeting Id found please select your Clinician room.');
        } else if ((hostname === "centiblickconnect.com" || hostname === "localhost") && (pathname.startsWith('/provider/') || pathname.startsWith('/patient/')) && code !== 'login') {
            navigate('/patient-demo-login');
        }
    }, []);

  return (
    <Loader/>
  )
}

export default NavigateComponent