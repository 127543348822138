// profileInformationThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { post } from '../../../Network/ApiService';
import { RootState } from '../..';
import { fetchAdminProfileData } from '../getProfileData/adminProfileThunk';

interface StoreAdminProfileDataArgs {
    authToken: string;
    data: any; // Adjust the type of data to match your data structure
    userId: string;
}

// Define the return type for the Thunk action
interface AdminDataResponse {
    status: number;
    message: string;
}

// Create the Thunk action
export const storeAdminProfileData = createAsyncThunk<AdminDataResponse, StoreAdminProfileDataArgs, { state: RootState }>(
    'profile/adminprofile',
    async ({ authToken, data, userId }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.user_profile.save_admin_profile}?userId=${userId}`;
            const successResponse = await post(ApiEndPoint, authToken, data);

            if (successResponse.status === 200) {
                dispatch(fetchAdminProfileData({ userId, authToken }));
                return { status: 200, message: 'Data saved successfully' };
            } else {
                return { status: successResponse.status, message: 'Error in saving Data' };
            }
        } catch (error) {
            return { status: 500, message: 'Internal Server Error' };
        }
    }
);
