import React, { useEffect, useState } from 'react';
import './WaitingRoomPage.scss';
import { useNavigate } from 'react-router-dom';
import { LoginPopupBG } from '../../../Assets/Svgs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../Store';
import { capitalizeEachWord } from '../../../Components/utils';
import Loader from '../../../Components/Common/Loader/Loader';
import { fetchEntryPageProviders } from '../../../Store/Thunk/WaitingRoomData/getWaitingRoomThunk';

const WaitingRoomPage = () => {
    const { EntryPageProviders } = useSelector((state: RootState) => state.entryPageProviders);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedProviderId, setSelectedProviderId] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);

    const fetchData = async () => {
        setLoader(true);
        const response: any = await dispatch(fetchEntryPageProviders({ customer_domain: 'troymedical' }));
        console.log("🚀 ~ fetchData ~ response:", response);
        setLoader(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRoom = () => {
        navigate(`/provider/${selectedProviderId}`);
    };

    return (
        <>
            {
                loader ? <Loader />
                    :
                    <>
                        <div className="background-container">
                            <img src={LoginPopupBG} alt="" />
                        </div>
                        <div className='waiting-room-page w-100 d-flex justify-content-center align-items-center'>
                            <div className='waiting-div text-center'>
                                <h1> Select Your Clinician’s Room Name</h1>
                                <div className="row waiting-room-details justify-content-center">
                                    {EntryPageProviders?.map((clinician: any, index: any) => (
                                        <div key={index} className="col-lg-3 col-md-6 col-sm-6 mb-3">
                                            <button
                                                className={`room-name text-truncate w-100 ${selectedProviderId === clinician.user_id ? 'active-room' : ''}`}
                                                onClick={() => setSelectedProviderId(clinician.user_id)}
                                            >
                                                {capitalizeEachWord(clinician.first_name + " " + clinician.last_name)}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <button 
                                    className='rounded-save-theme-btn' 
                                    onClick={handleRoom} 
                                    disabled={!selectedProviderId} // Disable if no provider is selected
                                >
                                    Enter Room
                                </button>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default WaitingRoomPage;
