import React, { useState } from 'react'
// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store';

// components
import Navbar from './Navbar';
import HomeDeviceListing from './HomeDeviceListing/HomeDeviceListing';

// utils
import { getInitials, lightenColor } from '../../../../../Components/utils';

// assets
import images from '../../../../../Assets/Images';
import { ImageUploadSquare } from '../../../../../Assets/Svgs';

interface SidebarComponentProps {
  ComponentTitle?: "Standard Platform" | "Customize",
  type?: string;
  className?: string
}
const Sidebar = ({ ComponentTitle, type, className }: SidebarComponentProps) => {
  const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)
  const { isfinishedSaving, selectedType } = useSelector((state: RootState) => state.onBoarding)

  const [isChecked, setIsChecked] = useState(false);


  if (ComponentTitle === "Customize") {
    const styleElement = document.createElement('style');

    const cssRule = `#inner-screen, #inner-screen * { color: ${selectedPrimaryColor}; ::-webkit-scrollbar-thumb { background: ${selectedPrimaryColor}; } }`;

    styleElement.appendChild(document.createTextNode(cssRule));
    document.head.appendChild(styleElement);
  }

  function handleProfileName() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return handleName();

      case 'Customize':
        return "John Doe";

      default:
        return handleName();
    }
  }
  function handleProfileButtonName() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return "my Account";

      case 'Customize':
        return "Update my Account";

      default:
        return selectedType === "Provider" ? "Update Profile" : "Update my Account"
    }
  }

  const handleName = () => {
    switch (selectedType) {
      case "Patient":
        return "Jane Smith";
      case "Provider":
        return "Dr. Kelly Warren";
      case "Admin":
        return "Pamela McCoy"
      default:
        return 'Jane Doe'
    }
  }

  const handleDegree = () => {
    switch (selectedType) {
      case "Patient":
        return "";
      case "Provider":
        return "Primary Care, MD, PhD";
      case "Admin":
        return "Virtual Care Administration"
      default:
        return ''
    }
  }

  const handleImageChange = () => {
    return handleImage();
  }

  const handleImage = () => {
    switch (selectedType) {
      case "Patient":
        return images.sidebarJane;
      case "Provider":
        return images.timeHeaderImg;
      case "Admin":
        return images.adminHomePamel;
    }
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const filteredAdminCarouselDetails = [
    {
      name: "Patient Profiles",
      image: CarouselImage({ ComponentTitle })

    },
    {
      name: "Encounters",
      image: CarouselImage({ ComponentTitle })

    },
    {
      name: "Analytics",
      image: CarouselImage({ ComponentTitle })

    },
  ]

  function CarouselImage({ ComponentTitle }: any) {
    switch (ComponentTitle) {
      case "Customize":
        // Handle Customize case based on itemType
        return [images.carouselPatientProfile, images.Encounters, images.Analytics];


      case "Standard Platform":
        // Handle Standard Platform case based on itemType
        return [images.carouselPatientProfile, images.Encounters, images.Analytics];

      default:
        return [ImageUploadSquare];
    }
  }

  const buttonStyle = {
    background: `radial-gradient(
      107.08% 85.59% at 86.3% 87.5%,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0) 86.18%
    ),
    radial-gradient(
      83.94% 83.94% at 26.39% 20.83%,
      rgba(255, 255, 255, 0.41) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${selectedPrimaryColor}`,
    border: `1px solid ${selectedPrimaryColor}`,
    cursor: 'pointer',
    color: 'white',
    fontWeight: "600",
    borderRadius: "50px"
  };

  return (
    <div className={`d-flex position-relative z-3 h-100 laptop-modal-content ${className}`} style={{ background: "#ffffff" }} id={ComponentTitle === "Customize" ? "inner-screen" : undefined}>
      <div
        className="side-screen-wraper clinicOnboarding-sidebar d-md-block col-md-2 z-1 "
        style={{ display: type === "toggle-sidebar" ? "" : "none", background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8), }}

      >
        <div className={`sidebar screen-sidebar ${selectedType === "Admin" && "admin-navbar"} ${selectedType === "Provider" && "provider-navbar"}`}>
          <div
            className="sidebar-user patient-sidebar-user d-md-flex p-0"
            style={{ display: "none" }}
          >
            {ComponentTitle === 'Standard Platform' && <img
              className="cursor-pointer"
              src={handleImageChange()}
              alt="user"
            />}
            {ComponentTitle === "Customize" && <div className='default-name' style={{ borderColor: selectedPrimaryColor, }}>
              {getInitials(handleProfileName())}
            </div>}
            <div className='text-center'>
              <h2 className="cursor-pointer commonFont-10">
                {handleProfileName()}
                {/* Patient 001 */}
              </h2>
              {/* <p className={`education ${selectedType == "Patient" && 'd-none'}`}>{handleDegree()}</p> */}
            </div>
            {(selectedType === "Patient" || selectedType === "Provider") && (
              <button
                className="btn account-btn theme-btn shadow-none w-75"
                style={{
                  color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                  107.08% 85.59% at 86.3% 87.5%,
                  rgba(0, 0, 0, 0.23) 0%,
                  rgba(0, 0, 0, 0) 86.18%
                ),
                radial-gradient(
                  83.94% 83.94% at 26.39% 20.83%,
                  rgba(255, 255, 255, 0.41) 0%,
                  rgba(255, 255, 255, 0) 69.79%,
                  rgba(255, 255, 255, 0) 100%
                ),
                ${selectedPrimaryColor}`
                }}
              >
                my Profile
                {/* {selectedType === "Provider" ? "Update Profile" : "Update my Account"} */}
              </button>
            )}

            {selectedType == "Admin" &&
              <div className='row gap-2 justify-content-center w-100'>
                <button
                  className="col-md-5 p-2 btn account-btn theme-btn shadow-none "
                  style={{
                    color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                    107.08% 85.59% at 86.3% 87.5%,
                    rgba(0, 0, 0, 0.23) 0%,
                    rgba(0, 0, 0, 0) 86.18%
                  ),
                  radial-gradient(
                    83.94% 83.94% at 26.39% 20.83%,
                    rgba(255, 255, 255, 0.41) 0%,
                    rgba(255, 255, 255, 0) 69.79%,
                    rgba(255, 255, 255, 0) 100%
                  ),
                  ${selectedPrimaryColor}`
                  }} >
                  Update Profile
                </button>
                <button
                  className="col-md-5 p-2 btn account-btn theme-btn shadow-none" style={{
                    color: "#fff", background: ComponentTitle === "Standard Platform" ? "" : `radial-gradient(
                    107.08% 85.59% at 86.3% 87.5%,
                    rgba(0, 0, 0, 0.23) 0%,
                    rgba(0, 0, 0, 0) 86.18%
                  ),
                  radial-gradient(
                    83.94% 83.94% at 26.39% 20.83%,
                    rgba(255, 255, 255, 0.41) 0%,
                    rgba(255, 255, 255, 0) 69.79%,
                    rgba(255, 255, 255, 0) 100%
                  ),
                  ${selectedPrimaryColor}`
                  }} >
                  Schedule
                </button>
              </div>
            }
          </div>
          {/* <SidebarButtons ComponentTitle={ComponentTitle} /> */}

          {selectedType === "Provider" &&
            <>
              <div className={`patient-carousel z-1`}>
                {filteredAdminCarouselDetails.map((item: any, index: any) => {
                  return (
                    <div className={`new-carousel `} key={`carousel_item_${index}`}>
                      <div
                        className="ch-card-item admin-home-ch mb-2"
                        key={`carousel_item_${index}`}
                      >
                        <div className="ch-card ch-card onboarding-provider-ch-card admin-ch-card pe-none p-2" style={{ backgroundColor: ComponentTitle === "Standard Platform" ? "" : selectedSecondaryColor }}>
                          {/* {
                            ComponentTitle === "Customize" &&
                            <div className='p-2' style={{ background: "#fff", borderRadius: "4px", border: item.image[index] === ImageUploadSquare ? `1px solid ${selectedPrimaryColor}` : `1px solid ${selectedPrimaryColor}` }}>
                              {item.image ? (
                                item.image[index]?.includes('<svg') ? (
                                  <div dangerouslySetInnerHTML={{ __html: item.image[index] }} />
                                ) : (
                                  <>
                                    <img src={ImageUploadSquare} alt="first img" width={50} height={40} className='m-0' style={{ borderRadius: 0 }} />
                                  </>
                                )
                              ) : (
                                <img src={ImageUploadSquare} alt="Default img" />
                              )}
                            </div>
                          }
                          {
                            ComponentTitle === "Standard Platform" &&
                          } */}
                          <div style={{ borderRadius: "4px", border: item.image ? "none" : `1px solid ${selectedPrimaryColor}` }} className='standard-image'>
                            <img src={item.image && item.image[index]} alt="first img" className='mb-0' />
                          </div>
                          <h3 className='commonFont-10'>{item.name}</h3>
                        </div>
                      </div>

                    </div>
                  );
                })}
              </div>
            </>
          }

          {selectedType === "Admin" &&
            <div className="admin-laptop-screen p-0 h-100" style={{ border: ComponentTitle === "Standard Platform" ? "1px solid #516cd3" : `1px solid ${selectedPrimaryColor}` }}>
              <div className="task-list-wrapper home-screen">
                <>
                  <div className="today-outer-content">
                    <h3 className="pt-2 ps-2 mb-2">Provider Rooms</h3>
                    <div className="task-list-box px-2"
                    >
                      <div className="provider-room d-flex flex-column gap-2">
                        <div className="provider-room-record d-flex flex-column" style={{ background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8) }}>
                          <h1 className='commonFont-10'>Dr. De Marco</h1>
                          <div className="provider-room-link d-flex flex-column p-2">
                            <input className="text-truncate" readOnly value={"https://centiblickconnect.com/provider/Kelly/aec47eef-93ed-40e8-b702-1bb14485a9de"} />
                            <button className="commonFont-10 p-1" style={buttonStyle} >{!isfinishedSaving ? "Copy Room Link" : "Copy"}</button>
                          </div>
                        </div>
                        <div className="provider-room-record d-flex flex-column" style={{ background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8) }}>
                          <h1 className='commonFont-10'>Dr. Kelly Warren </h1>
                          <div className="provider-room-link d-flex flex-column p-2">
                            <input className="text-truncate" readOnly value={"https://centiblickconnect.com/provider/Kelly/aec47eef-93ed-40e8-b702-1bb14485a9de"} />
                            <button className="commonFont-10 p-1" style={buttonStyle} >{!isfinishedSaving ? "Copy Room Link" : "Copy"}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          }
        </div>
      </div>
      {type === "toggle-sidebar" ? null : <HomeDeviceListing ComponentTitle={ComponentTitle} />}
    </div>
  )
}

export default Sidebar