import { createSlice } from "@reduxjs/toolkit";
import { fetchAdminProfileData } from "../../Thunk/getProfileData/adminProfileThunk";
export interface AppState {
  AdminUserData: any;
  AdminProfileModal:boolean;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  AdminUserData: null,
  AdminProfileModal:false,
  isLoading: false,
  error: null,
};

const AdminProfileSlice = createSlice({
  name: "adminprofile",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState, 
  },
  extraReducers: (builder) => {

    builder
      .addCase(fetchAdminProfileData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAdminProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.AdminUserData = action.payload;
        if (action.payload && !state.AdminUserData[0]?.first_name && !state.AdminUserData[0]?.last_name && !state.AdminUserData[0]?.phone) {
          state.AdminProfileModal = true;
        } else {
          state.AdminProfileModal = false;
        }
      })
      .addCase(fetchAdminProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default AdminProfileSlice;
