import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const usePricing = () => {
  const faqItems = [
    {
      question: "How do I know if my current telehealth platform is compliant?",
      answer: '',
    },
    {
      question: "Is our payment processing system HIPAA compliant?",
      answer: "To determine if your payment processing system is HIPAA compliant, ensure it includes necessary safeguards like encryption, secure access controls, and proper handling of protected health information (PHI). Non-compliant systems can expose you to data breaches and legal penalties. If your system lacks these features, it may not meet HIPAA standards, and you should consider switching to a compliant solution to protect patient data and avoid regulatory risks.",
    },
    {
      question: "Are we protected from fines and penalties with our BAA?",
      answer: "While a Business Associate Agreement (BAA) is essential for HIPAA compliance, it does not fully shield you from legal and financial consequences in the event of a breach. Even with a BAA in place, your practice remains responsible for ensuring that both you and your vendor adhere to HIPAA guidelines. If a breach occurs due to inadequate security measures, you could still face penalties and legal liabilities. A BAA is a crucial part of compliance, but it is not a complete safeguard.",
    },
    {
      question: "Can other providers using the same platform cause HIPAA Violations for our practice?",
      answer: "Yes, if your telehealth platform allows free accounts without strict provider verification, other providers could cause HIPAA violations. For example, a fake provider could create an account, spoof patient identities, and access sensitive data, putting your practice at risk. CentiBlick mitigates this by enforcing rigorous provider authentication, ensuring all users are verified healthcare professionals, protecting your practice from these risks.",
    },
    {
      question: "Am I locked into a contract?",
      answer: "Not at all! We don’t tie you down with a contract. We believe in keeping our customers with a great product and exceptional customer support. You can cancel at any time.",
    },
    {
      question: "Do you charge state tax?",
      answer: "If your state or country is subject to sales tax, VAT, or GST, you will see the rate and amount on the final purchase price before confirming the payment.",
    },
  ];

  const [pricingPlan, setPricingPlan] = useState('month');
  const [isOnboarding, setIsOnboarding] = useState(false)

  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleAccordionToggle = (index: any) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleButtonClick = (plan: string) => {
    setPricingPlan(plan);
  };

  const navigate = useNavigate()
  return {
    pricingPlan,
    handleButtonClick,
    faqItems,
    openAccordionIndex,
    handleAccordionToggle,
    navigate,
    setIsOnboarding,
    isOnboarding
  };
};
