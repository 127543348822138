import { createSlice } from "@reduxjs/toolkit";
import { fetchUpcomingAppointmentData } from "../../Thunk/Appointment/getUpcomingAppointmentThunk";

export interface AppState {
    UpcomingAppointmentData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    UpcomingAppointmentData: [],
    isLoading: false,
    error: null,
};

const UpcomingAppointmentSlice = createSlice({
    name: "UpcomingAppointmentData",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpcomingAppointmentData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchUpcomingAppointmentData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.UpcomingAppointmentData = action.payload;
            })
            .addCase(fetchUpcomingAppointmentData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default UpcomingAppointmentSlice;
