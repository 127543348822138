import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../..";
import { ApiUrl } from "../../../../Network/ApiUrl";
import { get } from "../../../../Network/ApiService";


interface FetchAppointmentDataArgs {
    from_date_time: string;
    to_date_time: string;
    provider_id: string;
    service_id: string;
    patient_id: string;
    type: 'provider' | 'patient';
    authToken: string;
}

// Define the return type for the Thunk action
interface AppointmentData {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchAppointmentByServiceThunk = createAsyncThunk<AppointmentData, FetchAppointmentDataArgs, { state: RootState }>(
    'appointment/GetAppointmentByServiceThunk',
    async ({ from_date_time, to_date_time, provider_id, service_id, patient_id, type, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint;
            if (type === 'provider') {
                ApiEndPoint = `${ApiUrl.appointments.services_api.get_provider_appointments_by_service}?from_date_time=${from_date_time}&to_date_time=${to_date_time}&provider_id=${provider_id}&service_id=${service_id}&patient_id=${patient_id}`;
            } else {
                ApiEndPoint = '';
            }

            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
