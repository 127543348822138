const Signup = {
  create_your_account: "Créez votre compte",
  check_me_out: "Vérifiez-moi",
  or_log_in_with_email: "Ou connectez-vous avec e-mail",
  verify_your_account: "Vérifiez votre compte",
  did_not_received_the_code: "Vous n'avez pas reçu le code?",
  resend: "Renvoyer",
  confirmation_code: "Entrez le code de confirmation",
  select_account_identifier: "Sélectionnez l'identifiant du compte",
  enter_account_identifier: "Saisir l'identifiant du compte",
  did_not_received_the_identifier: "Vous n'avez pas reçu l'identifiant?",
  account_registered_successfully: "Compte enregistré avec succès",
  create_your_administrator_account :'Créez votre compte Administrateur',
  i_have_read_the: "j'ai lu le", 
};
export default Signup;
