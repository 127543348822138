import React from 'react'
import images from '../../../../Assets/Images'
import { useNavigate } from 'react-router-dom'

//css
import '../../../ClinicOnboarding/ClinicOnBoarding.scss'

const SuccessPage = () => {

    const navigate = useNavigate()

    return (
        <div>
            <div className="modal-backdrop" />
            <div
                className={`modal fade show`}
                style={{ display: "block" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl`}
                >
                    <div className="modal-content p-0 shadow-none border-0 overflow-visible">
                        <div className="modal-body p-0" style={{ minWidth: "fit-content" }}>
                            <div className='onboarding-page commonOnboarding' style={{ minWidth: "fit-content" }}>
                                <img src={images.Logo} alt="" className='onboard-logo cursor-pointer' onClick={() => navigate('/')} />
                                <div className={`d-flex onboarding-display w-100`} >
                                    <div className='fifth-step'>
                                        <div className='d-flex flex-column telehealth-step mx-auto ' style={{ gap: "32px" }}>
                                            <div className='text-center'>
                                                <h1>Congratulations! 🎉</h1>
                                                <p className='onboarding-detail pt-4'>Your platform has been set up. </p>
                                            </div>
                                            <div className='text-center'>
                                                <img src={images.MailLogo} alt="" />
                                            </div>
                                            <div className='d-flex flex-column telehealth-flow'>
                                                <div className='text-center telehealth'>
                                                    <div className='d-flex flex-column gap-3 align-items-center'>
                                                        <img src={images.authLogo} alt="" width={40} height={32} />
                                                        <div>
                                                            <span className='fifth-step-text'>You will receive an email with login instructions</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessPage