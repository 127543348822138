// utils components
import routeHandlers from "../../../../routeHandler";

export const SideBarNavigationMenuOptionsList = [
  {
    name: "Profile",
    urlPathName: "profile",
    iconImgName: "navProfile",
    dropDownOptions: [
      {
        name: "Patient Demographics",
        urlPathName: "patient-demographics",
        url: routeHandlers["PatientDemographics"]["url"],
      },
      {
        name: "Allergies and Intolerances",
        urlPathName: "allergies-and-intolerance",
        url: routeHandlers["AllergiesAndIntolerances"]["url"],
      },
      {
        name: "Vaccinations",
        urlPathName: "vaccinations",
        url: routeHandlers["Vaccinations"]["url"],
      },
      {
        name: "Smoking Status",
        urlPathName: "smoking-status",
        url: routeHandlers["SmokingStatus"]["url"],
      },
    ],
  },
  {
    name: "Care Teams",
    urlPathName: "care-team",
    iconImgName: "navCareTeams",
    dropDownOptions: [
      {
        name: "Care Team​s",
        urlPathName: "care-teams",
        url: routeHandlers["CareTeams"]["url"],
      },
      {
        name: "Providers",
        urlPathName: "providers",
        url: routeHandlers["Providers"]["url"],
      },
    ],
  },
  {
    name: "Care History",
    urlPathName: "care-history",
    iconImgName: "navCareHistory",
    dropDownOptions: [
      {
        name: "Conditions",
        urlPathName: "conditions",
        url: routeHandlers["Conditions"]["url"],
      },
      {
        name: "Encounters",
        urlPathName: "encounters",
        url: routeHandlers["Encounters"]["url"],
      },
      {
        name: "Treatment Plans",
        urlPathName: "treatment-plan",
        url: routeHandlers["TreatmentPlan"]["url"],
      },
    ],
  },
  {
    name: "Medications",
    urlPathName: "medications",
    iconImgName: "navMedications",
    dropDownOptions: [
      {
        name: "Medications",
        urlPathName: "medications",
        url: routeHandlers["Medications"]["url"],
      },
    ],
  },
  {
    name: "Procedures",
    urlPathName: "procedures",
    iconImgName: "navProcedures",
    dropDownOptions: [
      {
        name: "Procedures",
        urlPathName: "procedures",
        url: routeHandlers["Procedures"]["url"],
      },
      {
        name: "Devices",
        urlPathName: "devices",
        url: routeHandlers["Devices"]["url"],
      },
    ],
  },
  {
    name: "Health Testing",
    urlPathName: "health-testing",
    iconImgName: "navHealthTesting",
    dropDownOptions: [
      {
        name: "Health Screen",
        urlPathName: "health-screen",
        url: routeHandlers["HealthScreen"]["url"],
      },
      {
        name: "Lab Tests",
        urlPathName: "lab-test",
        url: routeHandlers["LabTest"]["url"],
      },
      {
        name: "Lab Panels",
        urlPathName: "lab-panels",
        url: routeHandlers["LabPanels"]["url"],
      },
      {
        name: "Vitals",
        urlPathName: "vitals",
        url: routeHandlers["Vitals"]["url"],
      },
    ],
  },
];

export const SideBarNavigationButtonsList = [
  // {
  //   name: 'Learning Health System',
  //   urlPathName: 'profile',
  //   iconImgName: 'navLearningHealthSystem',
  //   url: routeHandlers['LearningHealthSystem']['url'],
  // },
  // {
  //   name: 'Goal',
  //   urlPathName: 'profile',
  //   iconImgName: 'navLearningHealthSystem',
  //   url: routeHandlers['Goal']['url'],
  // },
];
