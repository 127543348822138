import React from 'react'

interface SidebarSkeletonType {
  type?: "patient" | "provider" | "admin";
  className?: string
}

const SidebarSkeleton = ({ type, className }: SidebarSkeletonType) => {
  return (
    <div className='placeholder-glow d-flex gap-1 align-items-center'>
      <div className='d-flex flex-column gap-4 w-100 align-items-center'>
        <div className={`placeholder ${className}`}></div>
        <div className='d-flex flex-column gap-3 mb-3 w-100 align-items-center'>
          <div className='placeholder sidebar-btn-placeholder'></div>
          {
            type === "admin" ?
              <div className='w-100 d-flex gap-3 justify-content-center'>
                <div className='placeholder admin-sidebar-btn-placeholder'></div>
                <div className='placeholder admin-sidebar-btn-placeholder'></div>
              </div> :
              <div className='placeholder sidebar-btn-placeholder'></div>
          }
        </div>
      </div>
    </div>
  )
}

export default SidebarSkeleton