
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../../index';

interface FetchProviderContactHistory {
  userId: string;
  authToken: string;
}

// Define the return type for the Thunk action
interface ProviderContactHistoryData {
  userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchProviderContactHistory = createAsyncThunk<ProviderContactHistoryData, FetchProviderContactHistory, { state: RootState }>(
  'contactHistory/fetchProviderContactHistory',
  async ({ userId, authToken }, { dispatch }) => {
    try {
      const ApiEndPoint = `${ApiUrl.contact_history.provider_contact_history}?userId=${userId}`;
      const response = await get(ApiEndPoint, authToken);

      if (response) {
        return response.data; // This value will be the payload of the fulfilled action
      } else {
        throw new Error('Failed to fetch Contact History data');
      }
    } catch (error) {
      throw error; // This will trigger the rejected action
    }
  }
);
