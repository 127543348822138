
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../..';

interface FetchProviderDetailsArgs {
    userId: string;
}

interface ProviderDetails {
    userData: any;
}

// Create the Thunk action
export const fetchProviderDetails = createAsyncThunk<ProviderDetails, FetchProviderDetailsArgs, { state: RootState }>(
    'profile/fetchProviderDetails',
    async ({ userId }, { dispatch }) => {

        try {
            const ApiEndPoint = `${ApiUrl.user_profile.get_provider_details}?userId=${userId}`;
            const response = await get(ApiEndPoint) || []

            if (response) {
                return response.data ? response.data : response; // This value will be the payload of the fulfilled action
            } else {
                throw new Error('Failed to fetch Provider profile data');
            }
        } catch (error) {
            console.log("🚀 ~ error:", error, error)

            throw error; // This will trigger the rejected action
        }
    }
);
