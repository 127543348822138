import React from 'react'

//css
import './ResourceComponent.scss'
import images from '../../../../Assets/Images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const ResourceComponent = () => {

    const resourceData = [
        {
            image: images.News3,
            title: 'Quick examination',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News2,
            avatar: images.videoCallImg,
            title: 'Quick examination',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            image: images.News1,
            title: 'Quick examination',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
    ]

    return (
        <div className='resource-container'>
            <div className="resource-div container">
                <div className='d-flex align-items-center justify-content-between mb-4  '>
                    <h1>News, Resources, and Updates</h1>
                    <p>View All</p>
                </div>
                <div className="justify-content-center resource-cards d-flex">
                    {resourceData.map((item, index) => (
                        <div key={index} className="resource-details">
                            <div className="card h-100">
                                <div className="card-image position-relative">
                                    <img src={item.image} alt={item.title} className="img-fluid" />
                                    <div className='resource-save position-absolute'>save</div>
                                </div>
                                <div className="card-body text-start">
                                    <h5 className="title">{item.title}</h5>
                                    <p className="description text-muted">{item.description}</p>
                                    <button className="save-theme-btn mt-3 d-flex align-items-center gap-2" onClick={() => window.open('https://www.centiblick.com/resources', '_blank')}>Read More <FontAwesomeIcon icon={faChevronRight} /></button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='resource-btns mt-5'>
                    <button>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </button>
                    <button>
                        <FontAwesomeIcon icon={faArrowRightLong} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ResourceComponent