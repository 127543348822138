/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Client.scss'
import images from '../../../Assets/Images';

const ClientResetPassword = ({ url }: any) => {
    return (

        <div className="client d-flex justify-content-center m-0 mt-lg-5 row">
            <div className="card col-12 col-lg-4 p-0">
                <div className="contentdata mt-5">
                    <span className='contentText'>HH</span>
                </div>
                <a className='mb-4 text-center linka mt-2'>Client logo</a>
                <div className='d-flex justify-content-center mt-3'>
                    <img src={images.lockClient} alt="lockClient" className="lockPic" />
                </div>
                <span className='text-center resetcode mb-3 mt-2'>Your password reset code is</span>
                <h1 className='text-center code mb-4'>243 556</h1>
                <div className='text-center mb-3'>
                    <span className='border notsharedcode p-1'>*Do not share this security code with anyone!</span>
                </div>
                <div className="bg d-flex justify-content-center row m-0">
                    <p className='content text-center'>Click here to reset your password!</p>
                    {/* <button className="btn theme-btn w-50 mb-5">Reset your Password</button> */}
                    <a className="btn theme-btn w-50 mb-5" href={url}>Reset your Password</a>
                    <p className="resetcodelast mb-4 text-center">*After you change your password, return to the sign-in page and sign in with your new password.</p>
                </div>
            </div>
        </div>

    );
}
export default ClientResetPassword;
