import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { post } from "../../../Network/ApiService";

interface MeetingNotesPayload {
    notes: string;
}

interface storeMeetingNotesArgs {
    meeting_id: string
    payload: MeetingNotesPayload;
    authToken: string;
}

export const storeMeetingNotes = createAsyncThunk<any, storeMeetingNotesArgs, { state: RootState }>(
    "meeting/storeMeetingNotes",
    async ({ meeting_id, authToken, payload }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.meeting.create_meeting_notes}?meeting_id=${meeting_id}`;

            const response = await post(ApiEndPoint, authToken, payload);
            if (response) {
                return response;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error;
        }
    }
)