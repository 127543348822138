import React, { useState } from 'react'

// assets
import images from '../../../../Assets/Images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

// formik
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';


const Step5 = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {
  const [selectedOption, setSelectedOption] = useState<"monthly" | "yearly">("monthly")

  const [counter, setCounter] = useState(1);

  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  const validationSchema = Yup.object().shape({
    cardHolderName: Yup.string().required('Required'),
    cardNumber: Yup.number().required('Card Number must be Number'),
    expiry: Yup.string().required('Required'),
    cvv: Yup.number().required('CVV Number must be Number'),
  });


  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
  };

  const handleChange = (event: any) => {
    const newValue = parseInt(event.target.value, 10) || 0;
    setCounter(newValue);
  };

  return (
    <div className='w-100'>
      <div className='d-flex onboarding-main-flow align-items-center justify-content-center gap-5 h-100' style={{ padding: "0px 100px" }}>
        <div className='d-flex flex-column onboarding-stepping payment-section-first payment-section z-1 h-100' style={{ width: 'calc(100vw - 93%)' }}>
          <h1 className='onboarding-heading' style={{ fontSize: "16px" }}>Your Order</h1>
          <div className='d-flex flex-column gap-4'>
            <div className='d-flex flex-column'>
              <p className='blue-Heading pt-3'>ESSENTIAL</p>
              <div className="row w-100 mt-3 d-flex justify-content-between gap-4">
                <div className={`col border-div ${selectedOption === 'monthly' ? 'selected-box' : ''}`} onMouseEnter={() => handleOptionClick("monthly")} onClick={() => handleOptionClick('monthly')}>
                  <div className='inner-content-essential'>
                    <div className='d-flex justify-content-between align-content-center'>
                      <h4>Monthly</h4>
                      <img src={selectedOption == 'monthly' ? images.Icon : images.FilledCheckMark} alt="circle Icon" height={20} />
                    </div>
                    <h3 className='mt-3'>$69</h3>
                    <h5 className=''>/license/month</h5>
                  </div>
                </div>
                <div className={`col border-div ${selectedOption === 'yearly' ? 'selected-box' : ''}`} onClick={() => handleOptionClick('yearly')} onMouseEnter={() => handleOptionClick("yearly")}>
                  <div className='inner-content-essential'>
                    <div className='d-flex justify-content-between align-content-center'>
                      <h4 className='d-flex gap-2'>Yearly <span className='price-off'>20%OFF</span></h4>
                      <img src={selectedOption == 'yearly' ? images.Icon : images.FilledCheckMark} alt="circle Icon" height={20} />
                    </div>
                    <h3 className='mt-3'> $662 <del>$828 </del></h3>
                    <h5 className=''>/license/year</h5>
                  </div>
                </div>
              </div>
              <hr />
              <div className='pt-2 number-of-users-div'>
                <p className='number-of-users'>How many users do you have?</p>
                <div className="d-flex gap-2 pt-2">
                  <div className=" input-area position-relative " onClick={handleDecrement}>
                    <FontAwesomeIcon icon={faMinus} className='plus_minus_icon' />
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center position-relative">
                      <input type="text" name="cardNumber" value={counter} onChange={handleChange} className={`input-user user-input`} style={{ paddingLeft: "30px" }} />
                      <img src={images.UserIcon} alt="payment" className='user-icon' />
                    </div>
                  </div>
                  <div className=" input-area position-relative" onClick={handleIncrement}>
                    <FontAwesomeIcon icon={faPlus} className='plus_minus_icon' />
                  </div>
                </div>
              </div>
            </div>
            <div className="subscribe-content pt-4">
              <h2 className='heading'>Simple Unsubscribe</h2>
              <p className='onboarding-detail'>You have the flexibility to unsubscribe at any point within your 30-day free trial, and a reminder email for an effortless opt-out will be sent after 21 days.</p>
            </div>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-column'>
                <div className="subscribe-content pt-4">
                  <h2 className='heading'>{counter} Licenses</h2>
                  {/* <p className='onboarding-detail'> $ {counter * (selectedOption === "monthly" ? 69 : 800)} per license/per month</p> */}
                </div>
                <hr />
                <div className="d-flex justify-content-between pt-2 subtotal-div">
                  <p className="">SUBTOTAL</p>
                  <p>{counter} x {selectedOption == "monthly" ? '$69' : "$662"}</p>
                </div>
                <div className="d-flex justify-content-between pt-2 montly-subTotal">
                  <h2 className='heading'>{selectedOption === "monthly" ? "Monthly" : "Yearly"} Subtotal</h2>
                  <p>$ {counter * (selectedOption === "monthly" ? 69 : 662)}.00</p>
                </div>
              </div>
              <p className='onboarding-detail pt-4'>*T&C Applied</p>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column onboarding-stepping payment-section h-100 z-1' style={{ width: 'calc(100vw - 78%)' }}>
          <Formik
            initialValues={{
              cardHolderName: '',
              cardNumber: '',
              expiry: '',
              cvv: '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              // Your form submission logic goes here
              console.log(values);
              setSubmitting(false);
            }}
          >
            {(formik) => (
              <Form className='h-100'>
                <div className='d-flex justify-content-between flex-column h-100'>
                  <div>
                    <div className='d-flex flex-column' style={{ gap: "12px" }}>
                      <div>
                        <h1 className='onboarding-heading'>Payment</h1>
                        <p className='onboarding-detail'>Please select the number of users you have and the subscription cycle you prefer.</p>
                      </div>
                    </div>
                    <div className='onboarding-step mt-4'>
                      <div>
                        <div className="row">
                          <div className='col-lg-8 col-md-8 pb-3'>
                            <p className='onboarding-user pb-2'>Name on Card</p>
                            <div className="d-flex align-items-center position-relative">
                              <Field type="text" name="cardHolderName" className={`w-100 ${formik.touched.cardHolderName && formik.errors.cardHolderName ? 'error-border' : ''}`} />
                              {
                                formik.values.cardHolderName != "" &&
                                <FontAwesomeIcon icon={faCircleCheck} size='lg' color='#00cc00' className='check-name' />
                              }
                            </div>
                          </div>
                          <div className='col-lg-4 col-md-4 pb-3'>
                            <p className='onboarding-user pb-2'>Expiry</p>
                            <Field type="text" name="expiry" className={`w-100 input-user ${formik.touched.expiry && formik.errors.expiry ? 'error-border' : ''}`} />
                          </div>
                        </div>
                        <div className="row">
                          <div className='col-lg-8 col-md-8 pb-3'>
                            <p className='onboarding-user pb-2'>Card Number</p>
                            <div className="d-flex align-items-center position-relative">
                              <Field type="text" name="cardNumber" className={`w-100 input-user ${formik.touched.cardNumber && formik.errors.cardNumber ? 'error-border' : ''}`} style={{ paddingLeft: "50px" }} />
                              <img src={images.PaymentMethodIcon} alt="payment" className='payment-img' />
                            </div>
                          </div>
                          <div className='col-lg-4 col-md-4'>
                            <p className='onboarding-user pb-2'>CVV</p>
                            <Field type="text" name="cvv" placeholder='...' className={`w-100 input-user ${formik.touched.cvv && formik.errors.cvv ? 'error-border' : ''}`} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between col-lg-12'>
                    <button className='onboarding-back main-delete-theme-btn' onClick={handleBackButtonClick}>
                      Back
                    </button>
                    <button className='onboarding-checkout' type="submit" onClick={handleCheckoutButtonClick}>
                      Checkout
                    </button>
                  </div>

                </div>

              </Form>
            )}
          </Formik>
        </div>
      </div>

    </div >
  )
}

export default Step5