/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
const ClientLogin=()=>{
return (
    <> 
    <div className='row login mt-5'>
        <div className="col-12 p-0 mx-auto boxmodle-width">
                <div className='d-flex justify-content-center row'>
                    <div className="col-lg-6 p-0">
                    <div className='margin-set'>
                    <h2 className='text mt-5'>Hybrid Health</h2>
                 <div className="contextdataBox">
                    <div className="contentdata mt-5">
                    <span className='contentText'>HH</span>
                </div>
                    </div>
                <div className="linka mb-4 mt-4 text-center">Client logo</div>
                </div>
            </div>
            <div className="card col-lg-5 mb-3 mt-3 p-0">
                    <div className='row'>
                    <span className='d-flex justify-content-center mb-3 mt-3 resettext'>Reset Password</span>
                    <p className='d-flex justify-content-center mb-4 px-3 resetcontent text-center'>We have sent a password reset code by email to cb***@**.com. Enter it below to reset your password.</p>
                    </div>
                   <div className='border box mt-4 mx-3'>
                     <div className="form-group mb-3 mt-3">
                        <input type="text" className="form-control inputflied" placeholder='Enter Code' />
                     </div>
                     <div className="form-group mb-3">
                        <input type="text" className="form-control inputflied" placeholder='Enter New Password' />
                     </div>
                    
                     <div className="form-group mb-3">
                        <input type="text" className="form-control inputflied" placeholder='Confirm New Password' />
                     </div>
                     <button className="btn text-center theme-btn w-100">Confirm Password</button>
                    </div>
                    <p className='mb-4 mt-3 p-3 px-3 text-center'>Remember your password?<span className='text-color mx-1'>Log in</span></p>
            </div>
        </div>        
        </div>
    </div>
    </>
)
}
export default ClientLogin;