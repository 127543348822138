// src/utils/toastUtils.js

import React from 'react';

import { toast } from 'react-toastify';
import { capitalize } from '../utils';

// Common toast function
export const ToastMessage = (type: any, mainMessage: any) => {
    const content = (
        <div>
            <div className='fw-bold text-dark'>{capitalize(type)}</div>
            <div>{mainMessage}</div>
        </div>
    );

    switch (type) {
        case 'success':
            toast.success(content, {
                position: "top-right",
                autoClose: 5000,
                style: { zIndex: "10000" },
            });
            break;
        case 'error':
            toast.error(content, {
                position: "top-right",
                autoClose: 5000,
                style: { zIndex: "10000" },
            });
            break;
        case 'info':
            toast.info(content, {
                position: "top-right",
                autoClose: 5000,
                style: { zIndex: "10000" },
            });
            break;
        case 'warning':
            toast.warn(content, {
                position: "top-right",
                autoClose: 5000,
                style: { zIndex: "10000" },
            });
            break;
        default:
            toast(content, {
                position: "top-right",
                autoClose: 5000,
                style: { zIndex: "10000" },
            });
            break;
    }
};
