import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
  conditions_data: any[];
  encounters_data: any[];
  treatment_plans_data: any[];
}
const initialState: AppState = {
  conditions_data: [],
  encounters_data: [],
  treatment_plans_data: [],
};

export const careHistorySection = createSlice({
  name: "careHistory",
  initialState,
  reducers: {
    conditions: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.conditions_data = action.payload;
    },
    encounter: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.encounters_data = action.payload;
    },
    tratementPlans: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.treatment_plans_data = action.payload;
    },
    resetCareHistoryState: () => initialState
  },
});

export const { conditions, encounter, tratementPlans,resetCareHistoryState } =
  careHistorySection.actions;
