const Common = {
  already_have_an_account: "Vous avez déjà un compte?",
  signup: "s'inscrire",
  login: "connexion",
  enter_name: "Entrez le nom",
  enter_email: "Entrez l'e-mail",
  enter_password: "Entrer le mot de passe",
  get_started: "Commencer",
  verify: "Vérifier",
  contact_support: "Contactez le support",
  next: "Suivant",
  continue: "Continuer",
  already_have_an_admin_account :"Vous avez déjà un compte administrateur ? ",
  admin_signup:"Inscription administrateur",
  welcome_back:"Content de te revoir",
  privacy_policy:"politique de confidentialité"
};
export default Common;
