import React, { useEffect, useRef, useState } from 'react'

import * as Yup from 'yup';
//css
import '../ProfileFlow.scss'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { EyeClose, EyeOpen, ListIcon } from '../../../Assets/Svgs';
import images from '../../../Assets/Images';
import FinalFlowPage from './FinalFlowPage';
import { useNavigate } from 'react-router-dom';

const PatientProfileFlow = () => {

    const navigate = useNavigate()

    const [isFormFilled, setIsFormFilled] = useState<boolean>(false)
    const [isOTPFilled, setIsOTPFilled] = useState<boolean>(false)
    const [isSetUser, setIsSetUser] = useState<boolean>(false)
    const [code, setCode] = useState<string[]>(Array(6).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        policy: false
    };

    const handleRedirect = () => {
        navigate("/home", {
            state: "patient"
        })
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
        email: Yup.string().required('email is required'),
        policy: Yup.boolean().oneOf([true], 'You must accept the policy').required('Policy acceptance is required')
    });

    const handleSubmit = async (values: any, { setErrors }: any) => {

        if (isFormFilled) {
            setIsOTPFilled(true)
        } else {
            setIsFormFilled(true)
        }
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleChange = (value: string, index: number) => {
        if (isNaN(Number(value))) return;

        const newCode = [...code];
        newCode[index] = value.slice(-1); // Only keep the last entered digit
        setCode(newCode);

        // Move focus to the next input field
        if (index < 6 - 1 && value) {
            inputRefs.current[index + 1]?.focus(); // Use optional chaining
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1]?.focus(); // Use optional chaining
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteData = e.clipboardData
            .getData('text')
            .split('')
            .slice(0, 6);
        setCode(pasteData);
        pasteData.forEach((value, index) => {
            if (inputRefs.current[index]) {
                inputRefs.current[index]!.value = value;
            }
        });
    };

    useEffect(() => {
        inputRefs.current[0]?.focus(); // Use optional chaining
    }, []);

    const description = ['Trust that your information and virtual sessions are secure, meeting the highest standards of next-gen security.',
        'Combine and share your medical records with virtual care providers from a network of over 26,000 connected health facilities.',
        'Easily schedule and organize virtual care appointments with familiar providers or discover new ones!']

    return (
        <div className='profile-flow'>
            <img src={images.OnboardingBG} alt="" className='bg-image1' />
            <img src={images.OnboardingBG} alt="" className='bg-image2' />
            <div className='main-profile-flow'>
                {
                    isSetUser ? <FinalFlowPage handleRedirect={handleRedirect} /> :
                        <>
                            <div className='left-profile-flow'>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {(formik) => (
                                        <Form>
                                            <div className='d-flex flex-column gap-3 align-items-center'>
                                                <div className='d-flex flex-column align-items-center left-head'>
                                                    <div className='logo-border'>
                                                        <img src={images.Logo} alt="" />
                                                    </div>
                                                    <p>CENTIBLICK</p>
                                                </div>
                                                {isOTPFilled ?
                                                    <>
                                                        <div className='w-100'>
                                                            <label className='form-label profile-label'>Choose username</label>
                                                            <Field className='form-control profile-input' type='text' name='userName' placeholder='Enter you username' />
                                                            <ErrorMessage name='userName' component='p' className='text-danger' />
                                                        </div>
                                                        <div className='w-100 position-relative'>
                                                            <label className='form-label profile-label'>Create a new password</label>
                                                            <Field className='form-control profile-input' type={showPassword ? 'text' : 'password'} name='password' placeholder='Enter your password' />
                                                            <ErrorMessage name='password' component='p' className='text-danger' />
                                                            <img src={showPassword ? EyeOpen : EyeClose} className="position-absolute cursor-pointer" style={{ right: '15px', bottom: '20px' }} onClick={handleTogglePasswordVisibility} alt=''/>
                                                        </div>
                                                    </> :
                                                    isFormFilled ?
                                                        <>
                                                            <div className="code-input">
                                                                <p>Enter your Code here</p>
                                                                <div className='d-flex gap-3'>
                                                                    {code.map((value, index) => (
                                                                        <input
                                                                            key={index}
                                                                            type="text"
                                                                            maxLength={1}
                                                                            value={value}
                                                                            onChange={(e) => handleChange(e.target.value, index)}
                                                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                                                            onPaste={handlePaste}
                                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                                            placeholder='_'
                                                                        />
                                                                    ))}
                                                                </div>
                                                                <div className="resend-link">
                                                                    <a href="/" onClick={() => alert('Code resent!')}>Resend?</a>
                                                                </div>
                                                            </div>
                                                        </> :
                                                        <>
                                                            <div className='w-100'>
                                                                <label className='form-label profile-label'>First Name</label>
                                                                <Field className='form-control profile-input' type='text' name='firstName' placeholder='Enter First name' />
                                                                <ErrorMessage name='firstName' component='p' className='text-danger' />
                                                            </div>
                                                            <div className='w-100'>
                                                                <label className='form-label profile-label'>Last Name</label>
                                                                <Field className='form-control profile-input' type='text' name='lastName' placeholder='Enter last name' />
                                                                <ErrorMessage name='lastName' component='p' className='text-danger' />
                                                            </div>
                                                            <div className='w-100'>
                                                                <label className='form-label profile-label'>Email ID</label>
                                                                <Field className='form-control profile-input' type='text' name='email' placeholder='admin@centiblick.com' />
                                                                <ErrorMessage name='email' component='p' className='text-danger' />
                                                            </div>
                                                            <div className=' w-100 policy'>
                                                                <div className='d-flex gap-3 align-items-center'>
                                                                    <Field type='checkbox' name='policy' className='custom-checkbox' />
                                                                    <h4>I have read the <span>Privacy Policy</span></h4>
                                                                </div>
                                                                <ErrorMessage name='policy' component='p' className='text-danger' />
                                                            </div>
                                                        </>
                                                }
                                                <ErrorMessage name='form' component='p' className='text-danger' />
                                                {
                                                    isOTPFilled ?
                                                        <div className='d-flex w-100 gap-3'>
                                                            <button style={{ marginTop: "20px" }} className='btn confirm-back-btn w-50' >Back</button>
                                                            <button style={{ marginTop: "20px" }} className='btn theme-btn confirm-btn w-100' onClick={() => setIsSetUser(true)}>Confirm</button>
                                                        </div>
                                                        :
                                                        <button style={{ marginTop: "20px" }} className='btn theme-btn confirm-btn w-100' type='submit' disabled={formik.isSubmitting || !formik.isValid}>Continue</button>
                                                }
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className='right-profile-flow'>
                                <div className='right-sub-flow'>
                                    <h1 className='flow-header'>
                                        {isOTPFilled ? "Choose username and password! 🙈" : isFormFilled ? "Enter your registration code!" : " Let’s Get started"}
                                    </h1>
                                    {
                                        isOTPFilled ?
                                            <p>Choose a password that you can easily remember, but others can’t guess.</p> :
                                            isFormFilled ?
                                                <p>
                                                    Please check your email [example@hybridhealth.co.in] for the Registration Code. If you don’t see it in your inbox, check your spam folder as well.
                                                </p> :
                                                <div className='d-flex flex-column desc'>
                                                    <h2 className='flow-sub-header'>
                                                        As as admin you can:
                                                    </h2>
                                                    <div className='sub-desc'>
                                                        {
                                                            description ? description?.map((desc: any, index: number) => (
                                                                <div key={index} className='d-flex align-items-start gap-2'>
                                                                    <img src={ListIcon} alt="" height={33} width={33} />
                                                                    <p >{desc}</p>
                                                                </div>
                                                            )) : null
                                                        }
                                                    </div>
                                                </div>

                                    }
                                </div>
                                <div className='w-100'>
                                    <img src={images.PatientProfileFlowImg} alt="" className='w-100 flow-img' />
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default PatientProfileFlow