import React from "react";

// utils components
import themeComponents from "./themeComponents";

const ImageHandler = ({ theme, imageName, className ,handleClick}: any) => {
  return (
    <img
      src={themeComponents[theme][imageName]}
      alt={imageName}
      className={`${className} ${theme}_${imageName}`}
      onClick={handleClick}
    />
  );
};

export default ImageHandler;
