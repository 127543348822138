//step 1

export const option = [
    {
      label: 'Healthcare Provider',
      value: 'Healthcare Provider'
    },
    {
      label: 'Healthcare Staff Member',
      value: 'Healthcare Staff Member'
    },
  ]

  //step 2
export  const Specialty = [
    {
        label: 'Acupuncture',
        value: 'Acupuncture'
    },
    {
        label: 'Allergy & Immunology',
        value: 'Allergy & Immunology'
    },
    {
        label: 'Anesthesiology',
        value: 'Anesthesiology'
    },
    {
        label: 'Arthritis & Rheumatology',
        value: 'Arthritis & Rheumatology'
    },
    {
        label: 'Audiology',
        value: 'Audiology'
    },
    {
        label: 'Bariatric Surgery',
        value: 'Bariatric Surgery'
    },
    {
        label: 'Behavioral Health',
        value: 'Behavioral Health'
    },
    {
        label: 'Cardio',
        value: 'Cardio'
    },
    {
        label: 'Cardiology',
        value: 'Cardiology'
    },
    {
        label: 'Chiropractic',
        value: 'Chiropractic'
    },
    {
        label: 'Critical Care',
        value: 'Critical Care'
    },
    {
        label: 'Dentistry',
        value: 'Dentistry'
    },
    {
        label: 'Endocrinology',
        value: 'Endocrinology'
    },
    {
        label: 'Endodontic',
        value: 'Endodontic'
    },
    {
        label: 'ENT',
        value: 'ENT'
    },
    {
        label: 'Family Medicine',
        value: 'Family Medicine'
    },
    {
        label: 'Free Clinic',
        value: 'Free Clinic'
    },
    {
        label: 'Gastroenterology',
        value: 'Gastroenterology'
    },
    {
        label: 'Generic Practice',
        value: 'Generic Practice'
    },
    {
        label: 'Geriatrics',
        value: 'Geriatrics'
    },
    {
        label: 'Hematology',
        value: 'Hematology'
    },
    {
        label: 'Hospice and Palliative Care',
        value: 'Hospice and Palliative Care'
    },
    {
        label: 'Hospitalist',
        value: 'Hospitalist'
    },
    {
        label: 'IM - Inpatient',
        value: 'IM - Inpatient'
    },
    {
        label: 'IM - SNF',
        value: 'IM - SNF'
    },
    {
        label: 'Infectious Disease',
        value: 'Infectious Disease'
    },
    {
        label: 'Internal Medicine',
        value: 'Internal Medicine'
    },
    {
        label: 'Long-Term Care',
        value: 'Long-Term Care'
    },
    {
        label: 'Naturopathic',
        value: 'Naturopathic'
    },
    {
        label: 'Neurology',
        value: 'Neurology'
    },
    {
        label: 'Obstetrics & Gynecology',
        value: 'Obstetrics & Gynecology'
    },
    {
        label: 'Occupational Medicine',
        value: 'Occupational Medicine'
    },
    {
        label: 'Oncology',
        value: 'Oncology'
    },
    {
        label: 'Ophthalmology',
        value: 'Ophthalmology'
    },
    {
        label: 'Optometry',
        value: 'Optometry'
    },
    {
        label: 'Orthopedic',
        value: 'Orthopedic'
    },
    {
        label: 'Otolaryngology',
        value: 'Otolaryngology'
    },
    {
        label: 'Pan Medicine',
        value: 'Pan Medicine'
    },
    {
        label: 'Pediatrics',
        value: 'Pediatrics'
    },
    {
        label: 'Pharmacy',
        value: 'Pharmacy'
    },
    {
        label: 'Physical Rehabilitation',
        value: 'Physical Rehabilitation'
    },
    {
        label: 'Plastic Surgery',
        value: 'Plastic Surgery'
    },
    {
        label: 'Pulmonary',
        value: 'Pulmonary'
    },
    {
        label: 'Radiology',
        value: 'Radiology'
    },
    {
        label: 'Rheumatology',
        value: 'Rheumatology'
    },
    {
        label: 'Sleep Medicine',
        value: 'Sleep Medicine'
    },
    {
        label: 'Spine',
        value: 'Spine'
    },
    {
        label: 'Sports Medicine',
        value: 'Sports Medicine'
    },
    {
        label: 'Therapist/ Psychology/ Psychiatry',
        value: 'Therapist/ Psychology/ Psychiatry'
    },
    {
        label: 'Urology',
        value: 'Urology'
    },
    {
        label: 'Walk in Clinic',
        value: 'Walk in Clinic'
    },
    {
        label: 'Wellness',
        value: 'Wellness'
    },
]

export const State = [
    {
        label: 'Texas',
        value: "Texas"
    },
    {
        label: 'California',
        value: "California"
    },
    {
        label: 'Florida',
        value: "Florida"
    },
    {
        label: 'Washington',
        value: "Washington"
    },
    {
        label: 'Ohio',
        value: "Ohio"
    },
    {
        label: 'Georgia',
        value: "Georgia"
    },
    {
        label: 'Alabama',
        value: "Alabama"
    },
    {
        label: 'New York',
        value: "New York"
    },
]

//step 3


export const Service = [
    {
      label: 'Visit Now ',
      value: 'Visit Now '
    },
    {
      label: 'Virtual Appointments',
      value: 'Virtual Appointments'
    },
    {
      label: 'Both',
      value: 'Both'
    },
  ]

  export const Registration = [
    {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      },
  ]