import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { ApiUrl } from "../../../Network/ApiUrl";
import { get } from "../../../Network/ApiService";

interface DateViewParams {
   userId: string | undefined, startDate: string, endDate: string,type:string,authToken: string
}

export const fetchAppointmentDateView = createAsyncThunk<any, DateViewParams, { state: RootState }>(
    "appointment/fetchAppointmentDateView",
    async ({ userId, startDate, endDate,type, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint;
            if(type === 'provider'){
                ApiEndPoint = `${ApiUrl.appointments.get_provider_date_view}?provider_id=${userId}&from_date_time=${startDate}&to_date_time=${endDate}`;
            }else{
                ApiEndPoint = `${ApiUrl.appointments.get_patient_date_view}?patient_id=${userId}&from_date_time=${startDate}&to_date_time=${endDate}`;
            }
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error("Failed to fetch Patient date view");
            }
        } catch (error) {
            throw error;
        }
    }
);