import React, { useEffect, useState } from "react";

//css
import "./WaitingScreen.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import images from "../../../Assets/Images";
import {
    CloseModelIcon,
    EyeClose,
    EyeOpen,
    LandingLogo,
    LoginPopupBG,
    MicPermission,
    Padlock,
} from "../../../Assets/Svgs";
import { useNavigate } from "react-router-dom";
import PatientFormFlow from "./PatientFormFlow";
import { setCallStatus, setCallType, setPlan, setRoomId } from "../../../Store/Slicers/commonSlicer";
import { useDispatch, useSelector } from "react-redux";
import { faArrowRotateRight, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import ForgotPassword from "../../../Components/Common/ForgotPassword";
import { getLocalStorage, setLocalStorage } from "../../../Components/Patient/Utils/AuthHandlers";
import { fetchPatientProfileData } from "../../../Store/Thunk/getProfileData/patientProfileThunks";
import { toast } from "react-toastify";
import { Amplify, Auth } from "aws-amplify";
import { capitalizeEachWord, passwordValidation } from "../../../Components/utils";
import { fetchProviderDetails } from "../../../Store/Thunk/getProfileData/getProviderDetailsThunk";
import { RootState } from "../../../Store";
import { PatientAwsConfig } from "../../../awsConfig";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { ResetPasswordProfile } from "../../../Store/Thunk/ResetPassword/resetPasswordThunk";
import { ToastMessage } from "../../../Components/Common/ToastMessage";

const PatientLoginScreen = () => {


    Amplify.configure({
        Auth: {
            region: PatientAwsConfig.REGION,
            userPoolId: PatientAwsConfig.USER_POOL_ID,
            userPoolWebClientId: PatientAwsConfig.USER_POOL_CLIENT_ID,
        },
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authToken = getLocalStorage("authToken");
    const userId = getLocalStorage("userData").sub;

    const ProviderDetails = useSelector((state: RootState) => state.ProviderDetails.ProviderDetails)
    const [showModal, setShowModal] = useState<boolean>(true);
    const [isLoginClick, setIsLoginClick] = useState<boolean>(false);
    const [isSignIn, setIsSignIn] = useState<boolean>(false);
    const [isContinueClick, setIsContinueClick] = useState<boolean>(false);
    const [isSaveClick, setIsSaveClick] = useState<boolean>(false);
    const [forgotModal, setForgotModal] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [regiterEmail, setRegiterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [isSendVerification, setIsSendVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loader, setLoader] = useState<boolean>(false);

    // const room_id = getLocalStorage("room_id")
    const room_id = sessionStorage.getItem("room_id")

    const fetchProviderData = async () => {
        if (room_id) {
            await dispatch(fetchProviderDetails({ userId: room_id }))
        }
    }
    useEffect(() => {
        if (!!room_id === false) {
            navigate('/waiting-room')
            ToastMessage("error",'No meeting Id found please select your Clinician room.');
        } else {
            fetchProviderData()

            dispatch(setRoomId(room_id))
        }
        // if (!room_id || typeof room_id !== 'string' || room_id.trim() === '') {
        //     navigate('/')
        //     ToastMessage("error",'Meeting Id is incorrect please contact with your provider')
        // }
    }, [])

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e: any) => {
        const newPassword = e.target.value;
        setRegisterPassword(newPassword);
        validatePasswords(e.target.value, registerPasswordConfirm);
        const validationError: any = passwordValidation(newPassword);
        setPasswordError(validationError);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const validatePasswords = (password: any, confirmPassword: any) => {
        setPasswordsMatch(password === confirmPassword);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setRegisterPasswordConfirm(e.target.value);
        validatePasswords(registerPassword, e.target.value);
    };

    const handleVerification = async (e: any) => {
        toast.loading("Please wait...")
        e.preventDefault()
        try {
            await Auth.confirmSignUp(regiterEmail, verificationCode);
            await Auth.signIn(regiterEmail, registerPassword, {
                type: 'patient'
            });

            const userData = await Auth.currentAuthenticatedUser();
            if (userData) {
                setLocalStorage("SignUpUserName", userName);
                setLocalStorage("userData", JSON.stringify(userData.signInUserSession.accessToken.payload));
                setLocalStorage("userEmail", userData.attributes.email);
                setLocalStorage("userToken", userData.signInUserSession.idToken.jwtToken);
                setEmail('')
                setPassword('')
                setRegiterEmail('')
                setRegisterPassword('')
                setUserName('')
                setVerificationCode('')
            }
            toast.dismiss()
            setIsSignIn(false)
            setIsSendVerification(false)
            setIsContinueClick(true);

        } catch (error: any) {
            toast.dismiss()
            ToastMessage("error","Something went wrong")
            console.log("🚀 ~ handleVerification ~ error:", error)
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        toast.loading("Please Wait")
        dispatch(setPlan("clinic"));
        try {
            await Auth.signIn(email, password, {
                profile: 'patient'
            }).then(async (res: any) => {
                toast.dismiss()
                let authToken = res?.signInUserSession?.idToken?.jwtToken;
                let userId = res.signInUserSession?.accessToken?.payload?.sub;
                if (authToken) {
                    setLocalStorage("userToken", authToken);
                    setLocalStorage('userData', JSON.stringify(res.signInUserSession.accessToken.payload))
                    setLocalStorage('userEmail', res.attributes.email)
                    await dispatch(fetchPatientProfileData({ userId, authToken }));
                    setIsLoginClick(true);
                    navigate("/patient/meeting-screen");
                }
            })
        } catch (error: any) {
            toast.dismiss()
            console.error(error);
        }

    };

    const handleFormSubmit = async (e: any) => {
        toast.loading("Please wait...")
        e.preventDefault();
        try {
            await Auth.signUp({
                username: regiterEmail,
                password: registerPassword,
                attributes: {
                    name: userName,
                    email: regiterEmail,
                    profile: 'patient'
                },
            })
            toast.dismiss();
            setIsSendVerification(true)
        } catch (error: any) {
            console.error("Error signing up:", error);
            toast.dismiss();
            ToastMessage("error","Something went wrong")
        }
    };

    const handleForgot = () => {
        setForgotModal(true);
        // navigate('/forgot-password')
    };

    const closeModal = () => {
        setForgotModal(false);
    };

    const handleSaveClick = () => {
        setIsSaveClick(true);
        setIsContinueClick(false);
        setIsSignIn(false);
    };

    const allowPermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setIsSaveClick(false);
            navigate('/patient/meeting-screen')
            console.log("Camera and microphone access granted");
        } catch (error: any) {
            console.error("Error accessing camera or microphone", error);
            if (
                error.name === "NotAllowedError" ||
                error.name === "PermissionDeniedError"
            ) {
                console.log("Permission to access camera or microphone is blocked");
            } else {
                console.log("An error occurred: ", error.message);
            }
        }
    };

    // const [ws, setWs] = useState<WebSocket | null>(null);

    useEffect(() => {
        // const websocket = new WebSocket('wss://echo.websocket.org');
        const websocket = new WebSocket(`wss://8uhsg8qi47.execute-api.us-east-1.amazonaws.com/production?userId=${userId}&userType=patient`);
        // setWs(websocket);


        websocket.onopen = () => {
            console.log('WebSocket is connected');
        };


        websocket.onmessage = (evt) => {
            const data = JSON.parse(evt.data);
            console.log("🚀 ~ useEffect ~ data:", data)
            if (data.action === "waitingRoom") {
                dispatch(setCallStatus(data?.status));
                dispatch(setCallType(data?.channel))
            }
        };

        websocket.onerror = (error) => {
            console.log('WebSocket error:', error);
        };

        // websocket.onclose = () => {  
        //     console.log('WebSocket is closed');
        // };

        return () => {
            // websocket.close();
        };
    }, []);

    // const handleMeetingOption = (option: string) => {
    //     const capitalizedOption = option.toUpperCase();
    //     const message = {
    //         action: "waitingRoom",
    //         from_user_id: userId,
    //         to_user_id: room_id,
    //         channel: capitalizedOption,
    //         status: "WAITING",
    //         initiated_time: moment().utc().format('YYYY-MM-DDTHH:mm:ss')
    //     };
    //     console.log("🚀 ~ handleMeetingOption ~ message:", message)
    //     if (ws?.readyState === WebSocket.OPEN) {
    //         ws?.send(JSON.stringify(message))
    //     }
    //     dispatch(setPlan('clinic'))
    //     navigate("/patient/meeting-screen");
    // };

    const handleResendCode = async (email: string) => {
        toast.loading('Resending code...');
        try {
            await Auth.resendSignUp(email);
            toast.dismiss();
            ToastMessage("success",'Verification code sent successfully!');
        } catch (error) {
            toast.dismiss();
            ToastMessage("error",'Failed to resend verification code. Please try again.');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, submitFunction: () => void) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission
            submitFunction(); // Call the submit function
        }
    };

    const handlePassword = async (email: string) => {
        setLoader(true);
        toast.loading("Please wait...");
        let response: any = await dispatch(ResetPasswordProfile({ email }));
        if (response?.payload?.message === "password reset successful.") {
            ToastMessage("success","Email sent successfully");
        } else {
            ToastMessage("error","Something went wrong. Please try again later.");
        }
    };

    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered ${isContinueClick
                        ? "modal-xl"
                        : "modal-lg"
                        }`}
                >
                    <div className="modal-content p-0 shadow-none border-0 overflow-visible">
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup">
                                {isSignIn ? (
                                    <div className="waiting-login align-items-center">
                                        <div className="d-flex align-items-start">
                                            <img src={Padlock} alt="" className={`${isSendVerification && "d-none"}`} />
                                            <div>
                                                <h1 className="waiting-header d-flex justify-content-center align-items-center">
                                                    {isSendVerification ? "Please enter verification code" : "Choose username and password!"}
                                                </h1>
                                                <p className={`desc ${isSendVerification && 'd-none'}`}>Let’s setup a user name and password to keep your information and Telehealth session safe</p>
                                            </div>
                                        </div>
                                        <form
                                            className="register-form d-flex flex-column w-75"
                                            onSubmit={isSendVerification ? handleVerification : handleFormSubmit}
                                        >
                                            {isSendVerification ?
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control py-3 px-1"
                                                        placeholder="Enter verification code"
                                                        value={verificationCode}
                                                        onChange={(e) => setVerificationCode(e.target.value)}
                                                    />
                                                    <div>
                                                        <button type="button" className="save-theme-btn d-flex align-items-center gap-2" style={{ padding: "8px 12px" }} onClick={() => handleResendCode(regiterEmail)}>
                                                            <FontAwesomeIcon icon={faArrowRotateRight} /> Resend Code
                                                        </button>
                                                    </div>
                                                </> :
                                                <>
                                                    <input
                                                        type="email"
                                                        className="form-control py-3 px-1"
                                                        placeholder="Email"
                                                        value={regiterEmail}
                                                        onChange={(e) => setRegiterEmail(e.target.value)}
                                                        autoComplete="new-email"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="form-control py-3 px-1"
                                                        placeholder="User Name"
                                                        value={userName}
                                                        onChange={(e) => setUserName(e.target.value)}
                                                        autoComplete="new-user"
                                                    /> */}
                                                    <div>
                                                        <div className="position-relative">
                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                className="form-control py-3 px-1"
                                                                placeholder="Password"
                                                                value={registerPassword}
                                                                onChange={handlePasswordChange}
                                                                autoComplete="new-password"
                                                            />
                                                            <img
                                                                src={showPassword ? EyeOpen : EyeClose}
                                                                className="position-absolute cursor-pointer"
                                                                style={{ right: "15px", bottom: "20px" }}
                                                                onClick={handleTogglePasswordVisibility}
                                                                alt=""
                                                            />
                                                        </div>
                                                        {
                                                            registerPassword === "" && <div className="text-danger">Password is required</div>
                                                        }
                                                        {passwordError && (
                                                            <div className="text-danger">{passwordError}</div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div className="position-relative">
                                                            <input
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                className="form-control py-3 px-1"
                                                                placeholder="Re-enter Password"
                                                                value={registerPasswordConfirm}
                                                                onChange={handleConfirmPasswordChange}
                                                                autoComplete="new-password"
                                                            />
                                                            <img
                                                                src={showConfirmPassword ? EyeOpen : EyeClose}
                                                                className="position-absolute cursor-pointer"
                                                                style={{ right: "15px", top: "22px" }}
                                                                onClick={handleToggleConfirmPasswordVisibility}
                                                                alt=""
                                                            />
                                                        </div>
                                                        {!passwordsMatch && (
                                                            <div className="text-danger">Passwords do not match</div>
                                                        )}
                                                    </div>
                                                    <div className="remember d-flex align-items-center">
                                                        <input type="checkbox" />
                                                        <span>Remember me on this computer</span>
                                                    </div>
                                                </>}
                                            <button
                                                type="submit"
                                                className="yes-theme-btn border-0"
                                                style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                disabled={passwordError !== '' || !passwordsMatch || registerPassword === ''}
                                            >
                                                Continue
                                            </button>
                                            {(isSignIn && !isSendVerification) && <p onClick={() => setIsSignIn(false)} className="login-text">If already have an account <span>Log In</span></p>}
                                        </form>
                                        <div
                                            className={`text-center login-logo }`}
                                        >
                                            <img src={LandingLogo} alt="" />
                                        </div>
                                    </div>
                                ) : isContinueClick ? (
                                    <div className="waiting-login align-items-center">
                                        <PatientFormFlow handleSaveClick={handleSaveClick} />
                                        <div
                                            className={`text-center login-logo ${(isSaveClick || isLoginClick) && "d-none"
                                                }`}
                                        >
                                            <img src={LandingLogo} alt="" />
                                        </div>
                                        <h1 className="waiting-header mb-4">Let’s complete your profile</h1>
                                    </div>
                                ) : isSaveClick ? (
                                    <div className="waiting-login">
                                        <div className="permission-option w-100 d-flex flex-column">
                                            <div className="d-flex justify-content-between align-items-center chat-header">
                                                <h1>Give permission to CentiBlick Connect to</h1>
                                                <img
                                                    src={CloseModelIcon}
                                                    alt="Close"
                                                    className="close-model-icon"
                                                    style={{ width: "20px" }}
                                                    onClick={() => { setIsSaveClick(false); navigate('/patient/meeting-screen') }}
                                                />
                                            </div>
                                            <div className="permission-msg">
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{ marginBottom: "14px" }}
                                                >
                                                    <img src={MicPermission} alt="" />
                                                    <p>Use your microphone</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={faVideoSlash}
                                                        style={{ width: "24px" }}
                                                    />
                                                    <p>Use your camera</p>
                                                </div>
                                            </div>
                                            <div className="permission-btn d-flex gap-2">
                                                <button
                                                    className="save-theme-btn w-100"
                                                    style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                    onClick={() => { setIsSaveClick(false); navigate('/patient/meeting-screen') }}
                                                >
                                                    Block
                                                </button>
                                                <button
                                                    className="yes-theme-btn border-0 w-100"
                                                    style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                    onClick={allowPermission}
                                                >
                                                    Allow
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                ) : (
                                    forgotModal ? (
                                        <div className="waiting-login">
                                            <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Let’s setup a new password! <img src={Padlock} alt="" /></h1>
                                            {/* <p className="desc">Let’s setup a new password</p> */}
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <Formik
                                                        initialValues={{ email: '' }}
                                                        validationSchema={Yup.object({
                                                            email: Yup.string().email('Invalid email address').required('Email Required'),
                                                        })}
                                                        onSubmit={({ email }) => handlePassword(email)}
                                                    >
                                                        {({ handleSubmit, errors, touched }) => (
                                                            <Form className="login-form d-flex flex-column" onSubmit={handleSubmit}>
                                                                <div>
                                                                    <Field
                                                                        name="email"
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter your Email"
                                                                        autoComplete='new-email'
                                                                        onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                                                    />
                                                                    <ErrorMessage component='div' name='email' className='text-danger' />
                                                                </div>
                                                                <button
                                                                    type="submit"
                                                                    className="yes-theme-btn border-0"
                                                                    style={{
                                                                        padding: "16px 24px",
                                                                        borderRadius: "6px",
                                                                    }}
                                                                    disabled={loader}
                                                                >
                                                                    Continue
                                                                </button>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                                <div className={`text-center login-logo`}>
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                        <div className="waiting-login">
                                            <h1 className="waiting-header">Welcome!
                                            </h1>
                                            <p className="desc">
                                                {
                                                    window.innerWidth > 767 ? `Please sign in or register to let Dr. ${capitalizeEachWord(ProviderDetails?.first_name + " " + ProviderDetails?.last_name)} know you are here` : "Sign in"
                                                }

                                            </p>
                                            <div className="signin-div d-flex w-100 align-items-center">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <h1 className={`signin-header ${window.innerWidth > 767 ? "" : "d-none"}`}>Sign In</h1>
                                                    <form
                                                        className="login-form d-flex flex-column"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control py-3 px-1"
                                                            placeholder="Enter your Email Address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <div className="position-relative">
                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                className="form-control py-3 px-1"
                                                                placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                autoComplete="new-password"
                                                            />
                                                            <img
                                                                src={showPassword ? EyeOpen : EyeClose}
                                                                className="position-absolute cursor-pointer"
                                                                style={{ right: "15px", bottom: "20px" }}
                                                                onClick={handleTogglePasswordVisibility}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="remember d-flex align-items-center">
                                                            <input type="checkbox" />
                                                            <span>Remember me on this computer</span>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                        >
                                                            Sign In
                                                        </button>
                                                        <p onClick={handleForgot} className="forgotpassword-title">Forgot Password</p>
                                                    </form>
                                                </div>
                                                <div
                                                    className={`vertical-line `}
                                                ></div>
                                                <div
                                                    className={`social-login w-100 d-flex flex-column `}
                                                >
                                                    <div className="register d-flex flex-column">
                                                        <button
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                            onClick={() => { setIsSignIn(true) }}
                                                        >
                                                            Register
                                                        </button>
                                                        <h1 className={`signin-header text-center`}>
                                                            Don’t have a Profile?
                                                        </h1>
                                                    </div>
                                                    <div className="social-account">
                                                        <div className="google d-flex align-items-center mb-1">
                                                            <img src={images.Google} alt="Google" />
                                                            Continue with Google
                                                        </div>
                                                        <div className="apple d-flex align-items-center">
                                                            <FontAwesomeIcon icon={faApple} color="#ffffff" />
                                                            Continue with Apple
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`text-center login-logo }`}
                                            >
                                                <img src={LandingLogo} alt="" />
                                            </div>                                            
                                        </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientLoginScreen