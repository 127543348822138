import React, { useEffect, useState } from 'react'

// asstes
import onBoardingLaptop from "../../../../../Assets/Svgs/onBoardingLaptop.svg";
import { Provider, useSelector } from 'react-redux';
import { RootState, store } from '../../../../../Store';
import { BrowserRouter } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';

//components
import CommonHeader from '../../Platform/Header/CommonHeader';
import Sidebar from '../../Platform/Sidebar/Sidebar';
import '../../../ClinicOnBoarding.scss'

interface LaptopComponentProps {
    isStartedVisible: boolean;
    title: "Standard Platform" | "Customize"
}
const LaptopComponent = ({ isStartedVisible, title }: LaptopComponentProps) => {

    const [isEditVisible, setIsEditVisible] = useState(false);
    const { isfinishedSaving } = useSelector((state: RootState) => state.onBoarding);

    useEffect(() => {
        const handleButtonClick = (buttonType: string) => {
            // handleModalOpen();

        };

        const editButton = document.getElementById('btn-edit');
        if (editButton) {
            editButton.addEventListener('click', () => handleButtonClick('edit'));
        }

        const startedButton = document.getElementById('btn-started');
        if (startedButton) {
            startedButton.addEventListener('click', () => handleButtonClick('started'));
        }

        // Clean up the event listeners on component unmount
        return () => {
            if (editButton) {
                editButton.removeEventListener('click', () => handleButtonClick('edit'));
            }
            if (startedButton) {
                startedButton.removeEventListener('click', () => handleButtonClick('started'));
            }
        };
    }, [isEditVisible]);


 

    const renderComponentToHTMLString = (component: any) => {
        const htmlString = ReactDOMServer.renderToStaticMarkup(component);
        return htmlString;
    };

    const handleMouseEnter = () => {
        if (isfinishedSaving) {
            setIsEditVisible(true)
        }
    }

    const handleMouseLeave = () => {
        if (isfinishedSaving) {
            setIsEditVisible(false)
        }
    }

    const svgWithText = `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="330px"
      viewBox="0 0 100% 100%"
      preserveAspectRatio="xMidYMid meet"
      class='${isfinishedSaving ? "laptop-svg" : ""}'
    >      
      <!-- Your additional content -->
      <foreignObject x="11%" y="17%" width="79%" height="100%" class='${isfinishedSaving ? "inner-screen" : "two-laptop-screen-section inner-screen "}'>
        <div xmlns="http://www.w3.org/1999/xhtml" class='h-100'>
            ${renderComponentToHTMLString(
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <CommonHeader ComponentTitle={title} />
                    <Sidebar ComponentTitle={title} />
                </BrowserRouter>
            </Provider>
        </>
    )}
        </div>
      </foreignObject>
    </svg>
  `;

    return (
        <>
            <div className='d-flex justify-content-center' onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div className={`${isfinishedSaving ? "single-laptop-screen" : "laptop-screen"}`} style={{ backgroundImage: `url(${onBoardingLaptop})` }} >
                    <div dangerouslySetInnerHTML={{ __html: svgWithText }} />
                </div>
            </div>

        </>
    );
}

export default LaptopComponent