import React, { useState } from 'react'
import { CloseModelIcon, EyeClose, EyeOpen, LandingLogo, LoginPopupBG, MicPermission, Padlock } from '../../../../../Assets/Svgs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProviderplan } from '../../../../../Store/Slicers/commonSlicer';
import CBSelectState from '../../../../../Components/Common/CBSelect/CBSelectState';
import { Specialty } from '../../../../../Components/utils';
import ProviderDemoProfile from '../ProviderDemoProfile';

const ProviderDemoSignUp = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showModal, setShowModal] = useState<boolean>(true);
    const [isContinueClick, setIsContinueClick] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [isSaveClick, setIsSaveClick] = useState<boolean>(false);
    const [isVerificationCodeSend, setIsVerificationCodeSend] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [isSendVerification, setIsSendVerification] = useState(false);
    const [userName, setUserName] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [specialty, setSpecialty] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('')
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSubmit(true)
        console.log(firstName, lastName, email)
    }

    const handleVerification = (e: any) => {
        e.preventDefault()
        setIsContinueClick(true)
        setIsSendVerification(false)
    }

    const handleConfirmSubmit = (e: any) => {
        e.preventDefault();
        setIsVerificationCodeSend(true)
        setTimeout(() => {
            setIsVerificationCodeSend(false)
            setIsSendVerification(true)
        }, 3000)
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setRegisterPasswordConfirm(e.target.value);
    };

    const handlePasswordChange = (e: any) => {
        setRegisterPassword(e.target.value);
    };

    const handleSaveClick = () => {
        setIsSaveClick(true);
        setIsContinueClick(false);
        setIsSendVerification(false)
        setVerificationCode('')
        setRegisterPassword('')
    };

    const handleBlock = () => {
        dispatch(setProviderplan("clinic"));
        navigate('/provider-demo-appointments')
    }

    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center ${isContinueClick ? "modal-xl" : "modal-lg"} `}
                >
                    <div className="modal-content p-0 shadow-none border-0 overflow-visible"
                        style={{ maxWidth: isContinueClick ? "" : "628px" }}>
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup">
                                {
                                    !isSubmit ? (
                                        <div className="waiting-login align-items-center">
                                            <h1 className="waiting-header">Welcome Back!</h1>
                                            <p className="desc d-flex justify-content-center align-items-center gap-4">
                                                Let's get your telehealth room set up while keeping your identity secure.
                                                <img src={Padlock} alt="" />
                                            </p>
                                            <form
                                                className="login-form d-flex flex-column gap-3 w-100 align-items-center"
                                                style={{ padding: "24px 13px" }}
                                                onSubmit={handleSubmit}
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control py-3 px-1"
                                                    placeholder="First Name"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control py-3 px-1"
                                                    placeholder="Last Name"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control py-3 px-1"
                                                    placeholder="Email Address"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <div className="remember d-flex align-items-center w-100">
                                                    <input type="checkbox" />
                                                    <span>Remember me on this computer</span>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="yes-theme-btn border-0 w-100"
                                                    style={{
                                                        padding: "16px 24px",
                                                        borderRadius: "6px",
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </form>
                                            <div
                                                className={`text-center login-logo ${isSaveClick && "d-none"}`}
                                            >
                                                <img src={LandingLogo} alt="" />
                                            </div>
                                        </div>
                                    ) : isContinueClick ? (
                                        <ProviderDemoProfile handleSaveClick={handleSaveClick} type='demo-flow' />
                                    ) : isSaveClick ? (
                                        <div className='waiting-login'>
                                        <div className="permission-option w-100 d-flex flex-column">
                                            <div className="d-flex justify-content-between align-items-center chat-header">
                                                <h1>Give permission to CentiBlick Connect to</h1>
                                                <img
                                                    src={CloseModelIcon}
                                                    alt="Close"
                                                    className="close-model-icon"
                                                    style={{ width: "20px" }}
                                                    onClick={handleBlock}
                                                />
                                            </div>
                                            <div className="permission-msg">
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{ marginBottom: "14px" }}
                                                >
                                                    <img src={MicPermission} alt="" />
                                                    <p>Use your microphone</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={faVideoSlash}
                                                        style={{ width: "24px" }}
                                                    />
                                                    <p>Use your camera</p>
                                                </div>
                                            </div>
                                            <div className="permission-btn d-flex gap-2">
                                                <button
                                                    className="save-theme-btn w-100"
                                                    style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                    onClick={handleBlock}
                                                >
                                                    Block
                                                </button>
                                                <button
                                                    className="yes-theme-btn border-0 w-100"
                                                    style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                    onClick={handleBlock}
                                                >
                                                    Allow
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    ) : (
                                        <div className="waiting-login align-items-center">
                                            <h1 className="waiting-header flex-row justify-content-center">{isVerificationCodeSend ? "Verification Email Sent" : isSendVerification ? "Please enter verification code" : "Choose username and password!"}
                                                <img src={Padlock} alt="" className={`${(isVerificationCodeSend || isSendVerification) && "d-none"}`} />
                                            </h1>
                                            <form
                                                className={`login-form d-flex flex-column gap-3 w-100 align-items-center ${isVerificationCodeSend && 'd-none'}`}
                                                style={{ padding: "24px 13px" }}
                                                onSubmit={isSendVerification ? handleVerification : handleConfirmSubmit}
                                            >
                                                {isSendVerification ? (
                                                    <input
                                                        type="text"
                                                        className={`form-control py-3 px-1 ${isVerificationCodeSend && "d-none"}`}
                                                        placeholder="Enter verification code"
                                                        value={verificationCode}
                                                        onChange={(e) => setVerificationCode(e.target.value)}
                                                        autoComplete="new-password"
                                                    />
                                                ) : (
                                                    <div className={`login-form d-flex flex-column gap-3 w-100 align-items-center ${isVerificationCodeSend && "d-none"}`}>
                                                        <input
                                                            type="text"
                                                            className="form-control py-3 px-1"
                                                            placeholder="User Name"
                                                            value={userName}
                                                            onChange={(e) => setUserName(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <div className="form-group w-100">
                                                            <CBSelectState
                                                                options={Specialty}
                                                                className='m-0 w-100 provider-specialty'
                                                                seletedItem={specialty}
                                                                placeholder='Select Specialty'
                                                                onChange={(option: string) => setSpecialty(option)}
                                                                type="Specialities"
                                                            />
                                                        </div>
                                                        <div className='w-100'>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={showPassword ? "text" : "password"}
                                                                    className="form-control py-3 px-1"
                                                                    placeholder="Password"
                                                                    value={registerPassword}
                                                                    onChange={handlePasswordChange}
                                                                    autoComplete="new-password"
                                                                />
                                                                <img
                                                                    src={showPassword ? EyeOpen : EyeClose}
                                                                    className="position-absolute cursor-pointer"
                                                                    style={{ right: "15px", bottom: "20px" }}
                                                                    onClick={handleTogglePasswordVisibility}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={showConfirmPassword ? "text" : "password"}
                                                                    className="form-control py-3 px-1"
                                                                    placeholder="Re-enter Password"
                                                                    value={registerPasswordConfirm}
                                                                    onChange={handleConfirmPasswordChange}
                                                                />
                                                                <img
                                                                    src={showConfirmPassword ? EyeOpen : EyeClose}
                                                                    className="position-absolute cursor-pointer"
                                                                    style={{ right: "15px", top: "22px" }}
                                                                    onClick={handleToggleConfirmPasswordVisibility}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="remember d-flex align-items-center w-100">
                                                            <input type="checkbox" />
                                                            <span>Remember me on this computer</span>
                                                        </div>
                                                    </div>
                                                )}
                                                <button
                                                    type="submit"
                                                    className={`yes-theme-btn border-0 w-100 ${isVerificationCodeSend && "d-none"}`}
                                                    style={{
                                                        padding: "16px 24px",
                                                        borderRadius: "6px",
                                                    }}
                                                >
                                                    Continue
                                                </button>
                                            </form>
                                            <div
                                                className={`text-center login-logo ${isSaveClick && "d-none"}`}
                                            >
                                                <img src={LandingLogo} alt="" />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderDemoSignUp