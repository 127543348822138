import React, { useEffect, useRef, useState } from 'react'

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../Store';

import SketchPicker from "react-color";
interface SecondContentProps {
    onChangePrimaryColor: (color: string) => void;
    onChangeSecondaryColor: (color: string) => void;
}
const SecondContent = ({ onChangePrimaryColor, onChangeSecondaryColor }: SecondContentProps) => {
    const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)

    const [isPrimaryPickerOpen, setIsPrimaryPickerOpen] = useState(false);
    const [isSecondaryPickerOpen, setIsSecondaryPickerOpen] = useState(false);
    const [PrimaryColor, setPrimaryColor] = useState(selectedPrimaryColor)
    const [SecondaryColor, setSecondaryColor] = useState(selectedSecondaryColor)


    const primaryColorBoxRef = useRef<HTMLDivElement>(null);
    const secondaryColorBoxRef = useRef<HTMLDivElement>(null);

    const handlePicker = (value: 'primary' | 'secondary') => {
        switch (value) {
            case 'primary':
                setIsPrimaryPickerOpen(!isPrimaryPickerOpen);
                // setIsSecondaryPickerOpen(false);
                break;
            case 'secondary':
                setIsSecondaryPickerOpen(!isSecondaryPickerOpen);
                // setIsPrimaryPickerOpen(false);
                break;
            default:
                break;
        }
    };
    const handlePrimaryColor = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
        const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}` 
        console.log('primaryColorBoxRef', primaryColorBoxRef.current,)
        setPrimaryColor(hexCode);
        onChangePrimaryColor(hexCode);
        setIsPrimaryPickerOpen(true);
    };

    const handleSecondaryColor = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
        const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}` 
        setSecondaryColor(hexCode);
        onChangeSecondaryColor(hexCode);
        setIsSecondaryPickerOpen(true);
    };

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (
                primaryColorBoxRef.current &&
                !primaryColorBoxRef.current.contains(event.target)
            ) {
                setIsPrimaryPickerOpen(false);
            }

            if (
                secondaryColorBoxRef.current &&
                !secondaryColorBoxRef.current.contains(event.target)
            ) {
                setIsSecondaryPickerOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [primaryColorBoxRef, secondaryColorBoxRef]);

    return (
        <div className='color-content-div'>
            <div>
                <div className='outer-box position-relative'  ref={primaryColorBoxRef} >
                    <div className="color-box primary" style={{ backgroundColor: PrimaryColor }} onClick={() => handlePicker("primary")}></div>
                    {isPrimaryPickerOpen &&
                        <div className="primary-picker-container">
                            <SketchPicker
                                color={PrimaryColor}
                                onChange={handlePrimaryColor}
                            />
                        </div>
                    }

                    <p className='color-text'>Primary Color</p>
                </div>
                <div className='outer-box position-relative' ref={secondaryColorBoxRef} >
                    <div className="color-box secondary" style={{ backgroundColor: SecondaryColor }} onClick={() => handlePicker("secondary")} ></div>
                    {isSecondaryPickerOpen &&
                        <div className="primary-picker-container">
                            <SketchPicker
                                color={SecondaryColor}
                                onChange={handleSecondaryColor}
                            />
                        </div>
                    }

                    <p className='color-text'>Secondary Color</p>
                </div>
            </div>
        </div>
    )
}

export default SecondContent