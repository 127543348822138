import { createSlice } from "@reduxjs/toolkit";
import { fetchPatientProfileData } from "../../Thunk/getProfileData/patientProfileThunks";
export interface AppState {
  PatientUserData: any;
  PatientProfileModal:boolean;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  PatientUserData: null,
  PatientProfileModal:false,
  isLoading: false,
  error: null,
};

const PatientProfileSlice = createSlice({
  name: "patientprofile",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatientProfileData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPatientProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.PatientUserData = action.payload;
        if (action.payload && !state.PatientUserData[0]?.first_name && !state.PatientUserData[0]?.last_name && !state.PatientUserData[0]?.phone) {
          state.PatientProfileModal = true;
        } else {
          state.PatientProfileModal = false;

        }
      })
      .addCase(fetchPatientProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
});

export const { resetState } = PatientProfileSlice.actions

export default PatientProfileSlice;
