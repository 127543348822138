import React, { useState } from 'react'
import images from '../../../../Assets/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

//css
import './FAQComponent.scss'

const FAQComponent = () => {

    const faqItems = [
        {
          question: "How do I know if my current telehealth platform is compliant?",
          answer: '',
        },
        {
          question: "Is our payment processing system HIPAA compliant?",
          answer: "To determine if your payment processing system is HIPAA compliant, ensure it includes necessary safeguards like encryption, secure access controls, and proper handling of protected health information (PHI). Non-compliant systems can expose you to data breaches and legal penalties. If your system lacks these features, it may not meet HIPAA standards, and you should consider switching to a compliant solution to protect patient data and avoid regulatory risks.",
        },
        {
          question: "Are we protected from fines and penalties with our BAA?",
          answer: "While a Business Associate Agreement (BAA) is essential for HIPAA compliance, it does not fully shield you from legal and financial consequences in the event of a breach. Even with a BAA in place, your practice remains responsible for ensuring that both you and your vendor adhere to HIPAA guidelines. If a breach occurs due to inadequate security measures, you could still face penalties and legal liabilities. A BAA is a crucial part of compliance, but it is not a complete safeguard.",
        },
        {
          question: "Can other providers using the same platform cause HIPAA Violations for our practice?",
          answer: "Yes, if your telehealth platform allows free accounts without strict provider verification, other providers could cause HIPAA violations. For example, a fake provider could create an account, spoof patient identities, and access sensitive data, putting your practice at risk. CentiBlick mitigates this by enforcing rigorous provider authentication, ensuring all users are verified healthcare professionals, protecting your practice from these risks.",
        },
        {
          question: "Am I locked into a contract?",
          answer: "Not at all! We don’t tie you down with a contract. We believe in keeping our customers with a great product and exceptional customer support. You can cancel at any time.",
        },
        {
          question: "Do you charge state tax?",
          answer: "If your state or country is subject to sales tax, VAT, or GST, you will see the rate and amount on the final purchase price before confirming the payment.",
        },
      ];


      const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

      const handleAccordionToggle = (index: any) => {
        setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
      };

  return (
     <div className="d-flex faq-content">
              <img src={images.PricingSideImage} alt="img" />
              <div className="content">
                <div className="faq-main-content">
                  <h1>Frequently asked questions</h1>
                  <p className="mt-3">
                    Everything you need to know about the product and billing.
                  </p>
                </div>
                <div className="side-content accordion mt-5" id="faqAccordion">
                  {faqItems.map((item, index) => (
                    <div key={index}>
                      <h2
                        className="accordion-header d-flex justify-content-between"
                        id={`faqHeading${index}`}
                      >
                        <div className="que">{item.question}</div>
                        <button
                          data-bs-toggle="collapse"
                          data-bs-target={`#faqCollapse${index}`}
                          aria-expanded={openAccordionIndex === index}
                          aria-controls={`faqCollapse${index}`}
                          onClick={() => handleAccordionToggle(index)}
                        >
                          <FontAwesomeIcon
                            icon={openAccordionIndex === index ? faMinus : faPlus}
                            style={{ color: "#516cd3" }}
                            className="icon"
                          />
                        </button>
                      </h2>
                      <div
                        id={`faqCollapse${index}`}
                        className={`accordion-collapse collapse ${openAccordionIndex === index ? "show" : ""
                          }`}
                        style={openAccordionIndex === 0 ? { height: "fit-content" } : {}}
                        aria-labelledby={`faqHeading${index}`}
                        data-bs-parent="#faqAccordion"
                      >
                        <div className="accordion-body px-0">
                          {
                            openAccordionIndex === 0 ?
                              <>
                                <h2 className="pb-4">5 Essential Questions to Ensure Your Telehealth Platform is HIPAA Compliant</h2>
                                <ol className="ps-4">
                                  <li>Does your telehealth platform ensure end-to-end transmission and session encryption for all communications and patient data?</li>
                                  <li>Are patient logins and provider authentication required to access telehealth sessions?</li>
                                  <li>Does your telehealth platform comply with HIPAA's Security Rule regarding data storage and transmission?</li>
                                  <li>What steps does the platform take to verify the identity and credentials of both providers and patients?</li>
                                  <li>Has your telehealth platform been updated to reflect the end of the COVID-19 Public Health Emergency, and are we compliant with current HHS guidelines?</li>
                                </ol>
                                <p className="pt-3">Click here for the Telehealth Compliance Whitepaper</p>
                              </>
                              :
                              item.answer
                          }
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </div>
  )
}

export default FAQComponent