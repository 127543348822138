import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { get } from "../../../Network/ApiService";

interface fetchClinicalProviderArgs {
    userId: string;
    authToken: string;
}

export const fetchClinicalProvider = createAsyncThunk<any, fetchClinicalProviderArgs, { state: RootState }>(
    "onboarding/fetchClinicalProvider",
    async ({ authToken, userId }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.onboarding.get_clinical_onboarding}?account_id=${userId}`;

            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error;
        }
    }
)