import React from 'react'
import images from '../../../../Assets/Images'

const step6 = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {

  return (
    <div className='fifth-step'>
      <div className='d-flex flex-column telehealth-step mx-auto '>
        <div className='text-center'>
          <h1>Congratulations! 🎉</h1>
          <p className='onboarding-detail pt-4'>Your platform has been set up. </p>
        </div>
        <div className='d-flex flex-column telehealth-flow'>
          <div className='text-center'>
            <img src={images.MailLogo} alt="" />
          </div>
          <div className='text-center telehealth'>
            <div className='d-flex flex-column gap-3'>
              <div>
                <span className='fifth-step-text'>The admin you have designated during set up will receive log-in information and instructions to start inviting patients and providers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default step6