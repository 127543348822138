import { createSlice } from "@reduxjs/toolkit";
import { fetchAllAdminProfileData } from "../../Thunk/getProfileData/FetchAllAdminProfile";
export interface AppState {
    AllAdminUserData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
    AllAdminUserData: null,
  isLoading: false,
  error: null,
};

const AllAdminProfileSlice = createSlice({
  name: "alladminprofile",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAdminProfileData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllAdminProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.AllAdminUserData = action.payload;
      })
      .addCase(fetchAllAdminProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default AllAdminProfileSlice;
