
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';


interface ProviderSchedulingRule {
    authToken:string;
    provider_id:string;
}

// Define the return type for the Thunk action
interface SchedulingRuleMessages {
    messages: any;
}

// Create the Thunk action
export const fetchProviderSchedulingRule = createAsyncThunk<SchedulingRuleMessages, ProviderSchedulingRule, { state: RootState }>(
    'schedulingRule/fetchProviderSchedulingRule',
    async ( {authToken,provider_id}, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.provider_scheduling_rules.get_scheduling_rule_by_provider}?provider_id=${provider_id}`;
            const response = await get(ApiEndPoint,authToken);
            if (response) { 
                return response.data;
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error;
        }
    }
);