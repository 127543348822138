
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';

//! from_date_time and to_date_time format is '2024-02-29T18:30:00.000Z'
interface FetchSchedulingAppoinmentArgs {
    from_date_time:string;
    to_date_time:string;
    authToken:string
}

// Define the return type for the Thunk action
interface SchedulingappoinmentMessages {
    messages: any;
}

// Create the Thunk action
export const fetchScheduliingAppoinments = createAsyncThunk<SchedulingappoinmentMessages,FetchSchedulingAppoinmentArgs, { state: RootState }>(
    'scheduling/fetchSchedulingAppoinments',
    async ({from_date_time,to_date_time,authToken}, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.scheduling.get_schedule}?from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
            const response = await get(ApiEndPoint,authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error;
        }
    }
);
