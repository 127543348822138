import Common from "./Common";
import Login from "./Login";
import Signup from "./Signup";

const TRANSLATIONS_FR = {
  Common,
  Login,
  Signup,
};
export default TRANSLATIONS_FR;
