import { Auth } from 'aws-amplify';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import '../../../Pages/Provider/ProviderLoginFlow/WaitingScreen.scss'
import { EyeClose, EyeOpen, LandingLogo, LoginPopupBG, Padlock } from '../../../Assets/Svgs';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { ToastMessage } from '../ToastMessage';



const ForgotPassword = () => {

    const location = useLocation()
    const [showModal, setShowModal] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);


    const handleSubmit = async (values: any) => {
        console.log("🚀 ~ handleSubmit ~ value:", values)
        if (location.state !== 'demo-center') {
            toast.loading('Please wait...')
            try {
                await Auth.forgotPasswordSubmit(values.email, values.password, values.confirmPassword);
                toast.dismiss()
                ToastMessage("success",'Password successfully reset.');
            } catch (error) {
                toast.dismiss()
                ToastMessage("error",'Something went Wrong. Please try again');
            }
        }

    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const initialValues = {
        email: "",
        password: "",
        confirmPassword: ""
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[\W_]/, 'Password must contain at least one special character'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match'),
    });

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center modal-lg`}
                >
                    <div className="modal-content bg-transparent p-0 shadow-none border-0 overflow-visible" style={{ maxWidth: "628px", backgroundColor: '#ffffff66' }}>
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup" style={{ background: 'transparent' }}>
                                <div className="waiting-login">
                                    <h1 className="waiting-header flex-row gap-4 align-items-center justify-content-center h-auto">Let’s setup a new password!
                                        <img src={Padlock} alt="" height={42} width={32} /> </h1>
                                    {/* <p className="desc">Let’s setup a new password</p> */}
                                    <Formik
                                        onSubmit={handleSubmit}
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                    >

                                        {(formik) => (
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <h1 className="signin-header">Enter New Password</h1>
                                                    <Form
                                                        className="login-form d-flex flex-column"
                                                    >
                                                        <div>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Email"
                                                                name='email'
                                                                autoComplete='new-email'
                                                            />
                                                            <p className='text-start text-danger'>
                                                                <ErrorMessage name="email" />
                                                            </p>
                                                        </div>
                                                        <div>

                                                            <div className="position-relative">
                                                                <Field
                                                                    type={showPassword ? "text" : "password"}
                                                                    className="form-control"
                                                                    placeholder="Create New Password"
                                                                    name='password'
                                                                    autoComplete='new-password'
                                                                />
                                                                <img
                                                                    src={showPassword ? EyeOpen : EyeClose}
                                                                    className="position-absolute cursor-pointer"
                                                                    style={{ right: "15px", bottom: "20px" }}
                                                                    onClick={handleTogglePasswordVisibility}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className='text-start text-danger'>
                                                                <ErrorMessage name="password" />
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className="position-relative">
                                                                <Field
                                                                    type={showConfirmPassword ? "text" : "password"}
                                                                    className="form-control"
                                                                    placeholder="Re-Enter New Password"
                                                                    name='confirmPassword'
                                                                    autoComplete='new-password'
                                                                />
                                                                <img
                                                                    src={showConfirmPassword ? EyeOpen : EyeClose}
                                                                    className="position-absolute cursor-pointer"
                                                                    style={{ right: "15px", bottom: "20px" }}
                                                                    onClick={handleToggleConfirmPasswordVisibility}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className='text-start text-danger'>
                                                                <ErrorMessage name="confirmPassword" />
                                                            </p>
                                                        </div>
                                                        <div className="remember d-flex align-items-center">
                                                            <input type="checkbox" />
                                                            <span>Remember me on this computer</span>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                            disabled={formik.isSubmitting || !formik.isValid}
                                                        >
                                                            Continue
                                                        </button>
                                                    </Form>
                                                </div>
                                                <div
                                                    className={`text-center login-logo`}
                                                >
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword