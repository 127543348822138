// assets
import images from "../../Assets/Images";

const themeComponents: any = {
  blue: {
    Encounters: images.Encounters,
    Analytics: images.Analytics,
    SelectInfo: images.SelectInfo,
    DefaultImage: images.DefaultImage,
    LaptopPlan: images.LaptopPlan,
    CHPortalHybrid: images.CHPortalHybrid,
    CopyLogo: images.CopyLogo,
    EditIcon: images.EditIcon,
    ClockThree: images.ClockThree,
    ClockThreeWhite: images.ClockThreeWhite,
    shadedLogo: images.shadedLogo,
    Logo: images.Logo,
    ChatLineIcon: images.ChatLineIcon,
    mailSendLineLogo: images.mailSendLineLogo,
    messagesOutlineLogo: images.messagesOutlineLogo,
    CopyToClipBoardImage: images.CopyToClipBoardImage,
    CalenderIcon: images.CalenderIcon,
    sidebarUser: images.sidebarUser,
    eyeIcon: images.eyeIcon,
    sidebarJack: images.sidebarJack,
    sidebarJane: images.sidebarJane,
    profileLogo: images.profileLogo,
    scheduleLogo: images.scheduleLogo,
    BorderLogo: images.BorderLogo,
    leftArrow: images.leftArrow,
    greyArrow: images.greyArrow,
    search: images.search,
    plusLogo: images.plusLogo,
    authLogo: images.authLogo,
    ProviderLogo: images.ProviderLogo,
    authImage: images.authImage,
    languageImage: images.languageIcon,
    fbIcon: images.FB,
    sidebarLogo: images.sidebarLogo,
    sidebarGraph: images.sidebarGraph,
    sidebarUpload: images.sidebarUpload,
    sidebarReply: images.sidebarReply,
    navProfile: images.navProfile,
    navCareTeams: images.navCareteams,
    navCareHistory: images.navCareHistory,
    navMedications: images.navMedications,
    navProcedures: images.navProcedures,
    navHealthTesting: images.navHealthTesting,
    navLearningHealthSystem: images.navLearningHealthSystem,
    navGoal: images.navGoal,
    rightArrow: images.rightArrow,
    listMedication: images.listMedication,
    listStethoscope: images.listStethoscope,
    listDoctor: images.listDoctor,
    listWatch: images.listWatch,
    listDiabetes: images.listDoctor,
    listPreventDiabetes: images.listPreventDiabetes,
    CHCare: images.CHCare,
    CHMedication: images.CHMedication,
    CHDevices: images.CHDevices,
    CHPortal: images.CHPortal,
    CHRecords: images.CHRecords,
    CHForms: images.CHForms,
    CHCareLogo: images.CHCareLogo,
    CHPatientPortalLogo: images.CHPatientPortalLogo,
    CHDevicesLogo: images.CHDevicesLogo,
    Down: images.Down,
    CarePlan: images.CarePlan,
    HealthScreen: images.HealthScreen,
    LabTest: images.labTest,
    LabPanel: images.labPanel,
    ChatIcon: images.chatIcon,
    ExclamationIcon: images.exclamationIcon,
    hsListItem: images.hsImgListItem,
    hsInnerListItem: images.hsInnerListImage,
    vitalsBMI: images.vitalsBMI,
    vitalsBP: images.vitalsBP,
    vitalsWeight: images.vitalsWeight,
    vitalsRespiratory: images.vitalsRespiratory,
    vitalsOxygen: images.vitalsOxygen,
    vitalsTest: images.vitalsTest,
    signupSuccess: images.signupSuccess,
    VisitNow: images.visitNow,
    Appointment: images.appointment,
    VisitNowActive: images.visitNowActive,
    AppointmentActive: images.appointmentActive,
    DrFilterImg: images.filterImg,
    ProviderJulie: images.ProviderJulie,
    timeHeaderImg: images.timeHeaderImg,
    ProviderQuay: images.ProviderQuay,
    ProviderRosa: images.ProviderRosa,
    ProviderMarco: images.ProviderMarco,
    providerKelly: images.providerKelly,
    providerJill: images.providerJill,
    providerJohn: images.providerJohn,
    providerJana: images.providerJana,
    providerMyra: images.providerMyra,
    timeHeadRightImg: images.timeRightBlue,
    providerLogin: images.providerLogin,
    adminLogin: images.adminLogin,
    medicine1: images.medicine1,
    medicine2: images.medicine2,
    wellDoc: images.wellDoc,
    wellDocLogo: images.wellDocLogo,
    wellDocSmallLogo: images.wellDocSmallLogo,
    goalProfileFood: images.goalProfileFood,
    goalProfile: images.goalProfile,

    // patient images

    PatientSidebarArrow: images.PatientSidebarArrow,
    PatientSidebarCloud: images.PatientSidebarCloud,

    //provider images
    providerSidebarUser: images.providerSidebarUser,
    providerAppointments: images.providerAppointments,
    providerTasks: images.providerTasks,
    providerTasksSquare: images.providerTasksSquare,
    providerEHR: images.providerEHR,
    providerEHRSquare: images.providerEHRSquare,
    providerReffarals: images.providerReffarals,
    providerPrescribe: images.providerPrescribe,
    providerPrescribeSquare: images.providerPrescribeSquare,
    providerWelcome: images.providerWelcome,
    appointmentImage: images.appointmentImage,

    // Admin images
    adminHomePamel: images.adminHomePamel,
    carouselPatientProfile: images.carouselPatientProfile,
    carouselProviderProfile: images.carouselProviderProfile,
    carouselSchedule: images.carouselSchedule,
    carouselMetricsPlanning: images.carouselMetricsPlanning,
    carouselForecasting: images.carouselForecasting,
    carouselTaskManagement: images.carouselTaskManagement,
    carouselPatientAppointments: images.carouselPatientAppointments,
    carouselProviderScheduling: images.carouselProviderScheduling,
    carouselPatientReferrals: images.carouselPatientReferrals,
  },
  parrot: {
    authLogo: images.parrotAuthLogo,
    authImage: images.parrotAuthImage,
    languageImage: images.parrotLanguageIcon,
    fbIcon: images.parrotFB,
    sidebarLogo: images.parrotSidebarLogo,
    sidebarGraph: images.parrotSidebarGraph,
    sidebarUpload: images.parrotSidebarUpload,
    sidebarReply: images.parrotSidebarReply,
    navProfile: images.parrotNavProfile,
    navCareTeams: images.parrotNavCareteams,
    navCareHistory: images.parrotNavCareHistory,
    navMedications: images.parrotNavMedications,
    navProcedures: images.parrotNavProcedures,
    navHealthTesting: images.parrotNavHealthTesting,
    navLearningHealthSystem: images.parrotNavLearningHealthSystem,
    navGoal: images.parrotNavGoal,
    listMedication: images.parrotListMedication,
    listStethoscope: images.parrotListStethoscope,
    listDoctor: images.parrotListDoctor,
    listWatch: images.parrotListWatch,
    listDiabetes: images.ParrotListDiabetes,
    rightArrow: images.ParrotRightArrow,
    CHCare: images.ParrotCHCare,
    CHMedication: images.ParrotCHMedication,
    CHDevices: images.ParrotCHDevices,
    CHPortal: images.ParrotCHPortal,
    CHRecords: images.ParrotCHRecords,
    Down: images.ParrotDown,
    CarePlan: images.CarePlanParrot,
    HealthScreen: images.parrotHealthScreen,
    LabTest: images.parrotLabTest,
    LabPanel: images.parrotLabPanel,
    ChatIcon: images.parrotChatIcon,
    ExclamationIcon: images.exclamationIcon,
    hsListItem: images.hsImgListItemParrot,
    hsInnerListItem: images.hsInnerListImage,
    vitalsBMI: images.vitalsBMIParrot,
    vitalsBP: images.vitalsBPParrot,
    vitalsWeight: images.vitalsWeightParrot,
    vitalsRespiratory: images.vitalsRespiratoryParrot,
    vitalsOxygen: images.vitalsOxygenParrot,
    vitalsTest: images.vitalsTestParrot,
    signupSuccess: images.signupSuccessParrot,
    VisitNow: images.parrotVisitNow,
    Appointment: images.parrotAppointment,
    VisitNowActive: images.parrotVisitNowActive,
    AppointmentActive: images.parrotAppointmentActive,
    DrFilterImg: images.parrotFilterImg,
    providerKelly: images.providerKelly,
    providerJill: images.providerJill,
    providerJohn: images.providerJohn,
    providerJana: images.providerJana,
    providerMyra: images.providerMyra,
    timeHeadRightImg: images.timeRightParrot,
    providerLogin: images.parrotProviderLogin,
    adminLogin: images.parrotAdminLogin,
    medicine1: images.parrotMedicine1,
    medicine2: images.parrotMedicine2,
    wellDoc: images.wellDocParrot,
    wellDocLogo: images.wellDocLogoParrot,
    //provider images
    providerSidebarUser: images.parrotProviderSidebarUser,
    providerAppointments: images.parrotProviderAppointments,
    providerTasks: images.parrotProviderTasks,
    providerEHR: images.parrotProviderEHR,
    providerPrescribe: images.parrotProviderPrescribe,
    providerWelcome: images.parrotProviderWelcome,
    appointmentImage: images.parrotAppointmentImage,

    // Admin images
    adminHomePamel: images.ParrotAdminHomePamel,
    carouselPatientProfile: images.parrotCarouselPatientProfile,
    carouselProviderProfile: images.parrotCarouselProviderProfile,
    carouselSchedule: images.parrotCarouselSchedule,
    carouselMetricsPlanning: images.parrotCarouselMetricsPlanning,
    carouselForecasting: images.parrotCarouselForecasting,
    carouselTaskManagement: images.parrotCarouselTaskManagement,
  },
};

export default themeComponents;
