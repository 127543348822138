import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderListByAvailability } from "../../Thunk/SchedulingRulesProvider/getProviderListByAvailability";

export interface AppState {
    ProviderListByAvailabilityData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
    ProviderListByAvailabilityData: null,
  isLoading: false,
  error: null,
};

const ProviderListByAvailability = createSlice({
  name: "SchedulingRuleAvailability",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviderListByAvailability.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProviderListByAvailability.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderListByAvailabilityData = action.payload;
      })
      .addCase(fetchProviderListByAvailability.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default ProviderListByAvailability;
