import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';



interface ProviderListByAvailability {
    authToken:string;
    start_time:string;
    end_time:string;
    service:string;
    day:string;
}

// Define the return type for the Thunk action
interface ProviderListByAvailabilityMessages {
    messages: any;
}

// Create the Thunk action
export const fetchProviderListByAvailability = createAsyncThunk<ProviderListByAvailabilityMessages, ProviderListByAvailability, { state: RootState }>(
    'ProviderListByAvailability/fetchProviderListByAvailability',
    async ( {authToken,start_time,end_time,service,day}, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.provider_scheduling_rules.get_provider_list_by_availability}?start_time=${start_time}&end_time=${end_time}&service=${service}&day=${day}`;
            const response = await get(ApiEndPoint,authToken);
            if (response) { 
                return response.data;
            } else {
                throw new Error('Failed to fetch provider Availability data');
            }
        } catch (error) {
            throw error;
        }
    }
);