
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface FetchLeaveRequesteDataArgs {
    authToken: string;
}

// Define the return type for the Thunk action
interface LeaveRequestResponse {
    messages: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchLeaveRequestData = createAsyncThunk<LeaveRequestResponse, FetchLeaveRequesteDataArgs, { state: RootState }>(
    'admin/getAllLeaveRequest',
    async ({ authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.leave_requests.get_all_leave_requests}`;
            const response = await get(ApiEndPoint, authToken);

            if (response) {
                return response.data; // This value will be the payload of the fulfilled action
            } else {
                throw new Error('Failed to Fetch Leave Requests');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
