import { createSlice } from "@reduxjs/toolkit";
import { fetchAllProviderProfileData } from "../../Thunk/getProfileData/allProviderProfileThunk";
export interface AppState {
    AllProviderUserData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    AllProviderUserData: [],
    isLoading: false,
    error: null,
};

const AllProviderProfileSlice = createSlice({
    name: "allproviderprofile",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProviderProfileData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllProviderProfileData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AllProviderUserData = action.payload;
            })
            .addCase(fetchAllProviderProfileData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});


export default AllProviderProfileSlice;
