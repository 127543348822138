
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../../Network/ApiUrl';
import { get } from '../../../../Network/ApiService';
import { RootState } from '../../..';

interface FetchAppointmentDataArgs {
    user_id: string;
    from_date_time:string;
    to_date_time:string;
    authToken: string;
}

// Define the return type for the Thunk action
interface AppointmentData {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchAllAppointmentData = createAsyncThunk<AppointmentData, FetchAppointmentDataArgs, { state: RootState }>(
    'appointment/fetchAllAppointmentData',
    async ({ user_id,from_date_time,to_date_time, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint;
                ApiEndPoint = `${ApiUrl.appointments.get_patient_appointments_all_included}?provider_id=${user_id}&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
            const response = await get(ApiEndPoint, authToken);
            if (response) {               
                return response.data;
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
