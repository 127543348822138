import { faAngleDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import "./cbSelect.scss";
import images from "../../../Assets/Images";

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  defaultOption?: Option;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
  variant?: "default" | "outline";
  seletedItem?: any;
  disabled?: boolean; // Adjusted the type to boolean
}

const CBSelect: React.FC<Props> = (props) => {
  const {
    options,
    defaultOption,
    onChange,
    placeholder,
    className,
    seletedItem,
    disabled,
    variant,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option>({
    label: seletedItem?.appointment_duration
      ? seletedItem?.appointment_duration + " minutes"
      : seletedItem?.value
        ? seletedItem?.value + " minutes"
        : seletedItem?.gender
          ? seletedItem?.gender
          : placeholder || defaultOption?.label || "",
    value:
      seletedItem?.appointment_duration ||
      seletedItem?.value ||
      defaultOption?.value ||
      "",
  });

  const dropdownRef: any = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (option: Option) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option.value);
    }
    setOpen(false);
  };

  const handClick = () => {
    setOpen(!open);
  };

  if (disabled) {
    return (
      <div
        className={`cb-select-wrapper disabled  ${variant === "outline" ? "cb-outline-none" : ""
          }  ${className}`}
      >
        {/* Placeholder for the disabled state */}
        <div className="cb-select disabled">{selectedOption.label}</div>
      </div>
    );
  }

  return (
    <div
      ref={dropdownRef}
      className={`cb-select-wrapper  ${variant === "outline" ? "cb-outline-none" : ""
        }  ${className}`}
    >
      <div
        className={`cb-select ${open ? "cb-select-active" : ""}`}
        onClick={handClick}
      >
        {selectedOption && selectedOption.label}
        <div className="icon-div" style={variant === 'outline' ? { marginTop: '-13px' } : {}} >
          {variant === "outline" ? (
            <FontAwesomeIcon icon={faAngleDown} style={{ width: '24px', marginRight: '40px' }} color="#516cd3"/>
          ) : className === "break-dropdown" ? (
            <FontAwesomeIcon icon={faAngleDown} color="#000000" size="sm" />
          ) : (
            <FontAwesomeIcon icon={faCaretDown} color="#000000" />
          )}
        </div>
      </div>

      {open && (
        <div
          className={`cb-select-options-wrapper ${variant === "outline" ? "cb-outline-none" : ""
            }`}
        >
          {options &&
            options.map((option: Option, index: number) => (
              <li
                key={`${index}-${option.value}-cb-select-box`}
                onClick={() => handleChange(option)}
              >
                {option?.label}
              </li>
            ))}
        </div>
      )}
    </div>
  );
};

export default CBSelect;
