import React, { useState } from 'react'

//css
import './CentiBlickHomePage.scss'
import StickyHeader from './Header/StickyHeader'
import images from '../../../Assets/Images'
import Footer from './Footer/Footer'
import { useNavigate } from 'react-router-dom'
import ResourceComponent from './ResourceComponent/ResourceComponent'

const CentiBlickHomePage = () => {

    const navigate = useNavigate()

    const Features = [
        { name: 'Compliance First', details: 'CentiBlick is designed to fully comply with HIPAA and HHS guidelines' },
        { name: 'Enhanced Security', details: 'Secure your telehealth sessions with end-to-end encryption, patient logins, & provider authentication' },
        { name: 'Seamless Integration', details: 'Easily integrate CentiBlick into your existing workflows with minimal disruption' },
        { name: 'Risk Mitigation', details: 'Stay ahead of regulatory changes & avoid penalties with a platform built for today’s healthcare environment' }
    ]

    const complianceData = [
        {
            title: "Secure Encrypted Appointment Links",
            description:
                "Other platforms’ open links sent via text or emails without login requirements pose significant security risk. These links can be intercepted or accessed by unauthorized individuals, leading to potential breaches of protected health information (PHI).",
            imageUrl: images.FirstCompliance,
            buttonText: "Learn More",
        },
        {
            title: "Access Control & Authentication",
            description:
                "HIPAA mandates the verification of individuals accessing PHI. Other platforms' open links without authentication do not meet this requirement, increasing the risk of unauthorized access.",
            imageUrl: images.SecondCompliance,
            buttonText: "Learn More",
        },
        {
            title: "Provider Registration & Identity Management",
            description:
                "As part of HIPAA compliance, it is essential to verify the identity and credentials of all providers using the telehealth platform. This ensures that only authorized and qualified individuals have access to sensitive patient information.",
            imageUrl: images.ThirdCompliance,
            buttonText: "Learn More",
        },
        {
            title: "End-to-End Encryption",
            description:
                "While other platforms’ telehealth sessions might be encrypted, the transmission of open links is not, exposing sensitive information to potential breaches and unauthorized patient and provider data access.",
            imageUrl: images.ForthCompliance,
            buttonText: "Learn More",
        },
    ];

    const pricingData = [
        {
            title: "Individual Provider",
            description:
                "Our individual provider platform offers a secure and encrypted solution for your telehealth needs. Enjoy identity protection, verified patient profiles, detailed encounter summaries, and a modern best-in-class experience for both you and your patients.",
            imageUrl: images.EmployerWebsitePicture,
            buttonText: "Get Started",
            use: 'Unlimited use',
            price: "$35",
            duration: '/month',
            url: '/pricing'
        },
        {
            title: "Clinic Platform",
            description:
                "Our Clinic Platform includes all individual provider features plus a state-of-the-art admin center. Enjoy provider room directories, detailed patient and provider profiles, encounter summaries, and comprehensive platform analytics for a streamlined, modern experience.",
            imageUrl: images.ClinicPictureOption,
            buttonText: "Request a Demo",
            use: 'Unlimited use',
            price: "$50",
            duration: '/provider/month',
            url: '/pricing'
        },
        {
            title: "Add-On Features",
            description:
                "Start with the core features and expand with add-on functionalities as your needs grow. Access HIPAA Compliant Payments, 2-way EHR Integration, Flexcare virtual + location scheduling, patient appointments, 360 Medical Records, AI charting and so much more.",
            imageUrl: images.PlatformUpgradesImage,
            buttonText: "Learn More",
            price: "Quoted",
            url: '/contact-sales'
        },
    ];

    const securityData = [
        { title: 'HIPPA Compliant' },
        { title: 'GDPR Compliant' },
        { title: 'HiTrust Compliant' },
        { title: 'SOC2 Certified' },
        { title: 'FedRAMP Certified' },
        { title: 'End-to-end Encryption' },
    ]

    const [selectedCompliance, setSelectedCompliance] = useState(complianceData[0]);
    const [animationClass, setAnimationClass] = useState('');

    const handleComplianceChange = (data: any, index: any) => {
        const currentIndex = complianceData.findIndex(item => item.title === selectedCompliance.title);

        if (selectedCompliance.title !== data.title) {
            if (index > currentIndex) {
                setAnimationClass('slide-in-right');
            } else if (index < currentIndex) {
                setAnimationClass('slide-in-left');
            }

            setSelectedCompliance(data);
            setTimeout(() => {
                setAnimationClass('');
            }, 500);
        }
    };

    return (
        <div className='centiblick-home-page'>
            <div className='centiblick-header'>
                <StickyHeader />
            </div>
            <div className='hipaa-compliant position-relative d-flex gap-4 position-relative'>
                <img src={images.PricingBg} alt="" className='first-bg' />
                <img src={images.PricingBg} alt="" className='second-bg' />
                <img src={images.PricingBg} alt="" className='third-bg' />
                <img src={images.PricingBg} alt="" className='forth-bg' />
                <img src={images.PricingBg} alt="" className='fifth-bg' />
                <img src={images.PricingBg} alt="" className='sixth-bg' />
                <div className='z-1'>
                    <h1 className='my-4'>
                        HIPAA & HHS Compliant
                    </h1>
                    <h2>
                        Telehealth Platform
                    </h2>
                    <p className='my-4'>
                        CentiBlick provides a secure, compliant telehealth platform that integrates seamlessly into your practice, reducing legal risks while enhancing patient care
                    </p>
                    <div className='mt-3 d-flex gap-5'>
                        <button className='rounded-save-theme-btn'>Get Started</button>
                        <button className='save-theme-btn' onClick={() => navigate('/request-demo')}>Request a Demo</button>
                    </div>
                </div>
                <div className='z-1 w-100'>
                    <img src={images.HomePageProvider} alt="" className='provider-img' />
                    <p className='mt-3 text-center' style={{ color: "#516cd3" }}>Telehealth powered by CentiBlick</p>
                </div>
            </div>
            <div className='centiblick-features'>
                <div className='row'>
                    {
                        Features?.map((data: any) => (
                            <div className='col-lg-3 col-md-6 col-sm-6 col-xs-12 d-flex mt-4'>
                                <div className='feature-details'>
                                    <h1 className='mb-3'>{data.name}</h1>
                                    <p>{data.details}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='compliance-container w-100'>
                <h1 className='compliance-header'>Must Have Compliance Features</h1>
                <div className='w-100 d-flex justify-content-end position-relative' style={{ marginTop: '100px' }}>
                    <div className='position-absolute start-0 z-1'>
                        {complianceData.map((data, index) => (
                            <div
                                className='compliance-data'
                                key={index}
                                onClick={() => handleComplianceChange(data, index)}
                                style={selectedCompliance.title === data.title ? { background: "#516cd3", color: "#fff" } : {}}
                            >
                                {data.title}
                            </div>
                        ))}
                    </div>
                    <div className={`compliance-div d-flex gap-3 ${animationClass}`}>
                        <img src={selectedCompliance.imageUrl} alt="" />
                        <div className='compliance-details'>
                            <div>
                                <h1>{selectedCompliance.title}</h1>
                                <p className='mt-3'>{selectedCompliance.description}</p>
                            </div>
                            <button className='save-theme-btn w-75'>{selectedCompliance.buttonText}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pricing-container'>
                <h1 className='text-center'>We Make Compliance Easy: <br /> Just Swap The Link!</h1>
                <div className='pricing-div container mt-5'>
                    {pricingData.map((item, index) => (
                        <div className="pricing-item w-100" key={index}>
                            <img src={item.imageUrl} alt={item.title} className="pricing-image" />
                            <div className='d-flex flex-column justify-content-evenly align-items-center'>
                                <div>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center w-100 pricing-button'>
                                    <h2 className='price'>{item.price} <span>{item.duration}</span></h2>
                                    <button className='save-theme-btn' onClick={() => window.open(item.url, '_blank')}>{item.buttonText}</button>
                                </div>
                                <p className='useage'>{item.use}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='security-container'>
                <div className='security-div w-50'>
                    <h1 className=''>Enterprise Security</h1>
                    <div className='security-type mt-5'>
                        {
                            securityData.map((item, index) => (
                                <div className='d-flex align-items-center security-details'
                                    onClick={() => navigate('enterprise-security')}>
                                    <img src={images.SecurityCheck} alt="" />
                                    <h1>{item.title}</h1>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div>
                <ResourceComponent />
            </div>
            <Footer />
        </div>
    )
}

export default CentiBlickHomePage