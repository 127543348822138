import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { ApiUrl } from "../../../Network/ApiUrl";
import { get } from "../../../Network/ApiService";

interface StatusParams {
    status: "canceled" | "confirmed" | "pending", authToken: string, next_key: string
}

export const fetchAppointmentByStatus = createAsyncThunk<any, StatusParams, { state: RootState }>(
    "appointment/fetchAppointmentByStatus",
    async ({ status, authToken, next_key }, { dispatch }) => {
        try {
            let ApiEndPoint
            if (next_key == null) {
                ApiEndPoint = `${ApiUrl.appointments.get_appoinment_by_status}?status=${status}`;
            } else {
                ApiEndPoint = `${ApiUrl.appointments.get_appoinment_by_status}?status=${status}?next_key=${next_key}`;
            }
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error("Failed to fetch Patient date view");
            }
        } catch (error: any) {
            throw error;
        }
    }
);