import { createSlice } from "@reduxjs/toolkit";
import { fetchAppointmentByStatus } from "../../Thunk/Appointment/getAppoinmentByStatusThunk";

export interface Appointment {
    service_name: string;
    location: string;
    patient_name: string;
    status: string;
    provider_id: string;
    provider_email: string;
    provider_profile_image_path: string;
    to_date_time: string;
    from_date_time: string;
    service_id: string;
    patient_email: string;
    patient_dob: string;
    notes: string;
    event_id: string;
    patient_profile_image_path: string;
    provider_name: string;
    patient_id: string;
  }
export interface AppState {
    Data: Appointment[];
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    Data: [],
    isLoading: false,
    error: null,
};

const AppointmentByStatusSlice = createSlice({
    name: "AppointmentByStatus",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppointmentByStatus.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAppointmentByStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.Data = action.payload;
            })
            .addCase(fetchAppointmentByStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default AppointmentByStatusSlice;