import React from 'react'

import '../ProfileFlow.scss'
import { InviteFlowRocket } from '../../../Assets/Svgs'

const FinalFlowPage = ({handleRedirect}:any) => {
    return (
        <div className='final-flow' style={{padding:"83px"}}>
            <div>
                <h1 className='flow-header text-center' style={{paddingBottom:"12px"}}>
                    Let’s get started!
                </h1>
                <p className='final-desc' style={{paddingBottom:"20px"}}>
                    A client success specialist will be reaching out to schedule training soon!
                </p>
                <p className='final-desc'>
                    If you need immediate help, <span>click here</span>
                </p>
            </div>
            <div>
                <img src={InviteFlowRocket} alt="" />
            </div>
            <div className='d-flex justify-content-center gap-4'>
                <button className='back-theme-btn'>Back</button>
                <button className='btn yes-theme-btn' onClick={handleRedirect}>Click here for Provider Center Home</button>
            </div>
        </div>
    )
}

export default FinalFlowPage