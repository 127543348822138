import React from 'react'

//css
import './EnterpriseSecurity.scss'
import StickyHeader from '../Header/StickyHeader'
import Footer from '../Footer/Footer'
import images from '../../../../Assets/Images'

const EnterpriseSecurity = () => {

    const SecurityDetails = [
        { title: 'HIPPA', description: 'The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is legislation that is designed to make it easier for US workers to retain health insurance coverage when they change or lose their jobs. The legislation also seeks to encourage electronic health records to improve the efficiency and quality of the US healthcare system through improved information sharing.', imgUrl: images.Hipaa },
        { title: 'HITRUST', description: 'The HITRUST CSF (Cloud Security Framework) serves to unify security controls based on aspects of US federal law (such as HIPAA and HITECH), state law (such as Massachusetts’ Standards for the Protection of Personal Information of Residents of the Commonwealth) and recognized non-governmental compliance standards (such as PCI DSS) into a single framework that is tailored for healthcare needs.', imgUrl: images.Hitrust },
        { title: 'HITECH', description: 'Health Information Technology for Economic and Clinical Health Act (HITECH) expanded the HIPAA rules in 2009. HIPAA and HITECH together establish a set of federal standards intended to protect the security and privacy of PHI.', imgUrl: images.Hitech },
        { title: 'FedRAMP', description: 'The Federal Risk and Authorization Management Program (FedRAMP) is a government-wide program that promotes the adoption of secure cloud services across the federal government by providing a standardized approach to security assessment, authorization, and continuous monitoring for cloud products and services.', imgUrl: images.Fedramp },
        { title: 'AICPASOC', description: 'System and Organization Controls (SOC) Reports are independent third-party examination reports that demonstrate how powered by CentiBlick achieves key compliance controls and objectives. The purpose of these reports is to help you and your auditors understand the controls established to support operations and compliance.', imgUrl: images.AICPASOC },
        { title: 'GDPR', description: 'The European Union’s General Data Protection Regulation (GDPR) protects European Union (EU) individuals’ fundamental right to privacy and the protection of personal data. The GDPR includes robust requirements that raise and harmonize standards for data protection, security, and compliance.', imgUrl: images.GDPR },
    ]
    return (
        <div className='enterprise-security'>
            <div className='centiblick-header'>
                <StickyHeader />
            </div>
            <div className='security-main-div'>
                <div className='security-trust'>

                </div>
                <div className='securities container'>
                    {SecurityDetails?.map((security) => (
                        <div>
                            <div className='security-title'>
                                <h1>{security.title}</h1>
                            </div>
                            <hr className='my-4' style={{ color: '#E0E0E0' }} />
                            <div className='d-flex justify-content-between align-items-start security-details'>
                                <img src={security.imgUrl} alt="" />
                                <p>
                                    {security.description}
                                </p>
                            </div>
                            <hr style={{ margin: "95px 0px", color: "#626262" }} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EnterpriseSecurity