import { createSlice } from "@reduxjs/toolkit";
import { fetchLeaveRequestByDate } from "../../Thunk/LeaveRequests/getLeaveRequestByDateThunk";

export interface AppState {
    LeaveRequestsByDate: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    LeaveRequestsByDate: [],
    isLoading: false,
    error: null,
};

const LeaveRequestByDateSlicer = createSlice({
    name: "leaveRequestByDate",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaveRequestByDate.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchLeaveRequestByDate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.LeaveRequestsByDate = action.payload;
            })
            .addCase(fetchLeaveRequestByDate.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});
export default LeaveRequestByDateSlicer;
