import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderEncounters } from "../../Thunk/ProviderEncouters/getProviderEncounters";
export interface AppState {
    providerEncouterData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    providerEncouterData: null,
    isLoading: false,
    error: null,
};

const providerEncouter = createSlice({
    name: "providerEncouter",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {

        builder
            .addCase(fetchProviderEncounters.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProviderEncounters.fulfilled, (state, action) => {
                state.isLoading = false;
                state.providerEncouterData = action.payload;
            })
            .addCase(fetchProviderEncounters.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default providerEncouter;
