import React from "react";

function HeaderTitleHandler({ themeColor }: any) {
  return (
    <>
      {themeColor === "blue" ? <h1>Virtual Care</h1> : null}
      {themeColor === "parrot" ? (
        <h1>
          Access Care Oklahoma <span>Connect</span>
        </h1>
      ) : null}
    </>
  );
}

export default HeaderTitleHandler;
