const Signup = {
  create_your_account: "Create your account",
  check_me_out: "Check me out",
  or_log_in_with_email: "Or log in with email",
  verify_your_account: "Verify your account",
  did_not_received_the_code: "Didn't received the code?",
  resend: "Resend",
  confirmation_code: "Enter Confirmation Code",
  select_account_identifier: "Select Account Identifier",
  enter_account_identifier: "Enter Account Identifier",
  did_not_received_the_identifier: "Didn't received the Identifier?",
  account_registered_successfully: "Account Registered Successfully",
  create_your_administrator_account: "Create your Administrator account",
  i_have_read_the: "I have read the",
};
export default Signup;
