import React from "react";
import images from "../../../Assets/Images";
// assets
import "./Loader.css";

const Loader = () => {
  return (
    <div className="o-page-loader">
      <div className="o-page-loader--content">
        {/* <div className="o-page-loader--spinner" /> */}
        <img src={images.LoaddingLoader} alt="loading..." />
        <div className="o-page-loader--message">
          <span>Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
