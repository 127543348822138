import { createSlice } from "@reduxjs/toolkit";
import { fetchEntryPageProviders } from "../../Thunk/WaitingRoomData/getWaitingRoomThunk";

export interface AppState {
    EntryPageProviders: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    EntryPageProviders: null,
    isLoading: false,
    error: null,
};

const EntryPageProviderSlice = createSlice({
    name: "waitingRoom",
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEntryPageProviders.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchEntryPageProviders.fulfilled, (state, action) => {
                state.isLoading = false;
                state.EntryPageProviders = action.payload;
            })
            .addCase(fetchEntryPageProviders.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    },
});

export default EntryPageProviderSlice;
