import { createSlice } from "@reduxjs/toolkit";
import { fetchUserMessageData } from "../../Thunk/getUserMessages/userMessagesThunk";
export interface AppState {
  UserMessages: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  UserMessages: null,
  isLoading: false,
  error: null,
};

const UserMessageSlice = createSlice({
  name: "userMessage",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserMessageData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserMessageData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.UserMessages = action.payload;
      })
      .addCase(fetchUserMessageData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectUserMessage = (state: any) => state.userMessage.UserMessages;
export default UserMessageSlice;
