import React, { useEffect, useState } from "react";

//css
import '../PatientLoginFlow/WaitingScreen.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import images from "../../../Assets/Images";
import {
    ChatOption,
    CloseModelIcon,
    EyeClose,
    EyeOpen,
    LandingLogo,
    LoginPopupBG,
    MicPermission,
    Padlock,
    PhoneOption,
    VideoOption,
} from "../../../Assets/Svgs";
import { useNavigate } from "react-router-dom";
import PatientDemoFormFlow from "./PatientDemoFormFlow";
import { setPlan } from "../../../Store/Slicers/commonSlicer";
import { useDispatch } from "react-redux";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { passwordValidation } from "../../../Components/utils";


const PatientDemoLoginScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState<boolean>(true);
    const [isLoginClick, setIsLoginClick] = useState<boolean>(false);
    const [isSignIn, setIsSignIn] = useState<boolean>(false);
    const [isContinueClick, setIsContinueClick] = useState<boolean>(false);
    const [isSaveClick, setIsSaveClick] = useState<boolean>(false);
    const [forgotModal, setForgotModal] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [regiterEmail, setRegiterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [isSendVerification, setIsSendVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e: any) => {
        setRegisterPassword(e.target.value);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setRegisterPasswordConfirm(e.target.value);
    };


    const handleVerification = async (e: any) => {
        // toast.loading("Please wait...")
        e.preventDefault()
        setIsSignIn(false)
        setIsSendVerification(false)
        setIsContinueClick(true);
        // try {
        //     await Auth.confirmSignUp(regiterEmail, verificationCode);
        //     await Auth.signIn(regiterEmail, registerPassword, {
        //         type: 'patient'
        //     });

        //     const userData = await Auth.currentAuthenticatedUser();
        //     if (userData) {
        //         setLocalStorage("SignUpUserName", userName);
        //         setLocalStorage("userData", JSON.stringify(userData.signInUserSession.accessToken.payload));
        //         setLocalStorage("userEmail", userData.attributes.email);
        //         setLocalStorage("userToken", userData.signInUserSession.idToken.jwtToken);
        //         setEmail('')
        //         setPassword('')
        //         setRegiterEmail('')
        //         setRegisterPassword('')
        //         setUserName('')
        //         setVerificationCode('')
        //     }
        //     toast.dismiss()

        // } catch (error: any) {
        //     toast.dismiss()
        //     ToastMessage("error","Something went wrong")
        //     console.log("🚀 ~ handleVerification ~ error:", error)
        // }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        // toast.loading("Please Wait")
        dispatch(setPlan("clinic"));
        navigate("/patient-demo");
        // setIsLoginClick(true);
        // try {
        //     await Auth.signIn(email, password, {
        //         profile: 'patient'
        //     }).then(async (res: any) => {
        //         toast.dismiss()
        //         let authToken = res?.signInUserSession?.idToken?.jwtToken;
        //         let userId = res.signInUserSession?.accessToken?.payload?.sub;
        //         if (authToken) {
        //             setLocalStorage("userToken", authToken);
        //             setLocalStorage('userData', JSON.stringify(res.signInUserSession.accessToken.payload))
        //             setLocalStorage('userEmail', res.attributes.email)
        //             await dispatch(fetchPatientProfileData({ userId, authToken }));
        //             // navigate("/home");
        //         }
        //     })
        // } catch (error: any) {
        //     toast.dismiss()
        //     console.error(error);
        // }

    };

    const handleFormSubmit = async (e: any) => {
        // toast.loading("Please wait...")
        e.preventDefault();
        setIsSendVerification(true)
        // try {
        //     await Auth.signUp({
        //         username: regiterEmail,
        //         password: registerPassword,
        //         attributes: {
        //             name: userName,
        //             email: regiterEmail,
        //             profile: 'patient'
        //         },
        //     })
        //     toast.dismiss();
        // } catch (error: any) {
        //     console.error("Error signing up:", error);
        //     toast.dismiss();
        //     ToastMessage("error","Something went wrong")
        // }
    };

    const handleForgot = () => {
        setForgotModal(true);
    };

    const handleResetPassword = () => {
        navigate('/forgot-password', {
            state: "demo-center"
        })
    }

    const closeModal = () => {
        setForgotModal(false);
    };

    const handleSaveClick = () => {
        setIsSaveClick(true);
        setIsContinueClick(false);
        setIsSignIn(false);
    };

    const allowPermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setIsSaveClick(false);
            setIsLoginClick(true);
            console.log("Camera and microphone access granted");
        } catch (error: any) {
            console.error("Error accessing camera or microphone", error);
            if (
                error.name === "NotAllowedError" ||
                error.name === "PermissionDeniedError"
            ) {
                console.log("Permission to access camera or microphone is blocked");
            } else {
                console.log("An error occurred: ", error.message);
            }
        }
    };

    const handleMeetingOption = (option: string) => {
        dispatch(setPlan('clinic'))
        navigate("/patient-demo");
    };

    const handlePassword = () => {
        navigate('/forgot-password', {
            state: "demo-center"
        })
    }

    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered ${isContinueClick
                        ? "modal-xl"
                        : "modal-lg"
                        }`}
                >
                    <div className="modal-content bg-transparent p-0 shadow-none border-0 overflow-visible">
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup ">
                                {isSignIn ? (
                                    <div className="waiting-login align-items-center">
                                        <div className="d-flex align-items-start">
                                            <img src={Padlock} alt="" className={`${isSendVerification && "d-none"}`} />
                                            <div>
                                                <h1 className="waiting-header d-flex justify-content-center align-items-center">
                                                    {isSendVerification ? "Please enter verification code" : "Choose username and password!"}
                                                </h1>
                                                <p className={`desc ${isSendVerification && 'd-none'}`}>Let’s setup a user name and password to keep your information and Telehealth session safe</p>
                                            </div>
                                        </div>
                                        {/* <p className='desc'>Let’s setup a user name and password to keep your information and Telehealth session safe</p> */}
                                        <form
                                            className="register-form d-flex flex-column w-75"
                                            onSubmit={isSendVerification ? handleVerification : handleFormSubmit}
                                        >
                                            {isSendVerification ?
                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control py-3 px-1"
                                                        placeholder="Enter verification code"
                                                        value={verificationCode}
                                                        onChange={(e) => setVerificationCode(e.target.value)}
                                                    />
                                                </> :
                                                <>
                                                    <input
                                                        type="email"
                                                        className="form-control py-3 px-1"
                                                        placeholder="Email"
                                                        value={regiterEmail}
                                                        onChange={(e) => setRegiterEmail(e.target.value)}
                                                        autoComplete="new-email"
                                                    />
                                                    {/* <input
                                                        type="text"
                                                        className="form-control py-3 px-1"
                                                        placeholder="User Name"
                                                        value={userName}
                                                        onChange={(e) => setUserName(e.target.value)}
                                                        autoComplete="new-email"
                                                    /> */}
                                                    <div>
                                                        <div className="position-relative">
                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                className="form-control py-3 px-1"
                                                                placeholder="Password"
                                                                value={registerPassword}
                                                                onChange={handlePasswordChange}
                                                                autoComplete="new-password"
                                                            />
                                                            <img
                                                                src={showPassword ? EyeOpen : EyeClose}
                                                                className="position-absolute cursor-pointer"
                                                                style={{ right: "15px", bottom: "20px" }}
                                                                onClick={handleTogglePasswordVisibility}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="position-relative">
                                                            <input
                                                                type={showConfirmPassword ? "text" : "password"}
                                                                className="form-control py-3 px-1"
                                                                placeholder="Re-enter Password"
                                                                value={registerPasswordConfirm}
                                                                onChange={handleConfirmPasswordChange}
                                                            />
                                                            <img
                                                                src={showConfirmPassword ? EyeOpen : EyeClose}
                                                                className="position-absolute cursor-pointer"
                                                                style={{ right: "15px", top: "22px" }}
                                                                onClick={handleToggleConfirmPasswordVisibility}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="remember d-flex align-items-center">
                                                        <input type="checkbox" />
                                                        <span>Remember me on this computer</span>
                                                    </div>
                                                </>}
                                            <button
                                                type="submit"
                                                className="yes-theme-btn border-0"
                                                style={{ padding: "16px 24px", borderRadius: "6px" }}
                                            >
                                                Continue
                                            </button>
                                            {(isSignIn && !isSendVerification) && <p onClick={() => setIsSignIn(false)} className="login-text">If already have an account <span>Log In</span></p>}
                                        </form>
                                        <div
                                            className={`text-center login-logo ${(isSaveClick || isLoginClick) && "d-none"
                                                }`}
                                        >
                                            <img src={LandingLogo} alt="" />
                                        </div>
                                    </div>
                                ) : isContinueClick ? (
                                    <div className="waiting-login align-items-center">
                                        <PatientDemoFormFlow handleSaveClick={handleSaveClick} />
                                        <div
                                            className={`text-center login-logo ${(isSaveClick || isLoginClick) && "d-none"
                                                }`}
                                        >
                                            <img src={LandingLogo} alt="" />
                                        </div>
                                        <h1 className="waiting-header mb-4">Let’s complete your profile</h1>
                                    </div>
                                ) : isSaveClick ? (
                                    <div className="waiting-login">
                                        <div className="permission-option w-100 d-flex flex-column">
                                            <div className="d-flex justify-content-between align-items-center chat-header">
                                                <h1>Give permission to CentiBlick Connect to</h1>
                                                <img
                                                    src={CloseModelIcon}
                                                    alt="Close"
                                                    className="close-model-icon"
                                                    style={{ width: "20px" }}
                                                    onClick={() => { setIsSaveClick(false); setIsLoginClick(true); }}
                                                />
                                            </div>
                                            <div className="permission-msg">
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{ marginBottom: "14px" }}
                                                >
                                                    <img src={MicPermission} alt="" />
                                                    <p>Use your microphone</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon
                                                        icon={faVideoSlash}
                                                        style={{ width: "24px" }}
                                                    />
                                                    <p>Use your camera</p>
                                                </div>
                                            </div>
                                            <div className="permission-btn d-flex gap-2">
                                                <button
                                                    className="save-theme-btn w-100"
                                                    style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                    onClick={() => { setIsSaveClick(false); setIsLoginClick(true); navigate("/patient-demo") }}
                                                >
                                                    Block
                                                </button>
                                                <button
                                                    className="yes-theme-btn border-0 w-100"
                                                    style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                    onClick={() => { setIsSaveClick(false); setIsLoginClick(true); navigate("/patient-demo") }}
                                                >
                                                    Allow
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    forgotModal ?
                                        <div className="waiting-login">
                                            <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Let’s setup a new password! <img src={Padlock} alt="" />
                                            </h1>
                                            {/* <p className="desc">Let’s setup a new password</p> */}
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <form
                                                        className="login-form d-flex flex-column"
                                                        onSubmit={handlePassword}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                        >
                                                            Continue
                                                        </button>
                                                    </form>
                                                </div>
                                                <div
                                                    className={`text-center login-logo`}
                                                >
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <div className="waiting-login">
                                            <h1 className="waiting-header">Welcome!
                                            </h1>
                                            <p className="desc">
                                                Please sign in or register to let Dr. Kelly Warren know you are here
                                            </p>
                                            <div className="signin-div d-flex w-100 align-items-center">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <h1 className="signin-header">Sign In</h1>
                                                    <form
                                                        className="login-form d-flex flex-column"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Email Address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            autoComplete="new-password"
                                                        />
                                                        <div className="remember d-flex align-items-center">
                                                            <input type="checkbox" />
                                                            <span>Remember me on this computer</span>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                        >
                                                            Sign In
                                                        </button>
                                                        <p onClick={handleForgot} className="forgotpassword-title">Forgot Password</p>
                                                    </form>
                                                </div>
                                                <div
                                                    className={`vertical-line `}
                                                ></div>
                                                <div
                                                    className={`social-login w-100 d-flex flex-column `}
                                                >
                                                    <div className="register d-flex flex-column">
                                                        <button
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                            onClick={() => { setIsSignIn(true); localStorage.clear() }}
                                                        >
                                                            Register
                                                        </button>
                                                        <h1 className="signin-header text-center">
                                                            Don’t have a Profile?
                                                        </h1>
                                                    </div>
                                                    <div className="social-account">
                                                        <div className="google d-flex align-items-center mb-1">
                                                            <img src={images.Google} alt="Google" />
                                                            Continue with Google
                                                        </div>
                                                        <div className="apple d-flex align-items-center">
                                                            <FontAwesomeIcon icon={faApple} color="#ffffff" />
                                                            Continue with Apple
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`text-center login-logo ${(isSaveClick || isLoginClick) && "d-none"
                                                    }`}
                                            >
                                                <img src={LandingLogo} alt="" />
                                            </div>
                                        </div>
                                )
                                    //  : (
                                    //     <div className="meeting-option">
                                    //         <div>
                                    //             <div className="d-flex justify-content-between align-items-center chat-header">
                                    //                 <h1>How do you want to meet Dr. Kelly Warren?</h1>
                                    //                 <img
                                    //                     src={CloseModelIcon}
                                    //                     alt="Close"
                                    //                     className="close-model-icon"
                                    //                     style={{ width: "20px" }}
                                    //                     onClick={() => setIsLoginClick(false)}
                                    //                 />
                                    //             </div>
                                    //             <div className="chat-options">
                                    //                 <div onClick={() => handleMeetingOption("video")}>
                                    //                     <img src={VideoOption} alt="" />
                                    //                     <p>Video</p>
                                    //                 </div>
                                    //                 <div onClick={() => handleMeetingOption("chat")}>
                                    //                     <img src={ChatOption} alt="" />
                                    //                     <p>Chat</p>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // )
                                }
                            </div>
                            {/* {forgotModal && (
                                <ForgotPassword
                                    isRessetPasswords={forgotModal}
                                    closeModal={closeModal}
                                    authToken={authToken}
                                />
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientDemoLoginScreen