import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { CloseModelIcon } from '../../../../../../../Assets/Svgs';
interface ModalProps {
  showModal: boolean;
  closeModal: () => void;
  handleConfirm:() => void;
}

const WarningModel = ({ showModal, closeModal,handleConfirm }: ModalProps) => {
  return (
    <>
      <div className="modal-backdrop" onClick={closeModal} />
      <div className={`modal fade ${showModal ? "show" : ""} `} style={{ display: showModal ? "block" : "none" }} >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
          <div className="warning-model modal-body p-0">
            <div className="d-flex modal-content gap-5">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between p-0">
                  <h3 className="modal-title">Warning</h3>
                  <span className="close-icon-invite" data-bs-dismiss="modal" style={{ fontSize: "24px" }}>
                    {/* <FontAwesomeIcon icon={faClose} onClick={closeModal} color='#555555' /> */}
                    <img src={CloseModelIcon} alt ="Close" className='close-model-icon'style={{width:"20px"}}  onClick={closeModal}/>
                  </span>
                </div>
                <div>
                  <hr className='m-0' />
                </div>
              </div>
              <div className='d-flex flex-column '>
                <div className='d-flex flex-column gap-2'>
                  <h1 className="d-flex warning-message justify-content-center ">
                    Are you sure you want to revert all changes?
                  </h1>
                  <p className='warning-second-message text-center'>
                    This will revert color back to the original state, before you began editing.
                  </p>
                </div>
              </div>
              <div className='d-flex w-100 justify-content-between'>
                <button className="border-none onboarding-back" onClick={closeModal}>Cancel</button>
                <button className="border-none onboarding-checkout" onClick={handleConfirm}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WarningModel