import React from "react";
import { useLocation } from "react-router-dom";

//css
import '../Header/CommonHeader.scss'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../Store";

import { lightenColor } from "../../../../../Components/utils";

// utils components

// svgs

interface NavbarProps {
  ComponentTitle?: "Standard Platform" | "Customize"
}

const Navbar = ({ ComponentTitle }: NavbarProps) => {

  const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)
  const location = useLocation();

  const currentMainPathLocation = location.pathname.slice(1).split("/")[0];

  const ProfileLogo = `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2187_42661)">
  <rect x="6.07031" y="6.05469" width="29.208" height="29.208" rx="14.604" fill="white"/>
  <rect x="6.07031" y="6.05469" width="29.208" height="29.208" rx="14.604" fill="url(#paint0_radial_2187_42661)" fill-opacity="0.7"/>
  <rect x="6.07031" y="6.05469" width="29.208" height="29.208" rx="14.604" fill="url(#paint1_radial_2187_42661)"/>
  <rect x="5.37488" y="5.35926" width="30.5989" height="30.5989" rx="15.2994" stroke="white" stroke-width="1.39086"/>
  <g clip-path="url(#clip0_2187_42661)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6753 14.5312C17.2903 14.5312 14.5469 17.2747 14.5469 20.6597C14.5469 24.0447 17.2903 26.7882 20.6753 26.7882C24.0603 26.7882 26.8038 24.0447 26.8038 20.6597C26.8038 17.2747 24.0603 14.5312 20.6753 14.5312ZM20.6753 16.096C21.4016 16.096 22.0981 16.3845 22.6116 16.898C23.1251 17.4115 23.4136 18.108 23.4136 18.8342C23.4136 19.5604 23.1251 20.2569 22.6116 20.7705C22.0981 21.284 21.4016 21.5725 20.6753 21.5725C19.9491 21.5725 19.2526 21.284 18.7391 20.7705C18.2256 20.2569 17.9371 19.5604 17.9371 18.8342C17.9371 18.108 18.2256 17.4115 18.7391 16.898C19.2526 16.3845 19.9491 16.096 20.6753 16.096ZM20.6753 25.745C19.806 25.7455 18.9511 25.5229 18.1924 25.0987C17.4337 24.6744 16.7964 24.0626 16.3416 23.3218C17.7013 22.6982 19.1795 22.3753 20.6753 22.3753C22.1712 22.3753 23.6494 22.6982 25.0091 23.3218C24.5543 24.0626 23.917 24.6744 23.1583 25.0987C22.3995 25.5229 21.5446 25.7455 20.6753 25.745Z" fill='${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}'/>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_2187_42661" x="0.507115" y="0.49149" width="40.3373" height="40.3373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="2.08629"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_42661"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_42661" result="shape"/>
  </filter>
  <radialGradient id="paint0_radial_2187_42661" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.778 12.1397) rotate(51.0441) scale(24.5185 24.5185)">
  <stop stop-color="white" stop-opacity="0.59"/>
  <stop offset="0.6875" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2187_42661" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2772 31.6117) rotate(-93.6705) scale(24.9998 27.8633)">
  <stop stop-color="#516CD3" stop-opacity="0.34"/>
  <stop offset="0.0001" stop-color="#516CD3" stop-opacity="0.4"/>
  <stop offset="0.861815" stop-color="#516CD3" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2187_42661">
  <rect width="12.5177" height="12.5177" fill="white" transform="translate(14.4141 14.3984)"/>
  </clipPath>
  </defs>
  </svg>`

  const CareHistory = `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2187_42679)">
  <rect x="6.07031" y="6.15625" width="29.208" height="29.208" rx="14.604" fill="white"/>
  <rect x="6.07031" y="6.15625" width="29.208" height="29.208" rx="14.604" fill="url(#paint0_radial_2187_42679)" fill-opacity="0.7"/>
  <rect x="6.07031" y="6.15625" width="29.208" height="29.208" rx="14.604" fill="url(#paint1_radial_2187_42679)"/>
  <rect x="5.37488" y="5.46082" width="30.5989" height="30.5989" rx="15.2994" stroke="white" stroke-width="1.39086"/>
  <g clip-path="url(#clip0_2187_42679)">
  <path d="M20.8067 23.876H20.5459C20.0426 23.876 19.6332 23.4666 19.6332 22.9633V21.7898H18.4596C17.9563 21.7898 17.5469 21.3803 17.5469 20.877V20.6162C17.5469 20.1129 17.9563 19.7035 18.4596 19.7035H19.6332V18.5299C19.6332 18.0266 20.0426 17.6172 20.5459 17.6172H20.8067C21.31 17.6172 21.7194 18.0266 21.7194 18.5299V19.7035H22.893C23.3963 19.7035 23.8057 20.1129 23.8057 20.6162V20.877C23.8057 21.3803 23.3963 21.7898 22.893 21.7898H21.7194V22.9633C21.7194 23.4666 21.31 23.876 20.8067 23.876Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  <path d="M26.5562 20.7594C26.453 20.7539 26.3518 20.7889 26.274 20.857C26.1963 20.9252 26.1482 21.0209 26.14 21.124C25.9491 23.99 23.5473 26.2354 20.6729 26.2354C17.653 26.2354 15.1964 23.7788 15.1964 20.7589C15.1964 17.739 17.653 15.2824 20.6729 15.2824C22.0139 15.2824 23.2714 15.7674 24.2525 16.6259L23.9171 16.9618C23.8625 17.0165 23.8253 17.0862 23.8102 17.162C23.7951 17.2379 23.8029 17.3165 23.8325 17.3879C23.862 17.4593 23.9121 17.5204 23.9764 17.5634C24.0406 17.6064 24.1162 17.6294 24.1935 17.6294H25.4975C25.6012 17.6294 25.7007 17.5882 25.7741 17.5149C25.8474 17.4415 25.8886 17.342 25.8886 17.2383V15.9343C25.8886 15.5885 25.4688 15.4107 25.221 15.6579L24.8059 16.0731C23.6692 15.0573 22.1974 14.4971 20.6729 14.5C17.2217 14.5 14.4141 17.3076 14.4141 20.7589C14.4141 24.2101 17.2217 27.0177 20.6729 27.0177C23.9583 27.0177 26.7028 24.4516 26.9208 21.1761C26.9277 21.0725 26.8932 20.9704 26.8248 20.8923C26.7564 20.8142 26.6598 20.7664 26.5562 20.7594Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_2187_42679" x="0.507115" y="0.593052" width="40.3373" height="40.3373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="2.08629"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_42679"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_42679" result="shape"/>
  </filter>
  <radialGradient id="paint0_radial_2187_42679" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.778 12.2413) rotate(51.0441) scale(24.5185 24.5185)">
  <stop stop-color="white" stop-opacity="0.59"/>
  <stop offset="0.6875" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2187_42679" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2772 31.7133) rotate(-93.6705) scale(24.9998 27.8633)">
  <stop stop-color="#516CD3" stop-opacity="0.34"/>
  <stop offset="0.0001" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0.4"/>
  <stop offset="0.861815" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2187_42679">
  <rect width="12.5177" height="12.5177" fill="white" transform="translate(14.4141 14.5)"/>
  </clipPath>
  </defs>
  </svg>
  `
  const careTeam = `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_2187_42668)">
<rect x="6.07031" y="5.60156" width="29.208" height="29.208" rx="14.604" fill="white"/>
<rect x="6.07031" y="5.60156" width="29.208" height="29.208" rx="14.604" fill="url(#paint0_radial_2187_42668)" fill-opacity="0.7"/>
<rect x="6.07031" y="5.60156" width="29.208" height="29.208" rx="14.604" fill="url(#paint1_radial_2187_42668)"/>
<rect x="5.37488" y="4.90613" width="30.5989" height="30.5989" rx="15.2994" stroke="white" stroke-width="1.39086"/>
<path d="M19.26 14.6406H19.4414C19.9083 14.6599 20.356 14.8877 20.6725 15.2273C20.989 14.8877 21.4328 14.6599 21.9037 14.6406H22.0812C22.2703 14.6561 22.4594 14.6831 22.6408 14.741C23.2313 14.9224 23.7369 15.3585 24.0109 15.9105C24.2502 16.3775 24.3158 16.9295 24.2077 17.4428C24.1305 17.8211 23.9337 18.1646 23.679 18.4502C23.405 18.759 23.0769 19.0099 22.745 19.2531C22.3475 19.5425 21.9423 19.8243 21.5486 20.1215C21.2476 20.3492 20.9581 20.5963 20.6725 20.8433C20.4757 20.6735 20.2827 20.5036 20.0782 20.3415C19.6884 20.0327 19.2754 19.751 18.8702 19.4615C18.573 19.2531 18.2835 19.0369 18.0134 18.7976C17.7548 18.5622 17.5117 18.2997 17.3457 17.9909C17.145 17.6281 17.064 17.2035 17.091 16.7905C17.1257 16.2116 17.392 15.6557 17.8165 15.2621C18.2063 14.8915 18.7274 14.6715 19.26 14.6406ZM20.3483 16.6168C20.3483 16.833 20.3483 17.053 20.3483 17.2691C20.1322 17.2691 19.9122 17.2691 19.6961 17.2691C19.6961 17.4853 19.6961 17.7053 19.6961 17.9214C19.9122 17.9214 20.1322 17.9214 20.3483 17.9214C20.3483 18.1376 20.3483 18.3576 20.3483 18.5737C20.5644 18.5737 20.7844 18.5737 21.0006 18.5737C21.0006 18.3576 21.0006 18.1376 21.0006 17.9214C21.2167 17.9214 21.4367 17.9214 21.6528 17.9214C21.6528 17.7053 21.6528 17.4853 21.6528 17.2691C21.4367 17.2691 21.2167 17.2691 21.0006 17.2691C21.0006 17.053 21.0006 16.833 21.0006 16.6168C20.7806 16.6168 20.5644 16.6168 20.3483 16.6168Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
<path d="M15.3139 17.7476C15.3602 17.6626 15.4567 17.5854 15.5571 17.6163C15.696 17.6626 15.7732 17.7977 15.8349 17.9212C15.9932 18.2609 16.0704 18.6314 16.136 18.9981C16.2325 19.5423 16.2904 20.0943 16.3367 20.6462C16.3521 20.9164 16.4486 21.1789 16.5682 21.4221C16.7419 21.7694 16.985 22.0782 17.2938 22.3098C17.7569 22.6456 18.2201 22.9814 18.6832 23.3172C18.8144 23.1435 18.9456 22.9698 19.073 22.7961C18.6523 22.4912 18.2316 22.1863 17.811 21.8775C17.5369 21.6922 17.2784 21.4529 17.1587 21.1364C17.1163 21.0168 17.097 20.8701 17.178 20.7659C17.2591 20.6655 17.3941 20.6385 17.5176 20.6347C17.7878 20.6308 18.0502 20.7234 18.2972 20.8277C18.7218 21.0129 19.1193 21.2561 19.5014 21.5108C19.9375 21.781 20.2385 22.2519 20.3196 22.7575C20.3505 22.9428 20.3427 23.1319 20.3427 23.3211C20.3427 24.1316 20.3427 24.9422 20.3427 25.7566H18.3899C18.3822 25.4054 18.1969 25.085 17.9846 24.811C17.6103 24.3208 17.1356 23.9193 16.6956 23.4948C16.3482 23.1628 15.997 22.827 15.6999 22.4488C15.476 22.1554 15.2715 21.8235 15.2174 21.4491C15.148 20.7775 15.1171 20.0982 15.1094 19.4188V19.2683C15.1171 18.9171 15.121 18.562 15.175 18.2107C15.202 18.0525 15.2367 17.8904 15.3139 17.7476Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
<path d="M25.6197 17.7469C25.6776 17.6775 25.7625 17.6118 25.859 17.6273C25.9555 17.6466 26.0134 17.7392 26.0558 17.8203C26.133 17.9901 26.16 18.1792 26.1832 18.3645C26.2179 18.627 26.2179 18.8971 26.2334 19.1596V20.0782L26.2141 19.9586C26.2218 20.2211 26.2025 20.4835 26.187 20.7498C26.1677 21.0084 26.1523 21.2709 26.1137 21.5257C26.0365 21.9309 25.7934 22.2822 25.5348 22.5948C25.0331 23.1892 24.431 23.691 23.8791 24.2352C23.636 24.4784 23.4044 24.737 23.2114 25.0265C23.0686 25.2503 22.9567 25.5012 22.9529 25.7676H21C21 24.8605 21 23.9573 21 23.0503C21 22.6141 21.1621 22.178 21.4554 21.8537C21.6098 21.6762 21.8028 21.5411 21.9957 21.4137C22.3045 21.213 22.6248 21.0316 22.9606 20.8811C23.2076 20.773 23.4662 20.6726 23.7402 20.6495C23.8753 20.6418 24.0258 20.6533 24.1338 20.746C24.2265 20.827 24.238 20.9583 24.211 21.0702C24.1609 21.2709 24.0374 21.4446 23.8946 21.5951C23.7556 21.7418 23.5858 21.8537 23.4237 21.9695C23.0416 22.2474 22.6557 22.5253 22.2736 22.8071C22.4048 22.9808 22.5322 23.1545 22.6634 23.3282C23.1227 22.9962 23.5819 22.6604 24.0412 22.3285C24.2882 22.1509 24.4928 21.9155 24.6549 21.6607C24.8478 21.3442 24.9945 20.993 25.0138 20.6186C25.0408 20.3098 25.0678 20.001 25.1064 19.6961C25.1682 19.2175 25.2415 18.7389 25.3804 18.2757C25.4306 18.0866 25.4962 17.8975 25.6197 17.7469Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
</g>
<defs>
<filter id="filter0_d_2187_42668" x="0.507115" y="0.0383649" width="40.3373" height="40.3373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2.08629"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_42668"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_42668" result="shape"/>
</filter>
<radialGradient id="paint0_radial_2187_42668" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.778 11.6866) rotate(51.0441) scale(24.5185 24.5185)">
<stop stop-color="white" stop-opacity="0.59"/>
<stop offset="0.6875" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint1_radial_2187_42668" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2772 31.1586) rotate(-93.6705) scale(24.9998 27.8633)">
<stop stop-color="#516CD3" stop-opacity="0.34"/>
<stop offset="0.0001" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0.4"/>
<stop offset="0.861815" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0"/>
</radialGradient>
</defs>
</svg>
`
  const Medication = `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2187_42688)">
  <rect x="6.07031" y="5.70312" width="29.208" height="29.208" rx="14.604" fill="white"/>
  <rect x="6.07031" y="5.70312" width="29.208" height="29.208" rx="14.604" fill="url(#paint0_radial_2187_42688)" fill-opacity="0.7"/>
  <rect x="6.07031" y="5.70312" width="29.208" height="29.208" rx="14.604" fill="url(#paint1_radial_2187_42688)"/>
  <rect x="5.37488" y="5.0077" width="30.5989" height="30.5989" rx="15.2994" stroke="white" stroke-width="1.39086"/>
  <g clip-path="url(#clip0_2187_42688)">
  <path d="M24.9808 19.7969C24.7239 19.7961 24.4693 19.8464 24.232 19.9448C23.9946 20.0432 23.7792 20.1878 23.5981 20.3701L20.7295 23.2387C20.5472 23.4198 20.4027 23.6353 20.3043 23.8726C20.2059 24.11 20.1556 24.3645 20.1563 24.6214C20.1569 25.1399 20.3632 25.637 20.7299 26.0037C21.0965 26.3703 21.5936 26.5766 22.1122 26.5773C22.3692 26.578 22.6238 26.5277 22.8612 26.4293C23.0986 26.3309 23.3142 26.1864 23.4954 26.0041L26.3635 23.136C26.5457 22.9548 26.6902 22.7392 26.7886 22.5018C26.887 22.2644 26.9373 22.0098 26.9367 21.7528C26.936 21.2343 26.7297 20.7372 26.3631 20.3705C25.9964 20.0039 25.4993 19.7976 24.9808 19.7969ZM22.7573 25.2666C22.5852 25.4392 22.3562 25.5342 22.1122 25.5342C21.6088 25.5342 21.1994 25.1247 21.1994 24.6214C21.1994 24.3778 21.2943 24.1484 21.467 23.9762L22.5325 22.9107L23.8234 24.2016L22.7573 25.2666ZM25.626 22.398L24.5604 23.4635L23.27 22.1732L24.3361 21.1071C24.5077 20.935 24.7367 20.84 24.9808 20.84C25.4841 20.84 25.8935 21.2495 25.8935 21.7528C25.8935 21.9964 25.7986 22.2258 25.626 22.398Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  <path d="M21.7161 16.1332V15.4812C21.7161 14.6884 21.0745 14.0469 20.2817 14.0469H17.4131C16.6203 14.0469 15.9788 14.6884 15.9788 15.4812V16.1332C15.1182 16.1332 14.4141 16.8373 14.4141 17.6979V23.9567C14.4141 24.8173 15.1182 25.5215 15.9788 25.5215H19.249C19.1551 25.2346 19.1082 24.9269 19.1082 24.6087C19.1082 24.567 19.1082 24.52 19.1134 24.4783H15.9788C15.6919 24.4783 15.4572 24.2436 15.4572 23.9567V22.9136H18.1955C18.9882 22.9136 19.6298 22.2721 19.6298 21.4793V20.1753C19.6298 19.3826 18.9882 18.741 18.1955 18.741H15.4572V17.6979C15.4572 17.411 15.6919 17.1763 15.9788 17.1763H21.7161C22.0029 17.1763 22.2376 17.411 22.2376 17.6979V20.2327L22.8531 19.6173C22.9835 19.4869 23.1295 19.3669 23.2808 19.2626V17.6979C23.2808 16.8373 22.5767 16.1332 21.7161 16.1332Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_2187_42688" x="0.507115" y="0.139927" width="40.3373" height="40.3373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="2.08629"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_42688"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_42688" result="shape"/>
  </filter>
  <radialGradient id="paint0_radial_2187_42688" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.778 11.7881) rotate(51.0441) scale(24.5185 24.5185)">
  <stop stop-color="white" stop-opacity="0.59"/>
  <stop offset="0.6875" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2187_42688" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2772 31.2601) rotate(-93.6705) scale(24.9998 27.8633)">
  <stop stop-color="#516CD3" stop-opacity="0.34"/>
  <stop offset="0.0001" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0.4"/>
  <stop offset="0.861815" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2187_42688">
  <rect width="12.5177" height="12.5177" fill="white" transform="translate(14.4141 14.0469)"/>
  </clipPath>
  </defs>
  </svg>
  `
  const Procedures = `<svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2187_42697)">
  <rect x="6.07031" y="6.25" width="29.208" height="29.208" rx="14.604" fill="white"/>
  <rect x="6.07031" y="6.25" width="29.208" height="29.208" rx="14.604" fill="url(#paint0_radial_2187_42697)" fill-opacity="0.7"/>
  <rect x="6.07031" y="6.25" width="29.208" height="29.208" rx="14.604" fill="url(#paint1_radial_2187_42697)"/>
  <rect x="5.37488" y="5.55457" width="30.5989" height="30.5989" rx="15.2994" stroke="white" stroke-width="1.39086"/>
  <g clip-path="url(#clip0_2187_42697)">
  <path d="M26.9306 25.0778C26.9306 22.1442 26.9306 19.2105 26.9306 16.2769C26.9306 15.8098 26.7214 15.3476 26.376 15.0314C26.0695 14.7444 25.6511 14.5789 25.2327 14.5789C23.3548 14.5789 21.4818 14.5789 19.6039 14.5789C19.132 14.5644 18.6552 14.7444 18.3292 15.0801C18.013 15.3914 17.8281 15.8341 17.8281 16.2769C17.8281 16.6418 17.8281 17.0115 17.8281 17.3764C18.2076 17.3764 18.5871 17.3764 18.9665 17.3764C18.9665 17.0115 18.9665 16.6418 18.9665 16.2769C18.9665 16.0531 19.1125 15.839 19.3217 15.7612C19.4433 15.7077 19.5795 15.7222 19.7109 15.7222C21.4964 15.7222 23.2818 15.7222 25.0722 15.7222C25.184 15.7222 25.3057 15.7125 25.4127 15.7563C25.5927 15.8195 25.7338 15.9752 25.7776 16.1601C25.797 16.2428 25.7922 16.3304 25.7922 16.4131C25.7922 19.41 25.7922 22.4069 25.7922 25.3989C25.797 25.7006 25.5246 25.9584 25.223 25.9633C25.223 26.3428 25.223 26.7222 25.223 27.0969C25.5441 27.092 25.8652 27.0044 26.1327 26.8341C26.5317 26.5812 26.8187 26.1628 26.9014 25.6957C26.9403 25.4914 26.9257 25.2822 26.9306 25.0778Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  <path d="M19.711 18.2764V17.3764H25.0723V18.2764H19.711Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  <path d="M24.1025 19.5891C23.796 19.2972 23.3776 19.1317 22.9592 19.1317C21.8938 19.1317 20.8283 19.1317 19.7629 19.1317C19.4369 18.8058 19.1158 18.4798 18.7899 18.1539C18.6731 18.0371 18.5077 17.9836 18.3472 17.9933C17.6709 17.9933 16.9947 17.9933 16.3184 17.9933C16.1773 17.9933 16.0314 17.9884 15.8903 18.0079C15.506 18.0566 15.146 18.2414 14.8784 18.5187C14.5816 18.8301 14.4065 19.2582 14.4065 19.6912C14.4065 21.5838 14.4065 23.4763 14.4065 25.3688C14.4016 25.6607 14.4746 25.9575 14.6205 26.2153C14.9124 26.7456 15.4962 27.1008 16.1044 27.0959C18.3082 27.0959 20.5121 27.0959 22.7208 27.0959C22.8911 27.0959 23.0614 27.1008 23.2316 27.0716C23.6598 27.0035 24.0538 26.7554 24.3117 26.4051C24.5306 26.118 24.6474 25.758 24.6474 25.398C24.6474 23.8801 24.6474 22.3622 24.6474 20.8394C24.6474 20.3675 24.443 19.9053 24.1025 19.5891ZM20.0937 23.6806C20.0937 24.0601 20.0937 24.4396 20.0937 24.819C19.7142 24.819 19.3348 24.819 18.9553 24.819C18.9553 24.4396 18.9553 24.0601 18.9553 23.6806C18.5758 23.6806 18.1963 23.6806 17.8169 23.6806C17.8169 23.3011 17.8169 22.9217 17.8169 22.5422C18.1963 22.5422 18.5758 22.5422 18.9553 22.5422C18.9553 22.1627 18.9553 21.7832 18.9553 21.4037C19.3348 21.4037 19.7142 21.4037 20.0937 21.4037C20.0937 21.7832 20.0937 22.1627 20.0937 22.5422C20.4732 22.5422 20.8526 22.5422 21.2321 22.5422C21.2321 22.9217 21.2321 23.3011 21.2321 23.6806C20.8526 23.6806 20.4732 23.6806 20.0937 23.6806Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  </g>
  </g>
  <defs>
  <filter id="filter0_d_2187_42697" x="0.507115" y="0.686802" width="40.3373" height="40.3373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="2.08629"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_42697"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_42697" result="shape"/>
  </filter>
  <radialGradient id="paint0_radial_2187_42697" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.778 12.335) rotate(51.0441) scale(24.5185 24.5185)">
  <stop stop-color="white" stop-opacity="0.59"/>
  <stop offset="0.6875" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2187_42697" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2772 31.807) rotate(-93.6705) scale(24.9998 27.8633)">
  <stop stop-color="#516CD3" stop-opacity="0.34"/>
  <stop offset="0.0001" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0.4"/>
  <stop offset="0.861815" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0"/>
  </radialGradient>
  <clipPath id="clip0_2187_42697">
  <rect width="12.5177" height="12.5179" fill="white" transform="translate(14.4141 14.5938)"/>
  </clipPath>
  </defs>
  </svg>
  `
  const HealthTesting = `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_2187_42707)">
  <rect x="6.07031" y="5.79688" width="29.208" height="29.208" rx="14.604" fill="white"/>
  <rect x="6.07031" y="5.79688" width="29.208" height="29.208" rx="14.604" fill="url(#paint0_radial_2187_42707)" fill-opacity="0.7"/>
  <rect x="6.07031" y="5.79688" width="29.208" height="29.208" rx="14.604" fill="url(#paint1_radial_2187_42707)"/>
  <rect x="5.37488" y="5.10145" width="30.5989" height="30.5989" rx="15.2994" stroke="white" stroke-width="1.39086"/>
  <path d="M20.6769 25.9628C7.12301 17.9234 18.2257 11.3972 20.6769 16.8357C23.1281 11.3972 34.2308 17.9234 20.6769 25.9628Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  <path d="M19.5885 20.5155L20.0417 19.1378C20.0656 19.059 20.161 19 20.2803 19C20.3995 19 20.4711 19.059 20.5188 19.1378L21.4491 22.2082L22.3317 19.492C22.3555 19.4133 22.451 19.3543 22.5702 19.3543C22.6656 19.3543 22.7611 19.4133 22.8088 19.492L23.4767 21.539L23.6675 20.673C23.6913 20.5942 23.7868 20.5155 23.906 20.5155H26.2437V20.9091H24.0969L23.7152 22.3262C23.6913 22.405 23.5959 22.4837 23.5005 22.4837H23.4767C23.3812 22.4837 23.2858 22.4247 23.262 22.3459L22.5941 20.299L21.6638 23.0348C21.6399 23.1135 21.5445 23.1726 21.4252 23.1726C21.306 23.1726 21.2106 23.1135 21.1867 23.0348L20.2564 19.9644L19.994 20.7714C19.9702 20.8501 19.8747 20.9091 19.7555 20.9091H17.2031V20.5155H19.5885Z" fill="white"/>
  </g>
  <defs>
  <filter id="filter0_d_2187_42707" x="0.507115" y="0.233677" width="40.3373" height="40.3373" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="2.08629"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2187_42707"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2187_42707" result="shape"/>
  </filter>
  <radialGradient id="paint0_radial_2187_42707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.778 11.8819) rotate(51.0441) scale(24.5185 24.5185)">
  <stop stop-color="white" stop-opacity="0.59"/>
  <stop offset="0.6875" stop-color="white" stop-opacity="0"/>
  <stop offset="1" stop-color="white" stop-opacity="0"/>
  </radialGradient>
  <radialGradient id="paint1_radial_2187_42707" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(31.2772 31.3539) rotate(-93.6705) scale(24.9998 27.8633)">
  <stop stop-color="#516CD3" stop-opacity="0.34"/>
  <stop offset="0.0001" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0.4"/>
  <stop offset="0.861815" stop-color="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}" stop-opacity="0"/>
  </radialGradient>
  </defs>
  </svg>
  `
  const SideBarNavigationMenuOptionsList = [
    {
      name: "Profile",
      iconImgName: ProfileLogo,
    },
    {
      name: "Care Teams",
      iconImgName: careTeam,
    },
    {
      name: "Care History",
      iconImgName: CareHistory,
    },
    {
      name: "Medications",
      iconImgName: Medication,
    },
    {
      name: "Procedures",
      iconImgName: Procedures,
    },
    {
      name: "Health Testing",
      iconImgName: HealthTesting,
    },
  ];

  return (
    <div className="side-navbar p-1">
      <h2 className="heading pb-1">
        my Medical Records</h2>
      <ul className="navbar-nav justify-content-end flex-grow-1">
        {SideBarNavigationMenuOptionsList &&
          SideBarNavigationMenuOptionsList.map(
            (dataDict: any, dataDictIndex: any) => {
              return (
                <li
                  className="nav-item dropdown"
                  key={`dropdown_item_navbar_${dataDictIndex}`}
                  style={{
                    background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8),
                    borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor
                  }}
                >
                  <div
                    style={{ borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor }}
                    className=" nav-link"
                  >
                    <div className="d-flex medical-record-icon align-items-center gap-1">
                      <div dangerouslySetInnerHTML={{ __html: dataDict.iconImgName }} />
                      <span className="commonFont-10">{dataDict.name}</span>
                    </div>
                    <FontAwesomeIcon icon={faChevronDown} size="xs" color="#000000" className="patient-dropdown-icon me-2" />
                  </div>
                </li>
              );
            }
          )}
      </ul>
    </div>
  );
};

export default Navbar;
