import React from 'react'
import images from '../../../../Assets/Images'

//formik
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';


//css
import './AddOnFeatures.scss'
import { formatPhoneNumber } from '../../../utils';
import CBSelect from '../../CBSelect/CBSelect';
import { option } from '../../../../Pages/OnBoarding/components/Helpers/OptionArray';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const platformFeatures = [
    "HIPAA Compliant Payments",
    "2-way EHR Integration ",
    "Flexcare Provider Scheduling",
    "my Appointments for Patients",
    "Customized Digital Forms",
    "360 Medical Records",
    "Auto Transcribe & AI Charting",
    "Next-gen Claims & Billing ",
    "Remote Patient Monitoring",
    "ePrescribe",
]


const AddOnForm = () => {

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        webUrl: '',
        category: '',
        select: '',
        providers: ''
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required('first name is required'),
        lastName: Yup.string().required('last name is required'),
        email: Yup.string().email('email is not valid').required('email is required'),
        phoneNumber: Yup.string().required('phone number is required'),
        companyName: Yup.string().required('company name is required'),
        webUrl: Yup.string(),
        category: Yup.string(),
        select: Yup.string(),
        providers: Yup.string()
    })

    const handleSubmit = (values: any) => {
        console.log("🚀 ~ handleSubmit ~ values:", values)
    }

    return (
        <div className='addOn-form-div'>
            <div className='add-on-features position-relative text-center'>
                <img src={images.PricingBg} alt="" className='first-bg' />
                <img src={images.PricingBg} alt="" className='second-bg' />
                <img src={images.PricingBg} alt="" className='third-bg' />
                <img src={images.PricingBg} alt="" className='forth-bg' />
                <img src={images.PricingBg} alt="" className='fifth-bg' />
                <img src={images.PricingBg} alt="" className='sixth-bg' />
                <div className='w-100'>
                    <div className='container add-on-needs z-2'>
                        <h1>
                            Optimize your <br />
                            Platform Today!
                        </h1>
                        <p>
                            Discover how CentiBlick’s tailored Add-On Features can improve care, engage patients, and streamline your operations
                        </p>
                        <div className='row platform-feature-details mt-5'>
                            {
                                platformFeatures?.map((data) => (
                                    <div className='platforn-details col-md-6 col-sm-12'>
                                        <h1 className='feature-title d-flex align-items-center gap-1'>
                                            <FontAwesomeIcon icon={faCircleCheck} color='#516cd3' className='tick-icon' />
                                            {data}
                                        </h1>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='w-100 position-relative'>
                    <div className='position-absolute static-bg top-0'></div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            (formik) => (
                                <Form className='addOn-form position-relative text-start'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>First Name*</label>
                                            <div className='position-relative'>
                                                <Field
                                                    type='text'
                                                    name='firstName'
                                                    placeholder='Enter first name'
                                                    className={`${formik.values.firstName && !formik.errors.firstName && 'valid-input'}`}
                                                />
                                                {formik.values.firstName && !formik.errors.firstName && (
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        color='#00CC00'
                                                        className='position-absolute tick-icon'
                                                    />
                                                )}
                                            </div>
                                            <ErrorMessage name='firstName' component='div' className='error-message text-danger text-start' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Last Name*</label>
                                            <div className='position-relative'>
                                                <Field
                                                    type='text'
                                                    name='lastName'
                                                    placeholder='Enter last name'
                                                    className={`${formik.values.lastName && !formik.errors.lastName && 'valid-input'}`}
                                                />
                                                {formik.values.lastName && !formik.errors.lastName && (
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        color='#00CC00'
                                                        className='position-absolute tick-icon'
                                                    />
                                                )}
                                            </div>
                                            <ErrorMessage name='lastName' component='div' className='error-message text-danger text-start' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Email*</label>
                                            <div className='position-relative'>
                                                <Field
                                                    type='text'
                                                    name='email'
                                                    placeholder='Enter email'
                                                    className={`${formik.values.email && !formik.errors.email && 'valid-input'}`}
                                                />
                                                {formik.values.email && !formik.errors.email && (
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        color='#00CC00'
                                                        className='position-absolute tick-icon'
                                                    />
                                                )}
                                            </div>
                                            <ErrorMessage name='email' component='div' className='error-message text-danger text-start' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Phone Number*</label>
                                            <div className='position-relative'>
                                                <Field
                                                    type='text'
                                                    name='phoneNumber'
                                                    placeholder='Enter phone number'
                                                    className={`${formik.values.phoneNumber && !formik.errors.phoneNumber && 'valid-input'}`}
                                                    onChange={(e: any) => {
                                                        const formattedValue = formatPhoneNumber(e.target.value);
                                                        formik.setFieldValue('phoneNumber', formattedValue);
                                                    }}
                                                />
                                                {formik.values.phoneNumber && !formik.errors.phoneNumber && (
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        color='#00CC00'
                                                        className='position-absolute tick-icon'
                                                    />
                                                )}
                                            </div>
                                            <ErrorMessage name='phoneNumber' component='div' className='error-message text-danger text-start' />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>Company Name*</label>
                                            <div className='position-relative'>
                                                <Field
                                                    type='text'
                                                    name='companyName'
                                                    placeholder='Enter company name'
                                                    className={`${formik.values.companyName && !formik.errors.companyName && 'valid-input'}`}
                                                />
                                                {formik.values.companyName && !formik.errors.companyName && (
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        color='#00CC00'
                                                        className='position-absolute tick-icon'
                                                    />
                                                )}
                                            </div>
                                            <ErrorMessage name='companyName' component='div' className='error-message text-danger text-start' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label>Website Url</label>
                                            <Field
                                                type='text'
                                                name='webUrl'
                                                placeholder='Enter website url'
                                            />
                                            <ErrorMessage name='webUrl' component='div' className='error-message text-danger text-start' />
                                        </div>
                                    </div>
                                    <div>
                                        <label>Specialty category</label>
                                        <CBSelect options={option} className='onbarding-dropdown w-100' placeholder='Select Role' onChange={(option: string) => {
                                            formik.setFieldValue('category', option);
                                        }} />
                                        <ErrorMessage name='category' component='div' className='error-message text-danger' />
                                    </div>
                                    <div>
                                        <label>Please select one</label>
                                        <CBSelect options={option} className='onbarding-dropdown w-100' placeholder='Select Role' onChange={(option: string) => {
                                            formik.setFieldValue('select', option);
                                        }} />
                                        <ErrorMessage name='select' component='div' className='error-message text-danger' />
                                    </div>
                                    <div>
                                        <label>How many providers do you anticipate using CentiBlick?</label>
                                        <CBSelect options={option} className='onbarding-dropdown w-100' placeholder='Select Role' onChange={(option: string) => {
                                            formik.setFieldValue('providers', option);
                                        }} />
                                        <ErrorMessage name='providers' component='div' className='error-message text-danger' />
                                    </div>
                                    <button className='yes-theme-btn w-100' type='submit'>Submit</button>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddOnForm