import { createSlice } from "@reduxjs/toolkit";
import { fetchUserServices } from "../../Thunk/userServices/getUserServicesThunk";
import { fetchProviderService } from "../../Thunk/ProviderService/getProviderServiceThunk";

export interface AppState {
  serviceData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  serviceData: null,
  isLoading: false,
  error: null,
};

const UserServicesSlice = createSlice({
  name: "userServices",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserServices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserServices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceData = action.payload;
      })
      .addCase(fetchUserServices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchProviderService.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProviderService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceData = action.payload;
      })
      .addCase(fetchProviderService.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default UserServicesSlice;
