import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderSchedulingRule } from "../../Thunk/SchedulingRulesProvider/getProviderSchedulingRules";

export interface AppState {
    SchedulingRuleData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  SchedulingRuleData: null,
  isLoading: false,
  error: null,
};

const GetSchedulingRule = createSlice({
  name: "SchedulingRule",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviderSchedulingRule.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProviderSchedulingRule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.SchedulingRuleData = action.payload;
      })
      .addCase(fetchProviderSchedulingRule.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default GetSchedulingRule;
