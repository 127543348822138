import React, { useState } from 'react'
import { LandingLogo } from '../../../../Assets/Svgs'
import { useLocation, useNavigate } from 'react-router-dom'

//css
import './StickyHeader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

const StickyHeader = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [isSignIn, setIsSignIn] = useState<boolean>(false)

    return (
        <>
            <div className='d-flex align-items-center justify-content-between sticky-header'>
                <div className='d-flex justify-content-between align-items-center w-50 gap-4'>
                    <img src={LandingLogo} alt="" className='cursor-pointer' onClick={() => navigate('/')} />
                    <h1 onClick={() => navigate('/pricing')} className={`${(location.pathname === '/pricing' || location.pathname === '/request-demo') && 'active-component'}`}>Plans & Pricing</h1>
                    <h1 onClick={() => navigate('/add-on-features')} className={`d-flex gap-4 align-items-center ${location.pathname === '/add-on-features' && 'active-component'}`}>Add-On Features <FontAwesomeIcon icon={faAngleDown} /></h1>
                </div>
                <div className='d-flex align-items-center justify-content-end right-navbar w-100'>
                    <div className='d-flex gap-3'>
                        <button className='save-theme-btn shadow-none' onClick={() => navigate('/contact-sales')}>Contact Sales</button>
                        <button className='save-theme-btn shadow-none' onClick={() => setIsSignIn(true)}>Sign In</button>
                    </div>
                </div>
            </div>
            {isSignIn &&
                <>
                    <div className="modal-backdrop" />
                    <div
                        className={`modal fade ${isSignIn ? "show" : ""} signIn-modal`}
                        style={{ display: isSignIn ? "block" : "none" }}
                    >
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
                            <div className="modal-body p-0">
                                <div className="modal-content new-signin-modal">
                                    <div className='sign-in-modal'>
                                        <h1>I am a</h1>
                                        <div className='select-div'>
                                            <button className='yes-theme-btn' onClick={() => navigate('/provider/login')}>Provider</button>
                                            <h1>or</h1>
                                            <button className='yes-theme-btn' onClick={() => navigate('/admin/login')}>Admin</button>
                                        </div>
                                        <img src={LandingLogo} alt="" width={125} height={70} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default StickyHeader