import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { get } from "../../../Network/ApiService";

interface fetchProviderEncountersArgs {
    provider_id: string
    authToken: string;
}

interface fetchProviderEncounterMessage {
    message: any
}

export const fetchProviderEncounters = createAsyncThunk<fetchProviderEncounterMessage, fetchProviderEncountersArgs, { state: RootState }>(
    "meeting/fetchtProviderEncounters",
    async ({ provider_id, authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.meeting.get_meeting_encounters}?provider_id=${provider_id}`;

            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error;
        }
    }
)