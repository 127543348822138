import { createSlice } from "@reduxjs/toolkit";
import { fetchAllPatientProfileData } from "../../Thunk/getProfileData/allPatientProfileThunk";
export interface AppState {
    AllPatientUserData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    AllPatientUserData: null,
    isLoading: false,
    error: null,
};

const AllPatientProfileSlice = createSlice({
    name: "allPatientprofile",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllPatientProfileData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllPatientProfileData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AllPatientUserData = action.payload;
            })
            .addCase(fetchAllPatientProfileData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export const { resetState } = AllPatientProfileSlice.actions

export default AllPatientProfileSlice;
