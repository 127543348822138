import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { get } from "../../../Network/ApiService";


interface FetchServiceDataArgs {
    from_date_time: string;
    to_date_time: string;
    provider_id: string;
    authToken: string;
}

interface ServiceData {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}

export const fetchProviderService = createAsyncThunk<ServiceData, FetchServiceDataArgs, { state: RootState }>(
    'profile/fetchProviderService',
    async ({ from_date_time, to_date_time, provider_id, authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.userShift.get_provider_Service}?from_date_time=${from_date_time}&to_date_time=${to_date_time}&provider_id=${provider_id}`;

            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
)