
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

interface fetchUpcomingAppointmentDataArgs {
    userId: string, startDate: string, endDate: string, type: string, authToken: string
}

// Define the return type for the Thunk action
interface AppointmentData {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchUpcomingAppointmentData = createAsyncThunk<AppointmentData, fetchUpcomingAppointmentDataArgs, { state: RootState }>(
    'appointment/fetchAppointmentData',
    async ({ userId, startDate, endDate, type, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint;
            ApiEndPoint = `${ApiUrl.appointments.get_patient_upcoming_appointments}?patient_id=${userId}&from_date_time=${startDate}&to_date_time=${endDate}`;
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
