import React, { useEffect, useRef, useState } from 'react'

// redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../Store';
import { setTextColor, setheaderTextvalue } from '../../../../../../../Store/Slicers/OnBoarding/OnBoardingSlicer';

// library
import SketchPicker from "react-color";

const FirstContent = () => {

    const { textColor, headerText } = useSelector((state: RootState) => state.onBoarding.serviceValues);
    const dispatch = useDispatch();
    const [isTextPickerOpen, setisTextPickerOpen] = useState(false);

    const colorPickerRef = useRef<HTMLDivElement>(null);

    const handleChange = (e: any) => {
        dispatch(setheaderTextvalue(e.target.value))
    }

    const handlePickerOpen = () => {
        setisTextPickerOpen(true);
    };
    const handleColorChange = (color: any, e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)
        const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`
        dispatch(setTextColor(hexCode));
        setisTextPickerOpen(true);
    };
    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setisTextPickerOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [colorPickerRef]);

    return (
        <div className='p-2 d-flex flex-column first-content'>
            <h2 className='heading-text pt-3 pb-3'>What would you like to name your telehealth service?</h2>
            <div>
                <p className="heading-text">Examples:</p>
                <ul className='pt-3 listing'>
                    <li className='font-para'>
                        Canyon Park Virtual Care
                    </li>
                    <li className='font-para'>
                        Mission Clinic Hybrid Health
                    </li>
                    <li className='font-para'>
                        Maple Primary Telehealth
                    </li>
                </ul>
            </div>
            <div className="heading-text d-flex flex-column gap-3 pt-3">
                <label htmlFor="serviceName">Service Name</label>
                <input type="text" id="headerText" name="headerText" placeholder={headerText} value={headerText} onChange={handleChange} onClick={() => dispatch(setheaderTextvalue(""))} />
            </div>
            <div className="d-flex justify-content-between pt-3 align-items-center">
                <p className="heading-text align-middle">Text Color</p>
                <div className='color-box position-relative' style={{ backgroundColor: textColor }} ref={colorPickerRef} onClick={handlePickerOpen}>
                    {isTextPickerOpen &&
                        <div className="color-picker-container">
                            <SketchPicker
                                className='first-content-picker'
                                color={textColor}
                                onChange={handleColorChange}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FirstContent