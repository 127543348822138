import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderContactHistory } from "../../Thunk/GetContactHistory/providerContactHistoryThunk";
export interface AppState {
  ProviderContactHistoryData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  ProviderContactHistoryData: null,
  isLoading: false,
  error: null,
};

const ProviderContactHistorySlice = createSlice({
  name: "adminprofile",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState, 
  },
  extraReducers: (builder) => {

    builder
      .addCase(fetchProviderContactHistory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProviderContactHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderContactHistoryData = action.payload;
      })
      .addCase(fetchProviderContactHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default ProviderContactHistorySlice;
