import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
  patient_demographics_data: any[];
  allergies_intolerance_data: any[];
  vaccination_data: any[];
  smoking_status_data: any[];
}

const initialState: AppState = {
  patient_demographics_data: [],
  allergies_intolerance_data: [],
  vaccination_data: [],
  smoking_status_data: [],
};

export const userProfileSection = createSlice({
  name: "userProfileSection",
  initialState,
  reducers: {
    patientDemographicData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.patient_demographics_data = action.payload;
    },
    allergiesdAndIntolerance: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.allergies_intolerance_data = action.payload;
    },
    vaccinations: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.vaccination_data = action.payload;
    },
    smokingStatus: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.smoking_status_data = action.payload;
    },
    resetProfileState: () => initialState,
  },
});

export const {
  patientDemographicData,
  allergiesdAndIntolerance,
  vaccinations,
  smokingStatus,
  resetProfileState
} = userProfileSection.actions;
