import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderProfileData } from "../../Thunk/getProfileData/providerProfileThunk";
import { getProvidersByValidSchedule } from "../../Thunk/getProfileData/getProvidersByValidSchedule";
import { fetchAllProviderProfileData } from "../../Thunk/getProfileData/allProviderProfileThunk";
export interface AppState {
  ProviderUserData: any;
  ProviderProfileModal: boolean;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  ProviderUserData: [],
  ProviderProfileModal:false,
  isLoading: false,
  error: null,
};

const ProviderProfileSlice = createSlice({
  name: "providerprofile",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviderProfileData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProviderProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderUserData = action.payload;
        if (action.payload && !state.ProviderUserData[0]?.first_name && !state.ProviderUserData[0]?.last_name && !state.ProviderUserData[0]?.phone) {
          state.ProviderProfileModal = true;
        } else{
          state.ProviderProfileModal = false;
        }
      })
      .addCase(fetchProviderProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getProvidersByValidSchedule.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProvidersByValidSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderUserData = action.payload;
      })
      .addCase(getProvidersByValidSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllProviderProfileData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllProviderProfileData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ProviderUserData = action.payload;
      })
      .addCase(fetchAllProviderProfileData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
});


export default ProviderProfileSlice;
