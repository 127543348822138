import { createSlice } from "@reduxjs/toolkit";
import { fetchScheduleAppointmentData } from "../../Thunk/ScheduleAppointment/getScheduleAppointmentsThunk";
export interface AppState {
  AppointmentData: any;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: AppState = {
  AppointmentData: null,
  isLoading: false,
  error: null,
};

const GetAppointmentsSlice = createSlice({
  name: "getAppointments",
  initialState,
  reducers: {
    // Your other reducers go here
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScheduleAppointmentData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchScheduleAppointmentData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.AppointmentData = action.payload;
      })
      .addCase(fetchScheduleAppointmentData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default GetAppointmentsSlice;
