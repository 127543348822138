import React, { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const FetchAuthData = async () => {
  try {
    const session = await Auth.currentSession();
    const userToken = session.getAccessToken().getJwtToken();
    const userData = JSON.stringify(session.getIdToken().decodePayload());
    // setLocalStorage("userToken", userToken);
    // setLocalStorage("userData", userData);
    return { status: true, data: { token: userToken, data: userData } };
  } catch (error) {
    localStorage.clear();
    return { status: false, error_message: error };
  }
}

export default function PrivateRoutes({ component: Component, ...rest }: any) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const promise = FetchAuthData();
    Promise.resolve(promise).then((value) => {
      const status = value?.data?.token;
      if (status) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
  }, []);

  return !isAuthenticated ? <Navigate to="/login" /> : <Outlet />;
}
