import { createSlice } from "@reduxjs/toolkit";
import { fetchEventDataByProviderId } from "../../Thunk/UserShift/getEventDataByProviderId";

export interface AppState {
    schedulingData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    schedulingData: null,
    isLoading: false,
    error: null,
};

const EventDataByProviderIdSlice = createSlice({
    name: "userShift",
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventDataByProviderId.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchEventDataByProviderId.fulfilled, (state, action) => {
                state.isLoading = false;
                state.schedulingData = action.payload;
            })
            .addCase(fetchEventDataByProviderId.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
})

export default EventDataByProviderIdSlice;