const Common = {
  already_have_an_account: "Already have an account?",
  signup: "sign up",
  login: "log in",
  enter_name: "Enter Name",
  enter_email: "Enter Email",
  enter_password: "Enter Password",
  get_started: "Get Started",
  verify: "Verify",
  contact_support: "Contact Support",
  next: "Next",
  continue: "Continue",
  already_have_an_admin_account :"Already have a Admin account? ",
  admin_signup:"Admin Sign up",
  welcome_back:"Welcome Back",
  privacy_policy:"Privacy Policy"
};
export default Common;
