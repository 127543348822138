import { createSlice } from "@reduxjs/toolkit";
import { fetchScheduliingAppoinments } from "../../Thunk/Scheduling/getSchedulingAppointmentThunk";

export interface AppState {
    schedulingData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    schedulingData: null,
    isLoading: false,
    error: null,
};

const SchedullingAppointmentSlice = createSlice({
    name: "userScheduling",
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScheduliingAppoinments.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchScheduliingAppoinments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.schedulingData = action.payload;
            })
            .addCase(fetchScheduliingAppoinments.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default SchedullingAppointmentSlice;
