import React, { useEffect, useState } from 'react'

//css
import './OnBoarding.scss'
import Step2 from './components/Steps/Step2'
import Step3 from './components/Steps/Step3'
import Step6 from './components/Steps/Step6'
import Step5 from './components/Steps/Step5'
import Step4 from './components/Steps/Step4/Step4'
import Step1 from './components/Steps/Step1'
import images from '../../Assets/Images'
import { useNavigate } from 'react-router-dom'
import { setIsFinishedLogin } from '../../Store/Slicers/OnBoarding/OnBoardingSlicer'
import { useDispatch } from 'react-redux'

const OnBoarding = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const renderRoundFlows = () => {
        const roundFlows = [];

        for (let i = 1; i <= 5; i++) {
            const isCurrentOrBefore = i <= currentStep;
            let roundFlowStyle = {};
            roundFlowStyle = isCurrentOrBefore ? { background: '#516cd3', borderColor: '#516cd3' } : {};

            roundFlows.push(<div key={i} className="round-flow" style={roundFlowStyle}></div>);
            if (i < 5) {
                const upLineStyle = isCurrentOrBefore ? { borderColor: '#516cd3' } : {};
                roundFlows.push(<div key={`line-${i}`} className="up-line" style={upLineStyle}></div>);
            }
        }

        return roundFlows;
    };

    const handleStepChange = (step: any) => {
        setCurrentStep(step);
    };

    const handleBackButtonClick = () => {
        if (currentStep > 0) {
            handleStepChange(currentStep - 1);
        }
        // if (currentStep === 7) {
        //     setCompleted(false)
        // }
    };

    const handleCheckoutButtonClick = () => {
        if (currentStep < 7) {
            handleStepChange(currentStep + 1);
        } else {
            handleStepChange(1)
        }
        dispatch(setIsFinishedLogin(false))
    };
    const renderContent = () => {
        switch (currentStep) {
            case 1:
                return <Step1 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
            case 2:
                return <Step2 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
            case 3:
                return <Step3 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
            case 4:
                return <Step4 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
            case 5:
                return <Step5 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
            case 6:
                return <Step6 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
            default:
                return <Step1 handleBackButtonClick={handleBackButtonClick} handleCheckoutButtonClick={handleCheckoutButtonClick} />;
        }
    };

    return (
        <>
            <div className='onboarding-page'>
                <div className='bg-image1'></div>
                <div className='bg-image2'></div>
                <div className={`pricing-page d-flex justify-content-center `}>
                    <img src={images.Logo} alt="" className='onboard-logo' onClick={() => navigate('/')} />
                </div>
                <div className={`d-flex onboarding-display`}>
                    {
                        currentStep != 6 && <>
                            <div className='onboarding-left-flow'>
                                {renderRoundFlows()}
                            </div>
                        </>
                    }
                    {renderContent()}
                </div>
            </div>
        </>
    )
}

export default OnBoarding