import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import EN from "./en/translations";
import FR from "./fr/translations";

const local = navigator.language.substring(0, 2);
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      fr: {
        translation: FR,
      },
      "#": {
        translation: FR,
      },
    },
    fallbackLng: "en",
  });

i18n.changeLanguage(local);
export default i18n;
