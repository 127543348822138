import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HippaConsentPayload, InformationReleasePayload, MedicalHistoryPayload, PatienInformationPayload, TelehealthConsentPayload } from '../../Interfaces/FormsInterface/formInterFace';

interface PatientProfileState {
    FormDataPatientInformation: PatienInformationPayload;
    FormDataMedicalhistory: MedicalHistoryPayload;
    HIPPAConscentForm: HippaConsentPayload;
    TelhealthConscentForm:TelehealthConsentPayload;
    InformationReleseAuthorizationForm:InformationReleasePayload;
    isFirstLogin: boolean;
    IsUserLoginFirstTime:boolean
}

const initialState: PatientProfileState = {
    FormDataPatientInformation: {} as PatienInformationPayload,
    FormDataMedicalhistory: {} as MedicalHistoryPayload,
    HIPPAConscentForm: {} as HippaConsentPayload,
    TelhealthConscentForm: {} as TelehealthConsentPayload,
    InformationReleseAuthorizationForm :{} as InformationReleasePayload,
    isFirstLogin: false,
    IsUserLoginFirstTime:false
};

const patientFormProfileSlice = createSlice({
    name: 'patientFormsData',
    initialState,
    reducers: {
        setPatientInformation: (state, action: PayloadAction<PatienInformationPayload>) => {
            state.FormDataPatientInformation = action.payload;
        },
        setPatientMedicalHistory: (state, action: PayloadAction<any>) => {
            state.FormDataMedicalhistory = action.payload;
        
        },
        setHIPPAConscentFormData: (state, action: PayloadAction<any>) => {
            state.HIPPAConscentForm ={...action.payload ,signature:action.payload.imageURL};
        },
        setTelhealthConscentFormData:(state, action: PayloadAction<any>) => {
            state.TelhealthConscentForm = action.payload;
        },
        setInformationReleseAuthorizationFormData:(state, action: PayloadAction<any>) => {
            state.InformationReleseAuthorizationForm =action.payload;
        },
        setIsFirstLogin: (state, action: PayloadAction<boolean>) => {
            state.IsUserLoginFirstTime = action.payload;
        },
        resetState: () => initialState,
    },
});

export const { setPatientInformation, setPatientMedicalHistory ,setHIPPAConscentFormData,setTelhealthConscentFormData,setInformationReleseAuthorizationFormData ,setIsFirstLogin, resetState} = patientFormProfileSlice.actions;

export default patientFormProfileSlice;
