import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useRef, useState } from "react";
import images from "../../../Assets/Images";
import { FileUploader } from "react-drag-drop-files";
import {
    formatPhoneNumber,
    GenderOption,
    savePDFAsFormData,
    USAStatesCB,
} from "../../../Components/utils";
import CBSelect from "../../../Components/Common/CBSelect/CBSelect";
import CBSelectState from "../../../Components/Common/CBSelect/CBSelectState";
import SignaturePad from "react-signature-canvas";
import * as Yup from "yup";
import {
    DefaultImageGallery,
    ImageUploadSquare,
    CloseModelIcon,
    ShareIcon,
} from "../../../Assets/Svgs";
import UploadCropImageModal from "../Home/Modals/UploadCropImageModal";
import Cropper from "cropperjs";

//css
import '../PatientLoginFlow/WaitingScreen.scss'
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
// import { storePatientProfileData } from "../../../Store/Thunk/storeProfileData/storePatientProfileThunk";
// import { storeImages } from "../../../Store/Thunk/storeProfileData/storeImagesThunk";
import { getLocalStorage } from "../../../Components/Patient/Utils/AuthHandlers";
// import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store";
// import { SavePatientInformation } from "../../../Store/Thunk/myForms/PatientInformation/SavePatientInformationThunk";
import { capitalize } from "@mui/material";
import { ToastMessage } from "../../../Components/Common/ToastMessage";

interface FileState {
    file: File | null;
    fileName: string | null | undefined;
    fileBuffer: string | null;
}


const PatientFormFlow = ({ handleSaveClick }: any) => {

    const UserData = getLocalStorage('userData');

    const userId = UserData.sub

    const [profilePicture, setProfilePicture] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const [driversLicense, setDriversLicense] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const [insuranceCard, setInsuranceCard] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const [tempFile, setTempFile] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });
    const [tempFileIC, setTempFileIC] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const resultRef = useRef<HTMLDivElement>(null);
    const saveRef = useRef<HTMLButtonElement>(null);
    const croppedRef = useRef<HTMLImageElement>(null);
    const uploadRef = useRef<HTMLInputElement>(null);
    const sigTeleHealthCanvas: any = useRef(null);

    const imgResultRef = useRef<HTMLDivElement>(null);
    const [cropper, setCropper] = useState<Cropper | null>(null);
    const [isUplodOpen, setIsUploadOpen] = useState(false);
    const [isNotSelectedDL, setIsNotSelectedDL] = useState(false);
    const [isNotSelectedIC, setIsNotSelectedIC] = useState(false);
    const [selectedImage, setSelectedImage] = useState<any>(ImageUploadSquare);
    const [modalTitle, setModaltitle] = useState<string>("Driver's License");
    const [isSaveClick, setIsSaveClick] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState(null);

    const clear = () => {
        setImageURL(null);
        if (sigTeleHealthCanvas?.current?.clear) {
            sigTeleHealthCanvas?.current?.clear();
        }
    };

    const handleChange = (
        file: File,
        setFileState: React.Dispatch<React.SetStateAction<FileState>>
    ) => {
        setFileState((prevFileState) => ({
            ...prevFileState,
            file: file,
            fileName: file.name,
        }));

        let reader = new FileReader();
        reader.onloadend = function () {
            setFileState((prevFileState) => ({
                ...prevFileState,
                fileBuffer: reader.result as string,
            }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (values: any, { setSubmitting }: any) => {

        // toast.loading("Please wait...")
        handleSaveClick(values)
        // try {
        //     let result: any;
        //     if (profilePicture.fileBuffer || driversLicense.fileBuffer || insuranceCard.fileBuffer) {
        //         result = await dispatch(storeImages({ authToken, ImagePayload: ImagePayload, userId, profilepayload: ProfilePayload, type: 'patient' }));
        //     } else {
        //         result = await dispatch(storePatientProfileData({ authToken, data: ProfilePayload, userId }));
        //     }
        //     await dispatch(SavePatientInformation({ type: "TelhealthConscent", patient_id: userId, payload, authToken }))
        //     if (result.payload && result.payload.status === 200) {
        //         toast.dismiss()
        //         ToastMessage("success","Profile saved successfully");
        //     } else {
        //         console.error('Error in saving data:', result.payload.message);
        //         toast.dismiss()
        //         ToastMessage("error","Error in saving Data");
        //     }
        // } catch (error) {
        //     console.error('Error while dispatching the thunk:', error);
        //     toast.dismiss()
        //     ToastMessage("error","Error in saving Data");
        // }
        setSubmitting(false);
    };

    const initialValues: any = {
        currentAddress: "",
        city: "",
        state: "",
        zipCode: "",
        email: '',
        phoneNumber: "",
        firstName: "",
        middleName: "",
        lastName: "",
        birthDate: "",
        gender: "",
        policyNumber: "",
        groupNumber: "",
        emergencyContactName: "",
        emergencyPhoneNumber: "",
        insuranceCompanyName: "",
        clientName: "",
        relationToClient: "",
        witness: "",
        offeredCopy: "",
        marijuanaRecreationally: "",
        signature: "",
        authorized_first_name: "",
        authorized_last_name: "",
        legalRelationship: "",
        signDate: "",
    };

    const validationSchema = Yup.object().shape({
        currentAddress: Yup.string(),
        city: Yup.string(),
        state: Yup.string().test("State", "Invalid State", (value) => {
            if (!value) return true; // Allow empty values
            const validStates = USAStatesCB.map((state) => state.value);
            const validStatesLAbel = USAStatesCB.map((state) =>
                state.label?.toUpperCase()
            );
            if (value?.length === 2) {
                return validStates.includes(value);
            } else if (value?.length >= 2) {
                if (validStatesLAbel.includes(value?.toUpperCase())) {
                    return validStatesLAbel.includes(value?.toUpperCase());
                } else {
                    return false;
                }
            }
        }),
        zipCode: Yup.string(),
        email: Yup.string().email("Invalid email address"),
        phoneNumber: Yup.string(),

        firstName: Yup.string(),
        middleName: Yup.string(),
        lastName: Yup.string(),
        gender: Yup.string(),
        birthDate: Yup.date().max(moment().format("MM/DD/YYYY")),
        policyNumber: Yup.number()
            .typeError("Policy Number must be a number")
            .positive("Policy Number must be a positive number")
            .integer("Policy Number must be an integer"),
        groupNumber: Yup.number()
            .typeError("Group Number must be a number")
            .positive("Group Number must be a positive number")
            .integer("Group Number must be an integer"),
        emergencyContactName: Yup.string(),
        emergencyPhoneNumber: Yup.string().matches(
            /^\(\d{3}\) \d{3}-\d{4}$/,
            "Emergency Phone number must be in the format (XXX) XXX-XXXX"
        ),
        insuranceCompanyName: Yup.string(),
        signDate: Yup.date().min(moment().format("MM/DD/YYYY"), "Date field must be current date").max(moment().format("MM/DD/YYYY"), "Date field must be current date"),
    });

    const handleModalOpen = (index: number, image1: any) => {
        let image = image1;
        setIsUploadOpen(true);
        switch (index) {
            case 0:
                setTempFile((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: null,
                    fileBuffer: image,
                    // fileBuffer: null
                }));
                if (driversLicense?.fileName) {
                    setSelectedImage(image);
                    image = image1;
                    setIsNotSelectedDL(false);
                } else {
                    setIsNotSelectedDL(true);
                    image = ImageUploadSquare;
                    setSelectedImage(DefaultImageGallery);
                }
                setModaltitle("Driver's License");
                break;
            case 1:
                setTempFileIC((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: null,
                    fileBuffer: image,
                    // fileBuffer: null
                }));
                if (insuranceCard?.fileName) {
                    setSelectedImage(image);
                    image = image1;
                    setIsNotSelectedIC(false);
                } else {
                    image = ImageUploadSquare;
                    setSelectedImage(DefaultImageGallery);
                    setIsNotSelectedIC(true);
                }
                // if(insuranceCard?.fileName) {
                //     setSelectedImage(image);
                //     image=image1
                // }else{
                //     image=ImageUploadSquare
                //     setSelectedImage(ImageUploadSquare);
                // }
                setModaltitle("Insurance Card");
                break;
            default:
                setModaltitle("");
                break;
        }

        if (image && typeof image === "string") {
            const isPDF = image.endsWith(".pdf");
            const isExternalLink = image.startsWith("http");
            const img = new Image();
            img.src = image;
            const isInternalLink = img.src.startsWith("data:application/pdf");

            if (isPDF || isExternalLink || isInternalLink || selectedImage) {
                imgResultRef.current?.classList.add("hide");
                saveRef.current?.classList.add("hide");
            } else {
                imgResultRef.current?.classList.remove("hide");
                saveRef.current?.classList.remove("hide");
            }

            const resultElement = resultRef.current;

            if (resultElement) {
                resultElement.innerHTML = "";

                if (isPDF) {
                    const link = `https://docs.google.com/viewer?embedded=true&url=${image}`;
                    resultElement.innerHTML = `<iframe name="myFrame" id="myFrame" src="${link}" width="100%" height="200px"></iframe>`;
                } else {
                    if (isInternalLink) {
                        resultRef.current.innerHTML = `<iframe name="myFrame" id="myFrame" src="${img.src}" width="100%" height="200px"></iframe>`;
                    } else {
                        img.onload = () => {
                            resultElement.appendChild(img);
                        };

                        img.onerror = (error) => {
                            console.error("Error loading image:", error);
                        };
                    }
                }
            } else {
                console.error("resultRef.current is null");
            }

            croppedRef.current?.setAttribute("src", image);
        } else {
            if (resultRef.current) {
                resultRef.current.innerHTML = "";
            }
            setCropper(null);
        }
    };

    const HandleModalclose = () => {
        setIsUploadOpen(false);
        if (uploadRef?.current) {
            uploadRef.current.value = "";
        }
        if (modalTitle == "Driver's License") {
            if (driversLicense?.fileName) {
                setDriversLicense((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: "",
                    fileBuffer: null,
                    // fileBuffer: null
                }));
            } else if (selectedImage) {
                if (isNotSelectedDL) {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        //  fileBuffer: null
                    }));
                } else {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        //  file: null,
                        //  fileName:null,
                        fileBuffer: tempFile?.fileBuffer,
                        // fileBuffer: null
                    }));
                }
            } else if (selectedImage) {
                if (isNotSelectedDL) {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        // fileBuffer: tempFile?.fileBuffer,
                    }));
                } else {
                    setDriversLicense((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: "",
                        fileBuffer: null,
                    }));
                }
            }
        } else {
            // setInsuranceCard((prevFileState) => ({
            //     // ...prevFileState,
            //     file: null,
            //     fileName: selectedImage?null:insuranceCard?.fileName ? insuranceCard?.fileName : null,
            //     fileBuffer: null,

            // }))

            if (insuranceCard?.fileName) {
                setInsuranceCard((prevFileState) => ({
                    ...prevFileState,
                    file: null,
                    fileName: "patientUserData?.insurance_image_path",
                    fileBuffer: null,
                    // fileBuffer: null
                }));
            } else if (insuranceCard?.fileBuffer == selectedImage) {
                if (isNotSelectedIC) {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        //  fileBuffer: null
                    }));
                } else {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        //  file: null,
                        //  fileName:null,
                        fileBuffer: tempFileIC?.fileBuffer,
                        // fileBuffer: null
                    }));
                }
            } else if (selectedImage) {
                if (isNotSelectedIC) {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                        // fileBuffer: tempFile?.fileBuffer,
                    }));
                } else {
                    setInsuranceCard((prevFileState) => ({
                        ...prevFileState,
                        file: null,
                        fileName: null,
                        fileBuffer: null,
                    }));
                }
            }
        }
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];

            let reader = new FileReader();

            reader.onload = (e) => {
                const fileContent = e.target?.result as string;
                if (e.target?.result) {
                    if (file.type === "application/pdf") {
                        if (e.target && e.target instanceof HTMLInputElement) {
                            e.target.value = "";
                        }
                        if (resultRef.current) {
                            resultRef.current.innerHTML = `<iframe src="${fileContent}" width="100%" height="200px"></iframe>`;
                        }
                        if (saveRef.current) {
                            imgResultRef.current?.classList.add("hide");
                            saveRef.current.classList.add("hide");
                        }
                        if (modalTitle == "Driver's License") {
                            setDriversLicense({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        } else {
                            setInsuranceCard({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        }
                        setSelectedImage(fileContent);
                    } else {
                        imgResultRef.current?.classList.remove("hide");
                        let img = document.createElement("img");
                        img.id = "image";
                        img.src = fileContent;
                        setSelectedImage(fileContent);
                        croppedRef.current?.setAttribute("src", fileContent);
                        if (modalTitle == "Driver's License") {
                            setDriversLicense({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        } else {
                            setInsuranceCard({
                                file: file,
                                fileName: file.name,
                                fileBuffer: fileContent,
                            });
                        }
                        if (resultRef.current) {
                            resultRef.current.innerHTML = "";
                            resultRef.current.appendChild(img);
                        }

                        if (saveRef.current) {
                            saveRef.current.classList.remove("hide");
                        }
                        setCropper(new Cropper(img, { autoCropArea: 30 }));
                    }
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePreview = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        if (cropper && croppedRef.current) {
            if (
                profilePicture.file &&
                profilePicture.file.type === "application/pdf"
            ) {
                // Skip preview for PDF files
                setIsSaveClick(true);
            } else {
                // Handle preview for image files
                let imgSrc = cropper
                    .getCroppedCanvas({
                        width: 300,
                    })
                    .toDataURL();

                if (imgResultRef.current) {
                    imgResultRef.current.classList.remove("hide");
                }
                croppedRef.current.src = imgSrc;
                setIsSaveClick(true);
            }
            setSelectedImage(croppedRef.current.src);
        }
        setTimeout(() => {
            setIsSaveClick((prevIsSaveClick) => !prevIsSaveClick);
        }, 500);
    };

    const cropFun = (e?: any) => {
        e?.preventDefault();
        if (cropper && croppedRef.current) {
            if (
                profilePicture.file &&
                profilePicture.file.type === "application/pdf"
            ) {
                // Skip preview for PDF files
                setIsSaveClick(true);
            } else {
                // Handle preview for image files
                let imgSrc = cropper
                    ?.getCroppedCanvas({
                        width: 300,
                    })
                    ?.toDataURL();

                if (imgResultRef.current) {
                    imgResultRef.current.classList.remove("hide");
                }
                croppedRef.current.src = imgSrc;
                setIsSaveClick(true);
            }
            return croppedRef.current.src;
        }
        setTimeout(() => {
            setIsSaveClick((prevIsSaveClick) => !prevIsSaveClick);
        }, 500);
    };

    const hadleSave = async () => {
        const data: any = await cropFun();
        if (croppedRef.current && croppedRef.current.src) {
            if (modalTitle == "Driver's License") {
                setDriversLicense((prevFileState) => ({
                    ...prevFileState,
                    fileBuffer: data
                }))

            } else {
                setInsuranceCard((prevFileState) => ({
                    ...prevFileState,
                    fileBuffer: data

                }))
            }

        }
        setLoader(true)
        // toast.loading("Please wait...")
        setIsUploadOpen(false);
        ToastMessage("success","Save Image successfully")
        // if (response?.payload.status === 200) {
        //     // const response: any = await dispatch(storeImages({ authToken, ImagePayload: ImagePayload, userId, profilepayload: ProfilePayload, type: 'patient' }));
        // }
        if (uploadRef?.current) {
            uploadRef.current.value = ''
        }
        setLoader(false)

    }

    const selectImage = () => {
        if (uploadRef?.current) {
            uploadRef.current.value = "";
            uploadRef.current?.click();
        }
    };

    const handleRemoveImage = (title: string) => {
        if (title === "Insurance Card") {
            setInsuranceCard({
                file: null,
                fileName: null,
                fileBuffer: null,
            });
        } else {
            setDriversLicense({
                file: null,
                fileName: null,
                fileBuffer: null,
            });
        }
    };

    const fileTypes = ["JPEG", "PNG", "JPG"];

    const TelehealthItems = [
        "I acknowledge that the confidentiality protections provided by state and federal laws extend to telehealth sessions.",
        "I acknowledge that telehealth involves transmitting my personal health information electronically and may be shared with colleagues and practitioners as part of my care and services.",
        "I understand that I can choose to withhold or withdraw my consent for the use of telehealth in my care at any time, without affecting my future care or treatment.",
        "I understand that my provider may need to end my telehealth session.",
        "I acknowledge my right to review all information gathered and recorded during a telehealth interaction, and I may request copies of this information for a reasonable fee.",
        "I understand that there are various alternative healthcare methods available to me and I may seek out such methods at my discretion.",
        "I acknowledge my responsibility to inform my provider of any electronic interactions regarding my care with other healthcare providers.",
        "I understand that while telehealth may provide anticipated benefits in my care, no specific outcomes can be guaranteed.",
        "Besides my healthcare provider, I will be informed of any other individuals present during the appointment, and I reserve the right to request their exclusion from the viewing and listening area.",
        "I understand that audio, video, or photo recordings containing personal and medical information may be securely transmitted and may become part of my permanent medical record.",
        "I will ensure that only necessary individuals are present in my viewing and listening area during the virtual appointment and I will find a quiet space so that I can enable the communication between me and the provider.",
        "In case of a medical emergency, I will contact local authorities (9-1-1) for assistance.",
        "I have the right to withhold specific details of my medical history or physical examination if I choose.",
        "My healthcare provider may advise immediate treatment or determine a medical emergency, in which case, personal details may be provided to local authorities for assistance without further consent.",
        "I have the right to ask direct questions about this appointment, including inquiries about the privacy policy.",
        "I understand that this communication is privileged, and I will not record it without permission from my healthcare provider.",
        "I acknowledge that I may be responsible for co-payments, deductibles, or other charges related to telehealth services.",
        "I understand that I may download/print a copy of this signed Telehealth Consent for my records.",
    ];

    const introduction = [
        "Patient health records",
        "Live two-way audio and video",
        "Output data from health devices and sound and video files",
    ];

    const expectedBenefits = [
        "Improved access to care by enabling a patient to remain in his/her provider's office (or at a remote site) while the provider obtains test results and consults from practitioners at distant/other sites.",
        "More efficient patient evaluation and management.",
        "Obtaining expertise of a distant specialist.",
    ];

    const possibleRisks = [
        "In rare cases, information transmitted may not be sufficient (e.g., poor resolution of images) to allow for appropriate decision-making by the providers and consultant(s).",
        "Delays in evaluation and treatment could occur due to deficiencies or failures of the equipment.",
        "In very rare instances, security protocols could fail, causing a breach of privacy of personal health information.",
        "In rare cases, a lack of access to complete health records may result in interactions or allergic reactions or other judgment errors.",
    ];

    return (
        <>
            <div className="fill-form">
                <div className="col-lg-12 col-xl-12">
                    <div className="share_medical_record_card account-information-form">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {(formik: any) => (
                                <Form>
                                    <h3
                                        className="sm_card_title d-flex justify-content-between pb-3"
                                        style={{ borderBottom: "1px solid #dadada" }}
                                    >
                                        my Account Information
                                        <div className="d-flex gap-2">
                                            <div
                                                className="yes-theme-btn header-buttons d-flex justify-content-center align-items-center"
                                                style={{
                                                    width: "41px",
                                                    height: "41px",
                                                    borderRadius: "50%",
                                                    padding: 0,
                                                }}
                                            >
                                                <img src={ShareIcon} alt="" height={20} />
                                            </div>
                                            <button
                                                className="yes-theme-btn"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </h3>

                                    <div
                                        className="medical-form-box pt-4 p-2"
                                        style={{
                                            height: "calc(100vh - 460px)",
                                            overflowY: "auto",
                                            overflowX: "hidden",
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                First Name{" "}
                                                                <span className="text-danger">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.firstName &&
                                                                    formik.errors.firstName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="firstName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="firstName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>Middle Name</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.middleName &&
                                                                    formik.errors.middleName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="middleName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="middleName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Last Name <span className="text-danger">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.lastName &&
                                                                    formik.errors.lastName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="lastName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="lastName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.currentAddress &&
                                                            formik.errors.currentAddress
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="currentAddress"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="currentAddress" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.city && formik.errors.city
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="city"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="city" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <CBSelectState
                                                        options={USAStatesCB}
                                                        seletedItem={formik.values.state}
                                                        onChange={(option: string) => {
                                                            if (option?.length === 2) {
                                                                formik.setFieldValue("state", option);
                                                            } else {
                                                                const validStatesLAbel = USAStatesCB.filter(
                                                                    (state) =>
                                                                        state.label?.toUpperCase() ===
                                                                        option?.toUpperCase()
                                                                );
                                                                if (validStatesLAbel?.length > 0) {
                                                                    formik.setFieldValue(
                                                                        "state",
                                                                        validStatesLAbel[0]?.value
                                                                    );
                                                                } else {
                                                                    formik.setFieldValue("state", option);
                                                                }
                                                            }
                                                        }}
                                                        className={`${formik.touched.state && formik.errors.state
                                                            ? "error-border"
                                                            : ""
                                                            } w-100 m-0 StateDropdown-class`}
                                                        placeholder="Select"
                                                    />

                                                    <p>
                                                        <ErrorMessage name="state" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6">
                                                <div className="form-group">
                                                    <label>Zip</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.zipCode && formik.errors.zipCode
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="zipCode"
                                                    />
                                                    {/* <p><ErrorMessage name="zipCode" /></p> */}
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.zipCode
                                                            ? formik.errors.zipCode
                                                            : null}{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Date of Birth</label>
                                                    <Field
                                                        type="date"
                                                        max="9999-12-31"
                                                        className={`form-control`}
                                                        name="birthDate"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="birthDate" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Birth / Sex</label>
                                                    <CBSelect
                                                        options={GenderOption}
                                                        className="mt-0 w-100 StateDropdown-class"
                                                        onChange={(option) =>
                                                            formik.setFieldValue("gender", option)
                                                        }
                                                        seletedItem={formik.values}
                                                        placeholder={"Birth / Sex"}
                                                    />
                                                    <p>
                                                        <ErrorMessage name="gender" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>
                                                        Phone Number <span className="text-danger">*</span>
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.validateOnBlur && formik.errors.phoneNumber
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="phoneNumber"
                                                        placeholder="(000) 000-0000"
                                                        onBlur={formik.handleBlur}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-6 col-xs-12">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.email && formik.errors.email
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="email"
                                                        disabled
                                                    />
                                                    <p>
                                                        <ErrorMessage name="email" />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <h5 className="form-inner-title">
                                                            Insurance Information
                                                        </h5>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <label>Name of Insurance Company</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.insuranceCompanyName &&
                                                                    formik.errors.insuranceCompanyName
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="insuranceCompanyName"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="insuranceCompanyName" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <label>Policy Number</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.policyNumber &&
                                                                    formik.errors.policyNumber
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="policyNumber"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="policyNumber" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-6 col-xs-12">
                                                        <div className="form-group">
                                                            <label>Group Number</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.groupNumber &&
                                                                    formik.errors.groupNumber
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="groupNumber"
                                                            />
                                                            <p>
                                                                <ErrorMessage name="groupNumber" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 className="form-inner-title">Emergency Contact</h5>
                                            <div className="col-lg-4">
                                                <div className="form-group">
                                                    <label>Emergency Contact Name</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.emergencyContactName &&
                                                            formik.errors.emergencyContactName
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="emergencyContactName"
                                                    />
                                                    <p>
                                                        <ErrorMessage name="emergencyContactName" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-6">
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <Field
                                                        type="text"
                                                        className={`form-control ${formik.touched.emergencyPhoneNumber &&
                                                            formik.errors.emergencyPhoneNumber
                                                            ? "error-border"
                                                            : ""
                                                            }`}
                                                        name="emergencyPhoneNumber"
                                                        placeholder="(000) 000-0000"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5 className="form-inner-title">Upload</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label>Profile Picture</label>
                                                    <div className={`position-relative fileuploadbox `}>
                                                        <img
                                                            src={CloseModelIcon}
                                                            alt=""
                                                            className={`position-absolute cursor-pointer z-1 remove-image 
                                ${!profilePicture?.fileName ? "d-none" : ""}`}
                                                            height={"14px"}
                                                            width={"14px"}
                                                            onClick={() =>
                                                                setProfilePicture({
                                                                    file: null,
                                                                    fileName: null,
                                                                    fileBuffer: "",
                                                                })
                                                            }
                                                        />
                                                        <FileUploader
                                                            multiple={false}
                                                            handleChange={(file: any) =>
                                                                handleChange(file, setProfilePicture)
                                                            }
                                                            name="file"
                                                            types={fileTypes}
                                                            label="Upload or drop a file here"
                                                            className="Dropzone"
                                                            children={
                                                                <div className="uploader-box">
                                                                    <p className="upload-text">
                                                                        Drag file here to upload
                                                                    </p>
                                                                    <img
                                                                        src={
                                                                            profilePicture.fileBuffer
                                                                                ? profilePicture.fileBuffer
                                                                                : images.DefaultImage
                                                                        }
                                                                        alt=""
                                                                        height={30}
                                                                    />
                                                                    {/* {
                                                                        (patientUserData?.profile_image_path && profilePicture.fileName) || profilePicture.fileBuffer ?
                                                                            <img src={profilePicture.fileBuffer ? profilePicture.fileBuffer : patientUserData?.profile_image_path && process.env.REACT_APP_AWS_S3_BUCKET_PATH + "/" + patientUserData?.profile_image_path} alt="" height={30} />
                                                                            :
                                                                            <div className="para-img-div d-flex">
                                                                                <div className="para-circle-avatar">
                                                                                    <p className="para-image">{getInitials(patientUserData?.first_name + " " + patientUserData?.last_name)}</p>
                                                                                </div>
                                                                            </div>
                                                                    } */}
                                                                    {profilePicture.fileName ? (
                                                                        <p className="uploadedfiletext">{`${profilePicture?.fileName?.slice(
                                                                            0,
                                                                            40
                                                                        )}...`}</p>
                                                                    ) : (
                                                                        <button className="btn select-filebtn">
                                                                            Select file
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label>Driver's License</label>

                                                    <div
                                                        className={`position-relative fileuploadbox patient-insurance-image d-flex justify-content-center`}
                                                        onClick={() =>
                                                            handleModalOpen(
                                                                0,
                                                                ImageUploadSquare
                                                                // driversLicense?.fileBuffer ? driversLicense?.fileBuffer : (patientUserData?.liscense_image_path ? process.env.REACT_APP_DRIVERS_LISCENSE_BUCKET_PATH + "/" + patientUserData?.liscense_image_path : ImageUploadSquare)
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                driversLicense.fileBuffer
                                                                    ? driversLicense.fileBuffer
                                                                    : images.DefaultImage
                                                            }
                                                            alt=""
                                                            style={{ height: "66px" }}
                                                        />

                                                        <img
                                                            src={CloseModelIcon}
                                                            alt=""
                                                            className={`position-absolute cursor-pointer z-1 remove-image-patient ${!driversLicense?.fileName ? "d-none" : ""
                                                                }`}
                                                            height={"14px"}
                                                            width={"14px"}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveImage("Driver's License");
                                                            }}
                                                        />
                                                        {/* {
                              driversLicense?.fileName === null ? <img src={DefaultImageGallery} /> :
                                driversLicense?.fileBuffer && driversLicense?.fileBuffer?.startsWith('data:application/pdf') ?
                                  <iframe name="myFrame" id="myFrame" src={driversLicense.fileBuffer} width="100%" height="90px"></iframe>
                                  : driversLicense?.fileBuffer ?
                                    <img src={driversLicense?.fileBuffer} alt="driversLicense" className="image-uplaod-crop" style={{ width: 'auto' }} />
                                    : patientUserData?.liscense_image_path && patientUserData?.liscense_image_path.endsWith('.pdf') ?
                                      <iframe name="myFrame" id="myFrame" src={`https://docs.google.com/viewer?embedded=true&url=${process.env.REACT_APP_DRIVERS_LISCENSE_BUCKET_PATH}/${patientUserData?.liscense_image_path}`} width="100%" height="90px" style={{ border: 'none' }} ></iframe>
                                      : patientUserData?.liscense_image_path ?
                                        <img src={process.env.REACT_APP_DRIVERS_LISCENSE_BUCKET_PATH + "/" + patientUserData?.liscense_image_path} alt="Driving Licence" style={{ width: 'auto' }} />
                                        : <img src={DefaultImageGallery} alt="Default Logo" />
                            } */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label>Insurance Card</label>
                                                    <div
                                                        className={`position-relative fileuploadbox patient-insurance-image d-flex justify-content-center`}
                                                        onClick={() =>
                                                            handleModalOpen(
                                                                1,
                                                                ImageUploadSquare
                                                                // insuranceCard?.fileBuffer ? insuranceCard?.fileBuffer : (patientUserData?.insurance_image_path ? process.env.REACT_APP_INSURANCE_BUCKET_PATH + "/" + patientUserData?.insurance_image_path : ImageUploadSquare)
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                insuranceCard?.fileBuffer
                                                                    ? insuranceCard?.fileBuffer
                                                                    : images.DefaultImage
                                                            }
                                                            alt=""
                                                            style={{ height: "66px" }}
                                                        />
                                                        <img
                                                            src={CloseModelIcon}
                                                            alt=""
                                                            className={`position-absolute cursor-pointer z-1 remove-image-patient ${!insuranceCard?.fileName ? "d-none" : ""
                                                                }`}
                                                            height={"14px"}
                                                            width={"14px"}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveImage("Insurance Card");
                                                            }}
                                                        />
                                                        {/* {
                              insuranceCard?.fileName === null ?
                                <img src={DefaultImageGallery} />
                                :
                                insuranceCard && insuranceCard?.fileBuffer?.startsWith('data:application/pdf') ?
                                  <iframe name="myFrame" id="myFrame" src={insuranceCard?.fileBuffer} width="100%" height="90px"></iframe>
                                  : insuranceCard?.fileBuffer ?
                                    <img src={insuranceCard?.fileBuffer} alt="insuranceCard" className="image-uplaod-crop" height={30} style={{ width: 'auto' }} />
                                    : patientUserData?.insurance_image_path && patientUserData?.insurance_image_path.endsWith('.pdf') ?
                                      <iframe name="myFrame" id="myFrame" src={`https://docs.google.com/viewer?embedded=true&url=${process.env.REACT_APP_INSURANCE_BUCKET_PATH}/${patientUserData?.insurance_image_path}`} width="100%" height="90px" style={{ border: 'none' }}></iframe>
                                      : patientUserData?.insurance_image_path ?
                                        <img src={process.env.REACT_APP_INSURANCE_BUCKET_PATH + "/" + patientUserData?.insurance_image_path} alt="Insurance Card" style={{ width: 'auto' }} />
                                        : <img src={DefaultImageGallery} alt="Default Logo" style={{ width: '30%' }} />
                            } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p style={{ fontWeight: "600" }} className="pt-3">
                                            Telehealth Content{" "}
                                        </p>
                                        <div id="medical-form-box" className=" pt-3">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Patient Name{" "}
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control ${formik.touched.clientName &&
                                                                formik.errors.clientName
                                                                ? "error-border"
                                                                : ""
                                                                }`}
                                                            name="clientName"
                                                            placeholder={"Client Name"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Date of Birth </label>
                                                        <Field
                                                            type="date"
                                                            className={`form-control ${formik.touched.birthDate &&
                                                                formik.errors.birthDate
                                                                ? "error-border"
                                                                : ""
                                                                }`}
                                                            name="birthDate"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="heading">Introduction </p>
                                            <hr />
                                            <p className="question-header">
                                                Telehealth involves the use of electronic communications
                                                to enable providers at different locations to share
                                                individual patient information for the purpose of
                                                improving patient care. Providers may include primary
                                                care practitioners, specialists, and/or subspecialists.
                                                The information may be used for diagnosis, therapy,
                                                follow-up and/or education, and may include any of the
                                                following:
                                            </p>
                                            <ul className="question-header unorder-list">
                                                {introduction.map((item, index) => {
                                                    return (
                                                        <li key={`introduction-${index}`}>
                                                            <span></span>
                                                            {item}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                            <p className="question-header">
                                                Electronic systems used will incorporate network and
                                                software security protocols to protect the
                                                confidentiality of patient identification and imaging
                                                data and will include measures to safeguard the data and
                                                to ensure its integrity against intentional or
                                                unintentional corruption.
                                            </p>

                                            <div>
                                                <p className="answer-header">Expected Benefits:</p>
                                                <ul className="question-header unorder-list">
                                                    {expectedBenefits.map((item, index) => {
                                                        return (
                                                            <li key={`expectedBenefits-${index}`}>
                                                                <span></span>
                                                                {item}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <div>
                                                <p className="answer-header">Possible Risks:</p>
                                                <p className="question-header ">
                                                    There are potential risks associated with the use of
                                                    telehealth. These risks include, but may not be
                                                    limited to:
                                                </p>
                                                <ul className="question-header unorder-list">
                                                    {possibleRisks.map((item, index) => {
                                                        return (
                                                            <li key={`possibleRisks-${index}`}>
                                                                <span></span>
                                                                {item}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <div>
                                                <p className="answer-header">
                                                    By signing this Telehealth Consent I agree to the
                                                    following:
                                                </p>
                                                <ul className="question-header unorder-list">
                                                    {TelehealthItems.map((item, index) => {
                                                        return (
                                                            <li key={`privacy-polity-${index}`}>
                                                                <span></span>
                                                                {item}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="html2pdf__page-break"></div>
                                            <div className="page-break content-wrapper">
                                                <p className="answer-header">
                                                    Patient consent for the use of telehealth
                                                </p>
                                                <p className="question-header">
                                                    I, First Name Last Name, have read and understand the
                                                    information provided above regarding telehealth, have
                                                    discussed it with my provider or such assistants as
                                                    may be designated, and all of my questions have been
                                                    answered to my satisfaction. I hereby give my provider
                                                    and any appropriate colleagues the informed consent
                                                    for the use of telehealth in my care.
                                                </p>

                                                <p className="answer-header pt-4">
                                                    Patient/Authorized Signature:
                                                </p>
                                                <SignaturePad
                                                    ref={sigTeleHealthCanvas}
                                                    canvasProps={{
                                                        className: "signatureCanvas",
                                                    }}
                                                />
                                                <div className='signature-box-div'>
                                                    <button onClick={clear} type='button' className="rounded-save-theme-btn">Clear</button>
                                                </div>
                                                <div className="row  pt-3">
                                                    <div className="col-xl-4 col-lg-6">
                                                        <div className="form-group">
                                                            <label>Date</label>
                                                            <Field
                                                                type="date"
                                                                className={`form-control ${formik.touched.signDate &&
                                                                    formik.errors.signDate
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="signDate"
                                                                max="9999-12-31"
                                                                min={moment().format("YYYY-MM-DD")}
                                                                format={"MM-DD-YYYY"}
                                                                placeholder={"MM-DD-YYYY"}
                                                                value={formik.values.signDate}
                                                                onChange={(e: any) =>
                                                                    formik.setFieldValue(
                                                                        "signDate",
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                            <p>
                                                                <ErrorMessage name="signDate" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="question-header pt-3 pb-3">
                                                    If Patient is unable to sign this Authorization,
                                                    please complete the information below:
                                                </p>
                                                <div className="row">
                                                    <div className="col-xl-3 col-lg-6">
                                                        <div className="form-group">
                                                            <label>First Name of Authorized</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.authorized_first_name &&
                                                                    formik.errors.authorized_first_name
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="authorized_first_name"
                                                                placeholder={"First Name"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-6">
                                                        <div className="form-group">
                                                            <label>Last Name of Authorized</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.authorized_last_name &&
                                                                    formik.errors.authorized_last_name
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="authorized_last_name"
                                                                placeholder={"Last Name"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label>Legal Relationship</label>
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.legalRelationship &&
                                                                    formik.errors.legalRelationship
                                                                    ? "error-border"
                                                                    : ""
                                                                    }`}
                                                                name="legalRelationship"
                                                                placeholder={"Relation"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                {
                    <UploadCropImageModal
                        showModal={isUplodOpen}
                        modalTitle={modalTitle}
                        closeModal={HandleModalclose}
                        selectedImage={selectedImage}
                        confirm={() => console.log("hhelo")}
                        handleChangeImage={handleChangeImage}
                        uploadRef={uploadRef}
                        resultRef={resultRef}
                        imgResultRef={imgResultRef}
                        croppedRef={croppedRef}
                        saveRef={saveRef}
                        handlePreview={handlePreview}
                        cropper={cropper}
                        hadleSave={hadleSave}
                        selectImage={selectImage}
                        loader={loader}
                    // patientUserData={patientUserData}
                    />
                }
            </div>
        </>
    );
};

export default PatientFormFlow;
