import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../Network/ApiUrl";
import { RootState } from "../..";
import { get } from "../../../Network/ApiService";

interface FetchAppointmentDataArgs {
    from_date_time: string;
    service_id:string;
    authToken:string;
}

interface AppointmentData {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}
export const fetchProviderByServiceThunk = createAsyncThunk<AppointmentData, FetchAppointmentDataArgs, { state: RootState }>(
    "virtualCare/fetchProviderByServiceThunk",
    async ({ from_date_time,service_id,authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.appointments.get_provider_by_service}?from_date_time=${from_date_time}&service_id=${service_id}`;

            const response = await get(ApiEndPoint, authToken);
            if (response) {               
                return response.data;
            } else {
                throw new Error('Failed to fetch date view data of provider based on service_id');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
)