import React, { useState } from 'react'
import './ProfileFlow.scss'
import images from '../../Assets/Images'
import FinalFlowPage from './FinalFlowPage'
import { useNavigate } from 'react-router-dom'

const InviteFlow = () => {

    const navigate = useNavigate()

    const [inviteProvider, setInviteProvider] = useState(false)
    const [completeProfile, setCompleteProfile] = useState(false)

    const handleNext = () => {
        if (inviteProvider) {
            setCompleteProfile(true)
        } else {
            setInviteProvider(true)
        }
    }

    const handleRedirect = async()=>{
        navigate("/admin/homelisting",{
            state :"admin"
        })
    }


    return (
        <div className='w-100 invite-flow'>
            {
                completeProfile ? <FinalFlowPage handleRedirect={handleRedirect}/> :
                    <>
                        <h1 className='flow-header text-center'>
                            To invite {inviteProvider ? "providers" : "patients"} to <br />u
                            register on the platform!
                        </h1>
                        <div className='platform-feature'>
                            <div className='platform-sub-feature d-flex'>
                                <div className='d-flex flex-column align-items-center gap-4 w-100'>
                                    <div className='feature-option'>
                                        <img src={inviteProvider ? images.InviteProvider : images.InvitePatient} alt="" />
                                        <h2 className='feature-name'>{inviteProvider ? "Provider" : "Patient"} Profiles</h2>
                                    </div>
                                    <p className='feature-title'>1. Select “{inviteProvider ? "Provider" : "Patient"} Profiles” from your admin home screen.</p>
                                </div>
                                <div className='d-flex flex-column align-items-center gap-4 w-100'>
                                    <div className='feature-option'>
                                        <img src={inviteProvider ? images.InviteProviderBtn : images.InvitePatientBtn} alt="" />
                                        <h2 className='feature-name'>Invite {inviteProvider ? "provider" : "patient"}</h2>
                                    </div>
                                    <p className='feature-title'>2. Select “Invite {inviteProvider ? "Provider" : "Patient"}"</p>
                                </div>
                                <div className='d-flex flex-column align-items-center gap-4 w-100'>
                                    <div className='feature-option'>
                                        <img src={inviteProvider ? images.InviteProviderDetails : images.InvitePatientDetails} alt="" />
                                        <h2 className='feature-name'>Fill the details</h2>
                                    </div>
                                    <p className='feature-title'>3. Select “Enter their information”</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center gap-4'>
                                <button className='back-theme-btn' onClick={handleRedirect}>Go to Admin Home Screen</button>
                                <button className='btn yes-theme-btn' onClick={handleNext}>Next</button>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default InviteFlow