import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAdminplan } from '../../../Store/Slicers/commonSlicer';
import images from '../../../Assets/Images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { EyeClose, EyeOpen, LandingLogo, LoginPopupBG, Padlock } from '../../../Assets/Svgs';
import { passwordValidation } from '../../../Components/utils';
import AdminDemoProfile from './AdminDemoProfile';

//css
import '../SignInFlow/LoginScreen.scss'
import MainAdminDemoProfile from './MainAdminDemoProfile';

const AdminDemoLogin = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showModal, setShowModal] = useState<boolean>(true);
    const [isRegisterClick, setIsRegisterClick] = useState<boolean>(false);
    const [isContinueClick, setIsContinueClick] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);

    const [regiterEmail, setRegiterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerPasswordConfirm, setRegisterPasswordConfirm] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userName, setUserName] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSendVerification, setIsSendVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [forgotModal, setForgotModal] = useState<any>(false)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch(setAdminplan("clinic"))
        navigate("/admin-demo-home");
    }

    const handleRegister = () => {
        setIsRegisterClick(true)
    }

    const handleFormSubmit = (e: any) => {
        e.preventDefault()
        setIsSendVerification(true)
    };

    const handleVerification = (e: any) => {
        e.preventDefault()
        setIsSendVerification(false)
        setIsContinueClick(true);
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e: any) => {
        setRegisterPassword(e.target.value);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setRegisterPasswordConfirm(e.target.value);
    };

    const handleSave = () => {
        dispatch(setAdminplan("clinic"))
        navigate("/admin-demo-home");
    }

    const handleResetPassword = () => {
        setForgotModal(true)
    }

    const handlePassword = () => {
        navigate('/forgot-password', {
            state: "demo-center"
        })
    }
    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center ${isContinueClick ? "modal-xl" : "modal-lg"}`}
                >
                    <div className="modal-content bg-transparent p-0 shadow-none border-0 overflow-visible" style={{ maxWidth: isContinueClick ? "" : "628px" }}>
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup">
                                {
                                    forgotModal ?
                                        <div className="waiting-login">
                                            <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Create a new password! <img src={Padlock} alt="" />
                                            </h1>
                                            <p className="desc">Let’s setup a new password
                                            </p>
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <form
                                                        className="login-form d-flex flex-column"
                                                        onSubmit={handlePassword}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                        >
                                                            Continue
                                                        </button>
                                                    </form>
                                                </div>
                                                <div
                                                    className={`text-center login-logo`}
                                                >
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        !isRegisterClick ?

                                            <div className="waiting-login">
                                                <h1 className="waiting-header">
                                                    Welcome!
                                                </h1>
                                                <p className="desc">
                                                    Please sign in or register as an Admin to access your Admin tool
                                                </p>
                                                <div className="signin-div w-100 d-flex align-items-center flex-column gap-4">
                                                    <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                        <h1 className="signin-header">Sign In</h1>
                                                        <form
                                                            className="login-form d-flex flex-column"
                                                            onSubmit={handleSubmit}
                                                        >
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter your email address"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                autoComplete="new-email"
                                                            />
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                autoComplete="new-password"
                                                            />
                                                            <div className="remember d-flex align-items-center">
                                                                <input type="checkbox" />
                                                                <span>Remember me on this computer</span>
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className="yes-theme-btn border-0"
                                                                style={{
                                                                    padding: "16px 24px",
                                                                    borderRadius: "6px",
                                                                }}
                                                            >
                                                                Sign In
                                                            </button>
                                                            <p
                                                                onClick={handleResetPassword}
                                                                className="forgotpassword-title">Forgot Password</p>
                                                        </form>
                                                    </div>
                                                    <div className='signUp-tag'>
                                                        Don’t have a Telehealth account <span onClick={() => navigate('/pricing')}>Signup</span> today
                                                    </div>
                                                    <div className={`text-center login-logo`}>
                                                        <img src={LandingLogo} alt="" />
                                                    </div>
                                                </div>
                                            </div> :

                                            isContinueClick ?
                                                <MainAdminDemoProfile handleSave={handleSave} type='demo-flow' /> :
                                                <div className="waiting-login align-items-center">
                                                    <h1 className="waiting-header d-flex justify-content-center align-items-center gap-4">
                                                        Choose username and password!
                                                        <img src={Padlock} alt="" />
                                                    </h1>
                                                    {/* <p className='desc'>Let’s setup a user name and password to keep your information and Telehealth session safe</p> */}
                                                    <form
                                                        className="register-form d-flex flex-column w-75"
                                                        onSubmit={isSendVerification ? handleVerification : handleFormSubmit}
                                                    >
                                                        {isSendVerification ?
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter verification code"
                                                                    value={verificationCode}
                                                                    onChange={(e) => setVerificationCode(e.target.value)}
                                                                />
                                                            </> : <>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                    value={regiterEmail}
                                                                    onChange={(e) => setRegiterEmail(e.target.value)}
                                                                    autoComplete="new-email"
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="User Name"
                                                                    value={userName}
                                                                    onChange={(e) => setUserName(e.target.value)}
                                                                    autoComplete="new-email"
                                                                />
                                                                <div>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={showPassword ? "text" : "password"}
                                                                            className="form-control"
                                                                            placeholder="Password"
                                                                            value={registerPassword}
                                                                            onChange={handlePasswordChange}
                                                                            autoComplete="new-password"
                                                                        />
                                                                        <img
                                                                            src={showPassword ? EyeOpen : EyeClose}
                                                                            className="position-absolute cursor-pointer"
                                                                            style={{ right: "15px", bottom: "20px" }}
                                                                            onClick={handleTogglePasswordVisibility}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="position-relative">
                                                                        <input
                                                                            type={showConfirmPassword ? "text" : "password"}
                                                                            className="form-control"
                                                                            placeholder="Re-enter Password"
                                                                            value={registerPasswordConfirm}
                                                                            onChange={handleConfirmPasswordChange}
                                                                        />
                                                                        <img
                                                                            src={showConfirmPassword ? EyeOpen : EyeClose}
                                                                            className="position-absolute cursor-pointer"
                                                                            style={{ right: "15px", top: "22px" }}
                                                                            onClick={handleToggleConfirmPasswordVisibility}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="remember d-flex align-items-center">
                                                                    <input type="checkbox" />
                                                                    <span>Remember me on this computer</span>
                                                                </div>
                                                            </>}
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{ padding: "16px 24px", borderRadius: "6px" }}
                                                        >
                                                            Continue
                                                        </button>
                                                        {(isRegisterClick && !isSendVerification) && <p onClick={() => setIsRegisterClick(false)} className="login-text">If already have an account <span>Log In</span></p>}
                                                    </form>
                                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AdminDemoLogin