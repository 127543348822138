import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AppState {
  care_team_data: any[];
  practitioner_data: any[];
}

const initialState: AppState = {
  care_team_data: [],
  practitioner_data: [],
};

export const careTeamSection = createSlice({
  name: "careTeamSection",
  initialState,
  reducers: {
    careTeamData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.care_team_data = action.payload;
    },
    practitionerData: (
      state: Draft<typeof initialState>,
      action: PayloadAction<any>
    ) => {
      state.practitioner_data = action.payload;
    },
    resetCareTeam: () => initialState,
  },
});

export const { careTeamData, practitionerData, resetCareTeam } = careTeamSection.actions;
