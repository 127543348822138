import React from "react";

// redux
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Component
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Redux
import { store } from "./Store";

// css
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
