import { createSlice } from "@reduxjs/toolkit";
import { fetchNotificationPreferences } from "../../Thunk/NotificationPreferences/getNotificationPreferences";

export interface AppState {
    NotificationPreferences: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    NotificationPreferences: [],
    isLoading: false,
    error: null,
}
const NotificationPreferencesSlice = createSlice({
    name: "NotificationPreferences",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotificationPreferences.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchNotificationPreferences.fulfilled, (state, action) => {
                state.isLoading = false;
                state.NotificationPreferences = action.payload;
            })
            .addCase(fetchNotificationPreferences.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });

    },
})

export const {resetState} = NotificationPreferencesSlice.actions

export default NotificationPreferencesSlice;