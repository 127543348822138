import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { ApiUrl } from "../../../Network/ApiUrl";
import { get } from "../../../Network/ApiService";


interface FetchAdminScheduleAppoinmentArgs {
    admin_id:string;
    from_date_time:string;
    to_date_time:string;
    authToken:string
}

// Define the return type for the Thunk action
interface AdminScheduleAppoinmentMessages {
    messages: any;
}

export const fetchAdminScheduleAppoinments = createAsyncThunk<AdminScheduleAppoinmentMessages,FetchAdminScheduleAppoinmentArgs, { state: RootState }>(
    'scheduling/fetchAdminScheduleAppoinments',
    async ({admin_id,from_date_time,to_date_time,authToken}, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.adminSchedule.get_admin_schedule}?admin_id=${admin_id}&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
            const response = await get(ApiEndPoint,authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch Admin Schedule data');
            }
        } catch (error) {
            throw error;
        }
    }
)