
import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from '../../../../Network/ApiService';
import { ApiUrl } from '../../../../Network/ApiUrl';
import { RootState } from '../../..';
import { setHIPPAConscentFormData, setInformationReleseAuthorizationFormData, setPatientInformation, setPatientMedicalHistory, setTelhealthConscentFormData } from '../../../Slicers/PatientFormsData/PatientFormsDataSlicer';

interface GetPatientInformationArgs {
    type: "patientInformation" | "medicalHistory" | "HIPPAConscent" | "TelhealthConscent" | "InformationRelease";
    patient_id: string | number;
    authToken: string;
}

interface GetPatientInformationData {
    messages: any;
}

export const GetPatientInformation = createAsyncThunk<GetPatientInformationData, GetPatientInformationArgs, { state: RootState }>(
    'myForms/GetPatientInformation',
    async ({ type, patient_id, authToken }, { dispatch }) => {
        try {

            let Endpoint;
            switch (type) {
                case 'patientInformation':
                    Endpoint = ApiUrl.myForms.patient_information.patient_get_information;
                    break;
                case 'medicalHistory':
                    Endpoint = ApiUrl.myForms.patient_history.patient_get_history;
                    break;
                case 'HIPPAConscent':
                    Endpoint = ApiUrl.myForms.patient_hippa_consent.patient_get_hippa_consent
                    break;
                case 'TelhealthConscent':
                    Endpoint = ApiUrl.myForms.patient_telehealth_consent.patient_get_telehealth_consent
                    break;
                case 'InformationRelease':
                    Endpoint = ApiUrl.myForms.patient_information_release.patient_get_information_release
                    break;
            }
            const ApiEndPoint = `${Endpoint}?patient_id=${patient_id}`;
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                switch (type) {
                    case 'patientInformation':
                        dispatch(setPatientInformation(response.data[0]));
                        break;
                    case 'medicalHistory':
                        dispatch(setPatientMedicalHistory(response.data[0]));
                        break;
                    case 'HIPPAConscent':
                        dispatch(setHIPPAConscentFormData(response.data[0]));
                        break;
                    case 'TelhealthConscent':
                        dispatch(setTelhealthConscentFormData(response.data[0]));
                        break;
                    case 'InformationRelease':
                        dispatch(setInformationReleseAuthorizationFormData(response.data[0]));
                        break;
                }
                return response.data;
            } else {
                throw new Error('Failed to Fetch Leave Requests');
            }
        } catch (error) {
            throw error;
        }
    }
);
