
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface FetchUserMessageDataArgs {
    userId: any;
    authToken: string
}

// Define the return type for the Thunk action
interface UserMessages {
    messages: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchUserMessageData = createAsyncThunk<UserMessages, FetchUserMessageDataArgs, { state: RootState }>(
    'profile/fetchUserMessages',
    async ({ userId, authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.user_messages.get_messages}?user_id=${userId}`;
            const response = await get(ApiEndPoint, authToken);

            if (response) {
                return response.data; // This value will be the payload of the fulfilled action
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
