import { createSlice } from "@reduxjs/toolkit";
import { fetchProviderByServiceThunk } from "../../Thunk/ProviderByServiceThunk/fetchProviderByServiceThunk";

export interface AppState {
    ProviderByServiceData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    ProviderByServiceData: [],
    isLoading: false,
    error: null,
}
const ProviderByServiceSlice = createSlice({
    name: "ProviderByService",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProviderByServiceThunk.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProviderByServiceThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.ProviderByServiceData = action.payload;
            })
            .addCase(fetchProviderByServiceThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });

    },
})

export const {resetState} = ProviderByServiceSlice.actions

export default ProviderByServiceSlice;