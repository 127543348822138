
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';


interface GetUserServicesArgs {
    authToken:string;
}

// Define the return type for the Thunk action
interface UserMessages {
    messages: any;
}

// Create the Thunk action
export const fetchUserServices = createAsyncThunk<UserMessages, GetUserServicesArgs, { state: RootState }>(
    'services/fetchUserServices',
    async ( {authToken}, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.services.get_service}`;
            const response = await get(ApiEndPoint,authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error;
        }
    }
);
