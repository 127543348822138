
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../../index';

interface getProvidersByValidScheduleDataArg {
    from_date_time: string;
    authToken: string;
}

// Define the return type for the Thunk action
interface getProvidersByValidScheduleResponse {
  messgae:any
}

export const getProvidersByValidSchedule = createAsyncThunk<getProvidersByValidScheduleResponse, getProvidersByValidScheduleDataArg, { state: RootState }>(
    'profile/getProvidersByValidSchedule',
    async ({ from_date_time, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint = `${ApiUrl.appointments.services_api.get_providers_by_valid_schedule}?from_date_time=${from_date_time}`;
            const response = await get(ApiEndPoint, authToken);

            if (response) {
                return response.data; // This value will be the payload of the fulfilled action
            } else {
                throw new Error('Failed to fetch Provider profile data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
