import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react'
import { formatDEANumber, formatPhoneNumber, MedicalLicense, Specialty, USAStatesCB } from '../../../../Components/utils';
import CBSelectState from '../../../../Components/Common/CBSelect/CBSelectState';
import Select from "react-select";
import * as Yup from 'yup';
import { CloseModelIcon } from '../../../../Assets/Svgs';
import { FileUploader } from 'react-drag-drop-files';

import "../../ProviderLoginFlow/WaitingScreen.scss"
import images from '../../../../Assets/Images';

interface FileState {
    file: File | null;
    fileName: string | null;
    fileBuffer: string | null;
}

const fileTypes = ["JPEG", "PNG", "JPG"];

interface profileProps {
    handleSaveClick?: any,
    type?: string;
}

const ProviderDemoProfile = ({ handleSaveClick, type }: profileProps) => {

    const [selectedLicense, setSelectedLicense] = useState<string[]>([]);

    const [profilePicture, setProfilePicture] = useState<FileState>({
        file: null,
        fileName: null,
        fileBuffer: null,
    });

    const selectedOptions = MedicalLicense.filter(option => selectedLicense.includes(option.value));

    const initialValues: any = {
        firstname: "",
        lastname: "",
        Address: "",
        SuiteNumber: "",
        City: "",
        State: "",
        ZipCode: "",
        DateofBirth: "",
        Gender: "",
        NPINumber: "",
        DEANumber: "",
        Phone: "",
        Fax: "",
        Email: "",
        NewRx: false,
        ControllSubstance: false,
        //Refills: false,
        LiscenseType: '',
        Specialities: '',
    };

    const validationSchema = Yup.object().shape({
        firstname: Yup.string(),
        lastname: Yup.string(),
        Address: Yup.string(),
        SuiteNumber: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Suite Number must be alphanumeric'),
        City: Yup.string(),
        State: Yup.string().test('State', 'Invalid State', value => {
            if (!value) return true; // Allow empty values
            const validStates = USAStatesCB.map(state => state.value);
            const validStatesLAbel = USAStatesCB.map(state => state.label?.toUpperCase());
            if (value?.length === 2) {
                return validStates.includes(value);
            }
            else if (value?.length >= 2) {
                if (validStatesLAbel.includes(value?.toUpperCase())) {
                    return validStatesLAbel.includes(value?.toUpperCase());

                }
                else {
                    return false;
                }
            }

        }),
        ZipCode: Yup.string(),
        DateofBirth: Yup.string(),
        Gender: Yup.string(),
        NPINumber: Yup.number().typeError('NPI Number must be a number'),
        DEANumber: Yup.string().matches(/^[a-zA-Z]{2}\d{7}$/, 'DEA Number must be in the format XX0000000'),
        Phone: Yup.string(),

        Fax: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Fax number must be in the format (XXX) XXX-XXXX'),

        LiscenseType: Yup.array().of(Yup.string()).max(3, 'You can select up to three medical licenses'),
        Specialities: Yup.string(),
    });

    const handleSubmit = (e: any, value: any) => {
        console.log("value", value)
        handleSaveClick()
    }

    const handleChange = (file: File, setFileState: React.Dispatch<React.SetStateAction<FileState>>) => {
        setFileState((prevFileState) => ({
            ...prevFileState,
            file: file,
            fileName: file.name,
        }));

        let reader = new FileReader();
        reader.onloadend = function () {
            setFileState((prevFileState) => ({
                ...prevFileState,
                fileBuffer: reader.result as string,
            }));
        };
        reader.readAsDataURL(file);
    };

    const formatOptionLabel = ({ label, value }: any, { context }: any) => {
        return context === 'menu' ? label : value;
    };

    const isOptionDisabled = () => {
        return selectedOptions.length >= 3;
    };

    return (
        <section className="provider-profile">
            <div className="row">
                <div className="col-lg-12 col-xl-12">
                    <div className='profile-formbox'>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {(formik: any) => (
                                <>
                                    <Form style={{ padding: "6px", height: handleSaveClick ? "calc(100vh - 260px)" : "", overflowY: "auto" }} onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                        }
                                    }}>
                                        {type === 'demo-flow' && <h3 className="sm_card_title d-flex justify-content-between pb-3 w-100" style={{ borderBottom: "1px solid #dadada" }}>my Account Information
                                            <div className='d-flex gap-2'>
                                                <button className='yes-theme-btn' type='submit'
                                                >Save</button>
                                            </div>
                                        </h3>}
                                        <div className="p-2">
                                            <div className="row">

                                                <div className="row">
                                                    <div className="col-lg-6 ">
                                                        <div className="form-group flex-column d-flex position-relative">
                                                            {/* <label>Upload your photo</label> */}
                                                            <img src={CloseModelIcon} alt="" className={`position-absolute cursor-pointer z-1 remove-image ${!profilePicture?.fileName ? "d-none" : ""}`} height={"14px"} width={"14px"}
                                                                onClick={() => setProfilePicture({
                                                                    file: null,
                                                                    fileName: null,
                                                                    fileBuffer: "",
                                                                })} />
                                                            <div className={`fileuploadbox ${profilePicture.file ? "uploaded" : ""}`} style={{ width: "100%" }}>
                                                                <FileUploader
                                                                    multiple={false}
                                                                    handleChange={(file: any) => handleChange(file, setProfilePicture)}
                                                                    name="file"
                                                                    types={fileTypes}
                                                                    label="Upload or drop a file here"
                                                                    className="Dropzone"
                                                                    children={
                                                                        <div className="uploader-box">
                                                                            <p className="text-dark p-0" style={{ fontSize: "14px" }}>Upload your photo</p>
                                                                            <p className="upload-text">
                                                                                Drag file here to upload
                                                                            </p>
                                                                            {
                                                                                profilePicture.fileBuffer ?
                                                                                    <img src={profilePicture.fileBuffer} alt="" height={30} style={{ borderRadius: "50%" }} />
                                                                                    :
                                                                                    <img src={images.DefaultImage} alt="" height={30} style={{ borderRadius: "50%" }} />
                                                                            }
                                                                            {profilePicture.fileName ? (
                                                                                <p className="uploadedfiletext">{`${profilePicture?.fileName?.slice(0, 40)}...`}</p>
                                                                            ) : (
                                                                                <button className="btn select-filebtn">
                                                                                    Select file
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="row">
                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <div className="col-input">
                                                                        <div className="col-label">
                                                                            <label htmlFor="firstname" className="form-label">
                                                                                First Name <span className="text-danger">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <Field
                                                                            type="text"
                                                                            className={`form-control ${formik.touched.firstname && formik.errors.firstname ? 'error-border' : ''}`}
                                                                            name="firstname"
                                                                        />
                                                                        <p><ErrorMessage name="firstname" /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 col-lg-12">
                                                                <div className="form-group">
                                                                    <div className="col-input">
                                                                        <div className="col-label">
                                                                            <label htmlFor="lastname" className="form-label">
                                                                                Last Name <span className="text-danger">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <Field
                                                                            type="text"
                                                                            className={`form-control ${formik.touched.lastname && formik.errors.lastname ? 'error-border' : ''}`}
                                                                            name="lastname"
                                                                        />
                                                                        <p><ErrorMessage name="lastname" /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-12">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Address && formik.errors.Address ? 'error-border' : ''}`}
                                                                name="Address"
                                                            />
                                                            <p><ErrorMessage name="Address" /></p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Address" className="form-label">
                                                                Address
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-12">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.SuiteNumber && formik.errors.SuiteNumber ? 'error-border' : ''}`}
                                                                name="SuiteNumber"
                                                            />
                                                            <p><ErrorMessage name="SuiteNumber" /></p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="SuiteNumber" className="form-label">
                                                                Suite Number
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.City && formik.errors.City ? 'error-border' : ''}`}
                                                                name="City"
                                                            />
                                                            <p><ErrorMessage name="City" /></p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="City" className="form-label">
                                                                City
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <CBSelectState options={USAStatesCB} seletedItem={formik.values.State} onChange={(option: string) => {
                                                                if (option?.length === 2) {
                                                                    formik.setFieldValue('State', option)
                                                                }

                                                                else {
                                                                    const validStatesLAbel = USAStatesCB.filter(state => state.label?.toUpperCase() === option?.toUpperCase());
                                                                    if (validStatesLAbel?.length > 0) {
                                                                        formik.setFieldValue('State', validStatesLAbel[0]?.value)
                                                                    }
                                                                    else {
                                                                        formik.setFieldValue('State', option)

                                                                    }

                                                                }
                                                            }} className={`${formik.touched.State && formik.errors.State ? 'error-border' : ''} w-100 m-0 StateDropdown-small-border-class`} placeholder='Select' />

                                                            <p><ErrorMessage name="State" /></p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="State" className="form-label">
                                                                State
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.ZipCode && formik.errors.ZipCode ? 'error-border' : ''}`}
                                                                name="ZipCode"
                                                            />
                                                            <p className="formik-validation-red-message">
                                                                {formik.validateOnBlur && formik.errors.ZipCode ? formik.errors.ZipCode : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="ZipCode" className="form-label">
                                                                Zip Code
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="form-profile-title">Identifying Information</h3>
                                                </div>
                                                <div className="col-sm-9 col-lg-6 mb-4">
                                                    <div className="form-group m-0">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.NPINumber && formik.errors.NPINumber ? 'error-border' : ''}`}
                                                                name="NPINumber"
                                                            />
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="NPINumber" className="form-label">
                                                                NPI Number <span className="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.NPINumber ? formik.errors.NPINumber : null}
                                                    </p>
                                                </div>
                                                <div className="col-sm-9 col-lg-6 mb-4">
                                                    <div className="form-group m-0">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.DEANumber && formik.errors.DEANumber ? 'error-border' : ''}`}
                                                                name="DEANumber"
                                                                onChange={(e: any) => {
                                                                    const formattedValue = formatDEANumber(e.target.value);
                                                                    formik.setFieldValue('DEANumber', formattedValue);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="DEANumber" className="form-label">
                                                                DEA Number
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.DEANumber ? formik.errors.DEANumber : null}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row w-100">
                                                <div className="col-sm-12">
                                                    <h3 className="form-profile-title">Medical License</h3>
                                                </div>
                                                <div className="col-md-12 col-lg-6">
                                                    <div className="form-group">
                                                        <Select
                                                            isMulti={true}
                                                            value={selectedOptions as { value: string; label: string; }[]}
                                                            name="colors"
                                                            options={MedicalLicense}
                                                            getOptionLabel={(option) => option.label}
                                                            getOptionValue={(option) => option.value}
                                                            formatOptionLabel={formatOptionLabel}
                                                            className="basic-multi-select w-100"
                                                            onChange={(option) => {
                                                                const values = option ? option.map(option => option.value) : [];
                                                                setSelectedLicense(values)
                                                                formik.setFieldValue('LiscenseType', values)
                                                            }}
                                                            classNamePrefix="select"
                                                            isOptionDisabled={isOptionDisabled}
                                                            backspaceRemovesValue={false}
                                                            placeholder='Choose up to three'
                                                            styles={{
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    margin: 0,
                                                                    borderRadius: "8px",
                                                                    border: "1px solid #516CD3",
                                                                    // overflowY: "auto",
                                                                    maxHeight: "190px",
                                                                }),
                                                                menuList: (provided) => ({
                                                                    ...provided,
                                                                    // padding: 0,
                                                                    overflowY: "auto",
                                                                    maxHeight: "190px",
                                                                    padding: "5px",
                                                                    fontSize: "14px",
                                                                    color: "#000000"
                                                                }),
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    backgroundColor: state.isFocused ? 'transparent' : 'transparent',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        borderRadius: "4px",
                                                                        background: "rgba(81, 108, 211, 0.1)"
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                        <div className="col-label">
                                                            <label htmlFor="LiscenseType" className="form-label">
                                                                Professional License
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.LiscenseType ? formik.errors.LiscenseType : null}
                                                    </p>
                                                </div>
                                                <div className="col-sm-9 col-lg-12 col-xl-6">
                                                    <div className="form-group">
                                                        <CBSelectState options={Specialty} className='m-0 w-100 specialty-dropdoen' seletedItem={formik.values.Specialities} placeholder='Select Specialty' onChange={(option: string) => {
                                                            formik.setFieldValue('Specialities', option)
                                                        }} type="Specialities" />
                                                        <p><ErrorMessage name="Specialities" /></p>
                                                        <div className="col-label">
                                                            <label htmlFor="Specialities" className="form-label">
                                                                License/Specialty
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h3 className="form-profile-title">Contact Details</h3>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group mb-1">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Phone && formik.errors.Phone ? 'error-border' : ''}`}
                                                                name="Phone"
                                                                onChange={(e: any) => {
                                                                    const formattedValue = formatPhoneNumber(e.target.value);
                                                                    formik.setFieldValue('Phone', formattedValue);
                                                                }}
                                                            />

                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Phone" className="form-label">
                                                                Phone <span className="text-danger">*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p className="formik-validation-red-message">
                                                        {formik.validateOnBlur && formik.errors.Phone ? formik.errors.Phone : null}
                                                    </p>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Fax && formik.errors.Fax ? 'error-border' : ''}`}
                                                                name="Fax"
                                                                onChange={(e: any) => {
                                                                    const formattedValue = formatPhoneNumber(e.target.value);
                                                                    formik.setFieldValue('Fax', formattedValue);
                                                                }}

                                                            />
                                                            <p className="formik-validation-red-message">
                                                                {formik.validateOnBlur && formik.errors.Fax ? formik.errors.Fax : null}
                                                            </p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Fax" className="form-label">
                                                                Fax
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-md-6 col-xl-4">
                                                    <div className="form-group">
                                                        <div className="col-input">
                                                            <Field
                                                                type="text"
                                                                className={`form-control ${formik.touched.Email && formik.errors.Email ? 'error-border' : ''}`}
                                                                name="Email"
                                                                disabled
                                                            />
                                                            <p><ErrorMessage name="Email" /></p>
                                                        </div>
                                                        <div className="col-label">
                                                            <label htmlFor="Email" className="form-label">
                                                                Email
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {type !== 'demo-flow' && <div className="col-12 d-flex justify-content-end">
                                                <button type="submit" className="rounded-save-theme-btn" disabled={formik.isSubmitting || !formik.isValid}>
                                                    Save
                                                </button>
                                            </div>}
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProviderDemoProfile