import { createSlice } from "@reduxjs/toolkit";
import { fetchAppointmentDateView } from "../../Thunk/Appointment/getAppointmentDateViewThunk";
import { fetchDateViewBetweenByServiceThunk } from "../../Thunk/Appointment/Api_Services/GetDateViewBetweenByServiceThunk";

export interface AppState {
    AppointmentDateViewData: [] | any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    AppointmentDateViewData: [],
    isLoading: false,
    error: null,
};

const AppointmentDateViewSlice = createSlice({
    name: "AppointmentDateViewData",
    initialState,
    reducers: {
        // Your other reducers go here
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppointmentDateView.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAppointmentDateView.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AppointmentDateViewData = action.payload;
            })
            .addCase(fetchAppointmentDateView.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(fetchDateViewBetweenByServiceThunk.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchDateViewBetweenByServiceThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AppointmentDateViewData = action.payload;
            })
            .addCase(fetchDateViewBetweenByServiceThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
    }

});

export default AppointmentDateViewSlice;
