import React from 'react'
import { convertUtcToLocalTimeStringTimeZone } from '../../../Components/utils';
interface TooltipProps {
  isVisible: boolean,
  position: { top: number, left: number },
  title: string,
  className?: string;
  toolTipData?: { providerName: string, date: string};
}

const Tooltip = ({ isVisible, position, title, className, toolTipData }: TooltipProps) => {
  if (!isVisible) return null;

  return (
    <div
      className={`position-absolute d-flex flex-column visible`}
      style={{ top: position.top, left: position.left }}
    >
      <div className={`hover-triangle ${className}`}></div>
      <div className="hover-div " style={{width: title === "EventTooltip" ? '200px' : "" }}>
        {title === "EventTooltip" ?
          <>
            <div>
              <p>
                Provider Name : {toolTipData?.providerName}
              </p>
              <p>
                Scheduled Time : {convertUtcToLocalTimeStringTimeZone(toolTipData?.date)}
              </p>
            </div>
          </>
          : title}
      </div>
    </div>
  );
};
export default Tooltip

