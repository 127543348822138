
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';

interface FetchScheduleAppointmentDataArgs {
    event_id: string;
    authToken: string;
}

// Define the return type for the Thunk action
interface ScheduleAppointmentData {
    message: string;
    userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchScheduleAppointmentData = createAsyncThunk<ScheduleAppointmentData, FetchScheduleAppointmentDataArgs, { state: RootState }>(
    'appointment/fetchScheduleAppointmentData',
    async ({ event_id, authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.schedule_appointment.create_appointment}?event_id=${event_id}`;
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                let data = response.data.userData.map((data: any) => {
                    const simplifiedObject = {
                        from_date: data.from_date?.S || "",
                        to_date: data.to_date?.S || "",
                        status: data.status?.S || "",
                        event_id: data.event_id?.S || "",
                        provider_id: data.provider_id?.S || "",
                        patient_id: data.patient_id?.S || "",
                    };
                    return simplifiedObject;
                });
                return data;
            } else {
                throw new Error('Failed to fetch patient profile data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
