import { faCircleCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import images from '../../../../Assets/Images';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { setLocalStorage } from '../../../../Components/Patient/Utils/AuthHandlers';

interface formData {
    adminName: string;
    email: string;
    providers: Provider[];
}

interface Provider {
    name: string;
    email: string;
}

const ProviderStep = ({ handleBackButtonClick, handleCheckoutButtonClick }: any) => {
    const [counter, setCounter] = useState(1);

    const initialValues: formData = {
        adminName: '',
        email: '',
        providers: [{ name: '', email: '' }]
    };

    const validationSchema = Yup.object().shape({
        adminName: Yup.string().required('Admin name is a required field'),
        email: Yup.string()
            .email('Email address is not valid')
            .required('Email is a required field'),
        providers: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Provider name is a required field'),
                email: Yup.string()
                    .email('Email address is not valid')
                    .required('Provider email is a required field'),
            })
        ).min(1, 'At least one provider is required')
    });

    const handleSubmit = (values: formData) => {
        console.log("🚀 ~ handleSubmit ~ values:", values);
        setLocalStorage("admin", JSON.stringify({ name: values.adminName, email: values.email }))
        setLocalStorage("provider", JSON.stringify(values.providers))
        handleCheckoutButtonClick();
    };

    const handleDecrement = (formik: any) => {
        if (counter > 1) {
            setCounter(counter - 1);
            // Remove the last provider from Formik values
            formik.setFieldValue('providers', formik.values.providers.slice(0, -1));
        }
    };

    const handleIncrement = (formik: any) => {
        setCounter(counter + 1);
        // Add a new provider to Formik values
        formik.setFieldValue('providers', [...formik.values.providers, { name: '', email: '' }]);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, formik: any) => {
        const newValue = parseInt(event.target.value, 10) || 0;
        setCounter(newValue);
        if (newValue > formik.values.providers.length) {
            formik.setFieldValue('providers', [...formik.values.providers, ...Array(newValue - formik.values.providers.length).fill({ name: '', email: '' })]);
        } else if (newValue < formik.values.providers.length) {
            formik.setFieldValue('providers', formik.values.providers.slice(0, newValue));
        }
    };

    return (
        <div className='w-100'>
            <div className='d-flex onboarding-main-flow'>
                <div className='d-flex flex-column onboarding-stepping' style={{ gap: "24px" }}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            (formik) => (
                                <Form id='step1-form'>
                                    <div className='onboarding-step'>
                                        <div>
                                            <label className='onboarding-user'>Who will be your primary Admin?</label>
                                            <div className="row">
                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                    <div className="form-group position-relative">
                                                        <label className='onboarding-user'>Admin name*</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control w-100 ${formik.values.adminName.length > 0 ? '' : 'input-user'}`}
                                                            name="adminName"
                                                            placeholder='Enter name'
                                                        />
                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.adminName.length > 0 ? 'check' : 'd-none'}`} />
                                                    </div>
                                                    <ErrorMessage name="adminName" component="div" className="text-danger error pt-2" />
                                                </div>
                                                <div className='col-lg-6 col-md-6 pb-4'>
                                                    <div className="form-group position-relative">
                                                        <label className='onboarding-user'>Email address*</label>
                                                        <Field
                                                            type="text"
                                                            className={`form-control w-100 ${formik.values.email ? '' : 'input-user'}`}
                                                            name="email"
                                                            placeholder='Enter email'
                                                        />
                                                        <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.email ? 'check' : 'd-none'}`} />
                                                    </div>
                                                    <p className='text-danger pt-2'>
                                                        {formik.validateOnBlur && formik.errors.email ? formik.errors.email : null}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='pt-2 number-of-users-div mb-4'>
                                                <p className='onboarding-user'>How many providers do you have?</p>
                                                <div className="d-flex gap-2 pt-2">
                                                    <div className="input-area position-relative" onClick={() => handleDecrement(formik)}>
                                                        <FontAwesomeIcon icon={faMinus} className='plus_minus_icon' />
                                                    </div>
                                                    <div className="w-100">
                                                        <div className="d-flex align-items-center position-relative">
                                                            <input
                                                                type="text"
                                                                name="providerCount"
                                                                value={counter}
                                                                onChange={(e) => handleChange(e, formik)}
                                                                className={`input-user user-input w-100`}
                                                                style={{ paddingLeft: "30px" }}
                                                            />
                                                            <img src={images.UserIcon} alt="providers" className='user-icon' />
                                                        </div>
                                                    </div>
                                                    <div className="input-area position-relative" onClick={() => handleIncrement(formik)}>
                                                        <FontAwesomeIcon icon={faPlus} className='plus_minus_icon' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='provider-counter'>
                                                {
                                                    formik.values.providers.map((provider, index) => (
                                                        <div key={index} className="row">
                                                            <div className='col-lg-6 col-md-6 pb-4'>
                                                                <div className="form-group position-relative">
                                                                    <label className='onboarding-user'>Provider name*</label>
                                                                    <Field
                                                                        type="text"
                                                                        className={`form-control w-100 ${formik.values.providers[index]?.name ? '' : 'input-user'}`}
                                                                        name={`providers.${index}.name`}
                                                                        placeholder='Enter name'
                                                                    />
                                                                    <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.providers[index]?.name ? 'check' : 'd-none'}`} />
                                                                </div>
                                                                <ErrorMessage name={`providers.${index}.name`} component="div" className="text-danger error pt-2" />
                                                            </div>
                                                            <div className='col-lg-6 col-md-6 pb-4'>
                                                                <div className="form-group position-relative">
                                                                    <label className='onboarding-user'>Provider email*</label>
                                                                    <Field
                                                                        type="text"
                                                                        className={`form-control w-100 ${formik.values.providers[index]?.email ? '' : 'input-user'}`}
                                                                        name={`providers.${index}.email`}
                                                                        placeholder='Enter email'
                                                                    />
                                                                    <FontAwesomeIcon icon={faCircleCheck} size='lg' color="#00cc00" className={`${formik.values.providers[index]?.email ? 'check' : 'd-none'}`} />
                                                                </div>
                                                                <ErrorMessage name={`providers.${index}.email`} component="div" className="text-danger error pt-2" />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                    <div className='d-flex justify-content-between cliniconboarding-btns w-100'>
                        <button className='onboarding-back main-delete-theme-btn' onClick={handleBackButtonClick}>Back</button>
                        <button className='onboarding-checkout yes-theme-btn' type='submit' form='step1-form'>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderStep;
