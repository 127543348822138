// profileInformationThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { post } from '../../../Network/ApiService';
import { RootState } from '../..';
import { storeProviderProfileData } from './storeProviderProfileThunk';
import { storePatientProfileData } from './storePatientProfileThunk';
import { storeAdminProfileData } from './storeAdminProfileThunk';
import { toast } from 'react-toastify';

interface StorePatientProfileDataArgs {
    authToken: string;
    ImagePayload: any; // Adjust the type of data to match your data structure
    userId: string;
    profilepayload: any;
    type: string;
}

// Define the return type for the Thunk action
interface PatientDataResponse {
    status: number;
    message: string;
}

// Create the Thunk action
export const storeImages = createAsyncThunk<PatientDataResponse, StorePatientProfileDataArgs, { state: RootState }>(
    'profile/storeImageThunk',
    async ({ authToken, ImagePayload, userId, profilepayload, type }, { dispatch }) => {
        console.log("🚀 ~ userId:", userId)
        try {
            const successResponse: any = await post(ApiUrl.user_profile.store_user_images, authToken, ImagePayload);
            if (successResponse.status == 200) {
                const {
                    result: {
                        filepath: profileImagePath,
                        licensefilepath: licenseImagePath,
                        insurancefilepath: insuranceImagePath
                    }
                } = successResponse.data;
                if (type == 'admin') {
                    profilepayload.profile_image_path = profileImagePath;
                }
                else {
                    profilepayload.profileImagePath = profileImagePath;
                    profilepayload.licenseImagePath = licenseImagePath;
                    profilepayload.insuranceImagePath = insuranceImagePath;
                }
                switch (type) {
                    case 'patient':
                        await dispatch(storePatientProfileData({ authToken, data: profilepayload, userId }));
                        break;
                    case 'provider':
                        await dispatch(storeProviderProfileData({ authToken, data: profilepayload, userId }));
                        break;
                    case 'admin':
                        await dispatch(storeAdminProfileData({ authToken, data: profilepayload, userId }));
                        break;
                    default:
                    // Handle the default case if necessary
                }
                toast.dismiss()
                return { status: 200, message: 'Data saved successfully' };
            } else {
                toast.dismiss()
                return { status: successResponse.status, message: 'Error in saving Data' };
            }
        } catch (error) {
            toast.dismiss()
            return { status: 500, message: 'Internal Server Error' };
        }
    }
);
