import React from 'react'
import ImageUploadSquare from '../../../../../../../Assets/Svgs/ImageUploadSquare.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'


const ImageUpload = ({ selectedImage, onImageChange, fileInputRef, buttonText, onButtonClick, OnIconButtonClick, handleRemoveImage }: any) => {

    return (
        <div className='outer-box'>
            <div className="color-box position-relative ">
                {selectedImage ? (
                    selectedImage.includes('<svg') ? (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: selectedImage }} />
                        <FontAwesomeIcon icon={faClose} size='sm' color='white' className='close-icon-place' onClick={() => handleRemoveImage()} />
                      </>

                    ) : (
                        <>
                            <img src={selectedImage} alt="Selected Logo" />
                            <FontAwesomeIcon icon={faClose} size='sm' color='white' className='close-icon-place' onClick={() => handleRemoveImage()} />
                        </>
                    )
                ) : (
                    <img src={ImageUploadSquare} alt="Default Logo" />
                )}
            </div>
            <p className='color-text'>{buttonText}</p>
            <div className='d-flex pt-3 button-container'>
                <input
                    type="file"
                    onChange={onImageChange}
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <button className='font-para simple-button' onClick={onButtonClick}>
                    Upload Logo
                </button>
                <button className='font-para simple-button' onClick={OnIconButtonClick}>Choose Icon</button>
            </div>
        </div>
    )
}

export default ImageUpload