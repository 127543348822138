import React from 'react'
import { useSelector } from 'react-redux';
import images from '../../../../../Assets/Images';
import { RootState } from '../../../../../Store';

//css
import './CommonHeader.scss'
import Sidebar from '../Sidebar/Sidebar';
import { string } from 'yup';


interface HeaderComponentProps {
  ComponentTitle?: "Standard Platform" | "Customize",
  className?:string
}

const CommonHeader = ({ ComponentTitle, className }: HeaderComponentProps) => {
  const { headerText, textColor } = useSelector((state: RootState) => state.onBoarding.serviceValues);

  const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors);
  const selectedType = useSelector((state: RootState) => state.onBoarding.selectedType);

  const languageImage = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.06424 6.20794C8.06424 6.66973 8.03928 7.11488 7.9956 7.53923H4.13904C4.09328 7.11488 4.07039 6.66973 4.07039 6.20794C4.07039 5.74615 4.09536 5.30101 4.13904 4.87666H7.9956C8.04136 5.30101 8.06424 5.74615 8.06424 6.20794ZM8.66332 4.87666H11.224C11.3342 5.30309 11.3924 5.74823 11.3924 6.20794C11.3924 6.66765 11.3342 7.1128 11.224 7.53923H8.66332C8.707 7.11072 8.72988 6.66557 8.72988 6.20794C8.72988 5.75031 8.707 5.30517 8.66332 4.87666ZM11.0055 4.21102H8.57803C8.37002 2.88182 7.95816 1.76895 7.42772 1.05754C9.05646 1.48813 10.3815 2.66964 11.0035 4.21102H11.0055ZM7.90407 4.21102H4.23056C4.35745 3.45385 4.55298 2.78405 4.7922 2.24114C5.01061 1.75023 5.25399 1.39452 5.48904 1.16987C5.72202 0.949377 5.91547 0.882812 6.06732 0.882812C6.21917 0.882812 6.41262 0.949377 6.64559 1.16987C6.88065 1.39452 7.12402 1.75023 7.34244 2.24114C7.58373 2.78197 7.77926 3.45177 7.90407 4.21102ZM3.5566 4.21102H1.12909C1.75313 2.66964 3.07609 1.48813 4.70691 1.05754C4.17648 1.76895 3.76462 2.88182 3.5566 4.21102ZM0.910678 4.87666H3.47132C3.42763 5.30517 3.40475 5.75031 3.40475 6.20794C3.40475 6.66557 3.42763 7.11072 3.47132 7.53923H0.910678C0.800431 7.1128 0.742188 6.66765 0.742188 6.20794C0.742188 5.74823 0.800431 5.30309 0.910678 4.87666ZM4.7922 10.1727C4.5509 9.63184 4.35745 8.96203 4.23056 8.20487H7.90407C7.77718 8.96203 7.58165 9.63184 7.34244 10.1727C7.12402 10.6636 6.88065 11.0193 6.64559 11.2439C6.41262 11.4665 6.21917 11.5331 6.06732 11.5331C5.91547 11.5331 5.72202 11.4665 5.48904 11.246C5.25399 11.0214 5.01061 10.6657 4.7922 10.1747V10.1727ZM3.5566 8.20487C3.76462 9.53407 4.17648 10.6469 4.70691 11.3583C3.07609 10.9278 1.75313 9.74624 1.12909 8.20487H3.5566ZM11.0055 8.20487C10.3815 9.74624 9.05854 10.9278 7.4298 11.3583C7.96024 10.6469 8.37002 9.53407 8.58011 8.20487H11.0055Z" fill="${ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor}"/>
  </svg>
  `

  function RenderHeaderText() {
    switch (ComponentTitle) {
      case "Standard Platform":
        return handleText();

      case 'Customize':
        return headerText;

      default:
        return headerText;
    }
  }

  const handleText = () => {
    switch (selectedType) {
      case "Patient":
        return "Virtual Care";
      case "Provider":
        return "Provider Center";
      case "Admin":
        return "Admin Center"
      default:
        return 'Virtual Care'
    }
  }

  return (
    <div className={`w-100 screen-navbar position-relative z-4 ${className}`}>
      <div className="header p-2" style={{ backgroundColor: ComponentTitle === "Standard Platform" ? "" : selectedSecondaryColor }}>
        <nav className="navbar">
          <div className="container-fluid" style={{ backgroundColor: ComponentTitle === "Standard Platform" ? "" : selectedSecondaryColor }}>
            <button
              className="navbar-toggler d-md-none"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="custom-navbar">
              <div
                className="nav-left d-md-flex cursor-pointer"
                style={{ display: "none" }}
              >
                <h1 style={{ color: ComponentTitle === "Standard Platform" ? "#516cd3" : textColor }}> {RenderHeaderText()}</h1>
                <img src={images.authPower} alt="power" className="power-img" />
              </div>
              <div className="nav-right">
                {/* <div className="language-select">
                  <div dangerouslySetInnerHTML={{ __html: languageImage }} className='d-flex' />
                  <select
                    defaultValue={"en"}
                    className="form-select"
                    aria-label="Default select example"
                    style={{ color: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor }}
                  >
                    <option value="en">Eng</option>
                    <option value="fr">Fre</option>
                  </select>
                </div> */}
                <button
                  className="btn logout-btn"
                  style={{ backgroundColor: ComponentTitle === "Standard Platform" ? "" : selectedSecondaryColor, color: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor }}
                >
                  Logout
                </button>
              </div>
            </div>
            <div
              className="offcanvas offcanvas-start d-lg-none"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <Sidebar ComponentTitle={ComponentTitle} type="toggle-sidebar"/>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default CommonHeader