import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { ApiUrl } from "../../../Network/ApiUrl";
import { get } from "../../../Network/ApiService";

interface fetchEventDataByProviderIdArgs {
    provider_id:string
    from_date_time: string;
    to_date_time: string;
    authToken: string
}

interface fetchEventDataByProviderIdMessages {
    messages: any;
}

export const fetchEventDataByProviderId = createAsyncThunk<fetchEventDataByProviderIdMessages, fetchEventDataByProviderIdArgs, { state: RootState }>(
    'scheduling/fetchEventDataByProviderId',
    async ({ from_date_time, to_date_time,provider_id, authToken }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.userShift.get_provider_by_id}?provider_id=${provider_id}&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch Provider profile data');
            }
        } catch (error) {
            throw error;
        }
    }
)