// profileInformationThunks.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { put } from '../../../Network/ApiService';
import { RootState } from '../..';
import { fetchPatientProfileData } from '../getProfileData/patientProfileThunks';

interface StorePatientProfileDataArgs {
  authToken: string;
  data: any; // Adjust the type of data to match your data structure
  userId: string;
}

// Define the return type for the Thunk action
interface PatientDataResponse {
  status: number;
  message: string;
}

// Create the Thunk action
export const storePatientProfileData = createAsyncThunk<PatientDataResponse, StorePatientProfileDataArgs, { state: RootState }>(
  'profile/storeProfileData',
  async ({ authToken, data, userId }, { dispatch }) => {
    try {
      const API_URL = `${ApiUrl.user_profile.save_patient_profile}?userId=${userId}`;
      const successResponse = await put(API_URL, data, authToken);

      if (successResponse.status === 200) {
        dispatch(fetchPatientProfileData({ userId, authToken }));
        return { status: 200, message: 'Data saved successfully' };
      } else {
        return { status: successResponse.status, message: 'Error in saving Data' };
      }
    } catch (error) {
      return { status: 500, message: 'Internal Server Error' };
    }
  }
);
