import React from 'react'
//css
import './CommonHeader.scss'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../Store';

const SelectTypeHeader = () => {

    const SelectedType = useSelector((state: RootState) => state.onBoarding.selectedType);
    
    return (
        <div className='w-100 select-type-navbar position-relative'>
            <nav className="">
                <div className="innner-content">
                    <div className={`styled-button ${SelectedType === "Patient" && 'active-styled-button'}`} >Patient</div>
                    <div className={`styled-button ${SelectedType === "Provider" && 'active-styled-button'}`} >Provider</div>
                    <div className={`styled-button ${SelectedType === "Admin" && 'active-styled-button'}`} >Admin</div>
                </div>
            </nav>
        </div>
    )
}

export default SelectTypeHeader