import React from "react";
import { useNavigate } from 'react-router-dom'

// assets
import {
  faFacebook,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import images from "../../../../Assets/Images";

// css
import './Footer.scss'
import moment from "moment";
import { Instagram } from "../../../../Assets/Svgs";

const Footer = () => {
  const navigate = useNavigate();

  const currunt_url = new URL(window.location.href)
  const hostname = currunt_url.hostname
  console.log("🚀 ~ Footer ~ hostname:", hostname)

  const handleNavigateOurPlatForm = (route: string) => {
    window.open(`https://www.centiblick.com/${route}`, "_blank")
  }

  const handleNavigateResources = (route: string) => {
    window.open(
      `https://www.centiblick.com/resources/categories/${route}`, "_blank")
  }

  const openExternalUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const handleMediaNavigate = (route: 'linkedIn' | 'faceBook' | "instagram" | "twitter") => {
    switch (route) {
      case "linkedIn":
        openExternalUrl("https://www.linkedin.com/company/centiblick/");
        break;
      case "faceBook":
        openExternalUrl("https://www.facebook.com/CentiBlickTelehealth");
        break;
      case "instagram":
        openExternalUrl("https://www.instagram.com/centiblick/");
        break;
      case "twitter":
        openExternalUrl("https://x.com/CentiBlick");
        break;
      default:
        break;
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="footer">
          <div className="footerLogo">
            <div>
              <img src={images.Logo} alt="logo" className="logo" />
              <div className="icon">
                <FontAwesomeIcon
                  className="media"
                  icon={faLinkedinIn}
                  color="#0077b5"
                  onClick={() => handleMediaNavigate("linkedIn")}
                />
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="media"
                  color="#1877F2"
                  onClick={() => handleMediaNavigate('faceBook')}
                />
                <img
                  src={Instagram}
                  className="media"
                  onClick={() => handleMediaNavigate('instagram')}
                />
                <FontAwesomeIcon
                  icon={faXTwitter}
                  className="media"
                  style={{ color: "#000" }}
                  onClick={() => handleMediaNavigate("twitter")}
                />
              </div>
            </div>
            <p className="disc fw-normal">
              Compliant Telehealth
              That Meets <span className="fw-bold">HIPAA & HHS Guidelines</span>
            </p>
            <div>

              <button
                className="rounded-save-theme-btn"
                onClick={() => {
                  window.open('https://centiblickconnect.com/pricing', '_blank')
                }
                }
              >
                Contact Us  
              </button>
            </div>
          </div>
          <div className="footerTable d-flex justify-content-between">
            <div>
              <h1 >Our Platform</h1>
              <p
                onClick={scrollToTop}
              >
                Features
              </p>
              <p onClick={scrollToTop}
              >
                Plans
              </p>
              <p onClick={() => window.open(`${hostname === 'localhost' ? "http://localhost:3000/pricing" : `https://${hostname}/pricing`}`, '_blank')}>
                Pricing
              </p>
              <p >
              </p>
            </div>
            <div>
              <h1>Resources</h1>
              <p onClick={() => handleNavigateResources("telehealth-news-and-information")} >
                News Articles
              </p>
              <p onClick={() => handleNavigateResources("centiblick-announcements")}>
                CentiBlick Announcements
              </p>
              <p onClick={() => handleNavigateResources('telehealth-blog')} >
                Blog
              </p>
              <p onClick={() => handleNavigateOurPlatForm("company-contact-and-information")}>
                Contact Information
              </p>
            </div>
            <div>
              <h1>Company</h1>
              <p onClick={() => handleNavigateOurPlatForm("security-trust")} >
                Security & Trust
              </p>
              <p onClick={() => handleNavigateOurPlatForm("terms-of-service")}>
                Terms of Use
              </p>
              <p onClick={() => handleNavigateOurPlatForm("privacy-notice")} >
                Privacy Notice
              </p>
              <p onClick={() => handleNavigateOurPlatForm("help-support")} >
                Help and Support
              </p>
            </div>
          </div>
      </div>
      <div>
        <p className="last">©{moment().format("YYYY")} CentiBlick, Inc. <span onClick={() => window.open('https://www.summithealthportal.com/', '_blank')} className="cursor-pointer">All rights reserved.</span> | Cookie Settings</p>
      </div>
    </>
  );
};

export default Footer;
