import React from 'react'
import { useNavigate } from 'react-router-dom'

export const useInvestors = () => {
    const navigate = useNavigate()
  return {
    navigate
  }
}

