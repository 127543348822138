import React from 'react'

// redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Store';
import ImageHandler from '../../../../../../Components/Common/ImageHandler';
import { lightenColor } from '../../../../../../Components/utils';

interface TaskList {
    image?: string;
    doctorName?: string;
    recievedTime?: string;
    AppointmentTime?: string;
    Button?: string,
    appointment?: string
}
const taskList: TaskList[] = [
    {

        image: 'ProviderJulie',
        doctorName: 'Julie Gray',
        recievedTime: 'Virtual Primary Care',
        AppointmentTime: "Wed August 27, 2023 9:00 AM to 9:15 AM",
        Button: "Cancel",
        appointment: "Appointment Date"
    },
    {
        image: 'ProviderQuay',
        doctorName: 'Quay Nguyen',
        recievedTime: 'Virtual Primary Care',
        AppointmentTime: "Wed August 27,2023 9:15 AM to 9:30 AM",
        Button: "Cancel",
        appointment: "Appointment Date"
    },
    {
        doctorName: '9:30 AM to 9:45 AM',
        Button: "Schedule",
        appointment: "Unscheduled"
    },
    {
        image: 'ProviderRosa',
        doctorName: 'Rosa Lopez',
        recievedTime: 'Primary Care',
        AppointmentTime: "Wed August 27, 2023 9:30 am to 9:45 am",
        Button: "Reschedule",
        appointment: "Appointment Date"
    },
    {
        image: 'ProviderMarco',
        doctorName: 'Marco Juarez',
        recievedTime: 'Dermatology',
        AppointmentTime: "Wed August 27, 2023 10:00 AM to 10:15 AM",
        Button: "Reschedule",
        appointment: "Appointment Date"
    },
]

interface AppointmentProps {
    ComponentTitle?: "Standard Platform" | "Customize"
  }
    const UpcomingAppointments = ({ComponentTitle}:AppointmentProps) => {
    const { themeColor } = useSelector((state: RootState) => state.app);
    const { selectedPrimaryColor, selectedSecondaryColor } = useSelector((state: RootState) => state.onBoarding.selectedColors)
    const seletedType = useSelector((state: RootState) => state.onBoarding.selectedType)

    const clock = `<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.15042 9.58209C2.90998 9.58209 1.09375 7.76583 1.09375 5.52542C1.09375 3.28498 2.90998 1.46875 5.15042 1.46875C7.39083 1.46875 9.20709 3.28498 9.20709 5.52542C9.20709 7.76583 7.39083 9.58209 5.15042 9.58209ZM5.15042 8.77075C6.94278 8.77075 8.39575 7.31778 8.39575 5.52542C8.39575 3.73307 6.94278 2.28008 5.15042 2.28008C3.35807 2.28008 1.90508 3.73307 1.90508 5.52542C1.90508 7.31778 3.35807 8.77075 5.15042 8.77075ZM5.55608 5.52542H7.17875V6.33675H4.74475V3.49708H5.55608V5.52542Z" fill="${ComponentTitle === "Standard Platform" ? "black" :selectedPrimaryColor}"/>
    </svg>`


    const style = {
        header: {
            fontWeight: "700",
            fontSize: "10px",
            color: ComponentTitle === "Standard Platform" ? "#516cd3" : selectedPrimaryColor
        },
        first: {
            background: ComponentTitle === "Standard Platform" ? "" : lightenColor(selectedPrimaryColor, 0.8),
            borderColor: ComponentTitle === "Standard Platform" ? "#516cd3" :selectedPrimaryColor,
            color: "#000 !important"
        },
        task: {
            border: "none",
            background: "#ffffff"
        },
        section: {
            backgroundColor: ComponentTitle === "Standard Platform" ? "#F1F3F6" : selectedSecondaryColor,
            border: ComponentTitle === "Standard Platform" ? "1px solid #DFE0DF" : `1px solid ${selectedSecondaryColor}`
        },
        box: {
            margin: 0
        },
        button: {
            color: "#000",
            borderColor: ComponentTitle === "Standard Platform" ? "" : selectedPrimaryColor,
            fontSize: "9px",
            fontWeight: 400
        },
        firstBtn: {
            color: "#fff",
            borderColor: ComponentTitle === "Standard Platform" ? "#516cd3" :selectedPrimaryColor,
            background: ComponentTitle === "Standard Platform" ? "#516cd3" :selectedPrimaryColor,
            fontSize: "9px",
            fontWeight: 700
        }
    }

    return (
        <section className='UpcomingAppointments-section screen-upcomingAppointment home-page'>
            <h3 style={style.header} className='pb-2'>{seletedType == "Provider" ? "Today's" : "Upcoming"} Appointments</h3>
            <div className='outer-section' style={style.section}>
                <div className=''>
                    <div className='details-section'>
                        <div className="task-list-box">
                            <div className="task-list-box" style={style.box}>
                                {taskList.map((task: TaskList, index: number) => (
                                    <>
                                        <div className="tasklist-item" key={`task_details_${index}`} style={index === 0 ? style.first : style.task}>                                           
                                         <p className='pt-1' style = {{color : index === 0 ? "black" : ""}}>{task.appointment}</p>
                                            <div className={`d-flex align-items-center clock-img pt-2 gap-2 ${index === 2 ? "d-none" : ""}`} >
                                                 <div dangerouslySetInnerHTML={{ __html: clock }} />
                                                <h4 style = {{color : index === 0 ? "black" : ""}}>{task.AppointmentTime}</h4>

                                            </div>
                                            <hr />
                                            <div className='d-flex justify-content-between align-items-center pt-1 w-100'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ImageHandler
                                                        theme={themeColor}
                                                        imageName={task.image}
                                                        className={index === 2 ? "d-none" : "profile-img"}
                                                    />
                                                    <div>
                                                        <h2 className='commonFont-10' style = {{color : index === 0 ? "black" : ""}}>{task.doctorName}</h2>
                                                        <p style = {{color : index === 0 ? "black" : ""}}>{task.recievedTime}</p>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <button className="btn pro-light-btn" style={index === 0 ? style.firstBtn : style.button}>
                                                        {task.Button}
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default UpcomingAppointments
