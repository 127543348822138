
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../..';

interface FetchProviderProfileDataArgs {
    userId?: string;
    authToken: string;
}

interface ProviderProfileData {
    userData: any;
}

// Create the Thunk action
export const fetchProviderProfileData = createAsyncThunk<ProviderProfileData, FetchProviderProfileDataArgs, { state: RootState }>(
    'profile/fetchProviderProfileData',
    async ({ userId, authToken }, { dispatch }) => {

        try {
              const ApiEndPoint = `${ApiUrl.user_profile.get_provider_profile}?userId=${userId}`;
            const response = await get(ApiEndPoint, authToken) || []

            if (response) {
                return response.data ? response.data : response; // This value will be the payload of the fulfilled action
            } else {
                throw new Error('Failed to fetch Provider profile data');
            }
        } catch (error) {
            console.log("🚀 ~ error:", error,error)
            
            throw error; // This will trigger the rejected action
        }
    }
);
