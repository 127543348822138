import { createSlice } from "@reduxjs/toolkit";
import { fetchAdminScheduleAppoinments } from "../../Thunk/AdminSchedule/getAdminScheduleAppointmentThunk";

export interface AppState {
    AdminschedulingData: any;
    isLoading: boolean;
    error: string | null | undefined;
}

const initialState: AppState = {
    AdminschedulingData: null,
    isLoading: false,
    error: null,
};

const AdminScheduleAppointmentSlice = createSlice({
    name: "adminSchedule",
    initialState,
    reducers:{
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminScheduleAppoinments.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAdminScheduleAppoinments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.AdminschedulingData = action.payload;
            })
            .addCase(fetchAdminScheduleAppoinments.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
})

export default AdminScheduleAppointmentSlice;