
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ApiUrl } from '../../../../Network/ApiUrl';
import { post } from '../../../../Network/ApiService';
import { GetPatientInformation } from './GetPatientInformationThunk';
import { HippaConsentPayload, InformationReleasePayload, MedicalHistoryPayload, PatienInformationPayload, TelehealthConsentPayload } from '../../../Interfaces/FormsInterface/formInterFace';
import { setIsFirstLogin } from '../../../Slicers/PatientFormsData/PatientFormsDataSlicer';
import { ToastMessage } from '../../../../Components/Common/ToastMessage';

interface PatientInformationArgs {
    patient_id: string,
    payload: PatienInformationPayload | MedicalHistoryPayload | HippaConsentPayload | TelehealthConsentPayload | InformationReleasePayload;
    authToken: string;
    type: "patientInformation" | "medicalHistory" | "HIPPAConscent" | "TelhealthConscent" | "InformationRelease";
}

// Define the return type for the Thunk action
interface PatientInformationData {
    messages: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const SavePatientInformation = createAsyncThunk<PatientInformationData, PatientInformationArgs, { state: any }>(
    'myForms/SavePatientInformation',
    async ({ type, patient_id, payload, authToken }, { dispatch }) => {
        try {
            let Endpoint;
            switch (type) {
                case 'patientInformation':
                    Endpoint = ApiUrl.myForms.patient_information.patient_create_information;
                    break;
                case 'medicalHistory':
                    Endpoint = ApiUrl.myForms.patient_history.patient_create_history;
                    break;
                case 'HIPPAConscent':
                    Endpoint = ApiUrl.myForms.patient_hippa_consent.patient_create_hippa_consent
                    break;
                case 'TelhealthConscent':
                    Endpoint = ApiUrl.myForms.patient_telehealth_consent.patient_create_telehealth_consent
                    break;
                    case 'InformationRelease':
                    Endpoint = ApiUrl.myForms.patient_information_release.patient_create_information_release
                    break;
            }
            const ApiEndPoint = `${Endpoint}?patient_id=${patient_id}`;
            const response = await post(ApiEndPoint, authToken, payload);
            if (response.status === 200) {
                toast.dismiss()
                ToastMessage("success","Data saved successfully");
                dispatch(GetPatientInformation({ type, patient_id, authToken }))
                await dispatch(setIsFirstLogin(false));
                return response.data;
            } else {
                toast.dismiss()
                ToastMessage("error","Error in saving Patient Data.")
                await dispatch(setIsFirstLogin(false));
                throw new Error('Error in saving Patient Data.');
            }
        } catch (error) {
            toast.dismiss()
            ToastMessage("error","Error in saving Patient Data")
            await dispatch(setIsFirstLogin(false));
            throw error;
        }
    }
);
