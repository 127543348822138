
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiUrl } from '../../../Network/ApiUrl';
import { get } from '../../../Network/ApiService';
import { RootState } from '../../index';

interface FetchEntryPageProvidersDataArgs {
    customer_domain: string;
}

// Define the return type for the Thunk action
interface EntryPageProvidersData {
    userData: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const fetchEntryPageProviders = createAsyncThunk<EntryPageProvidersData, FetchEntryPageProvidersDataArgs, { state: RootState }>(
    'waitingRoom/fetchEntryPageProviders',
    async ({ customer_domain }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.get_entry_page_providers}?customer_domain=${customer_domain}`;
            const response = await get(ApiEndPoint);

            if (response) {
                return response.data;
            } else {
                throw new Error('Failed to fetch Waiting Room Data');
            }
        } catch (error) {
            throw error; // This will trigger the rejected action
        }
    }
);
