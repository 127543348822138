import React from 'react'
import { CloseModelIcon } from '../../../../Assets/Svgs'

import './CompleteProfileModal.scss'
import { useNavigate } from 'react-router-dom'

interface CompleteProfileModaltype {
    isProfileModalOpen: boolean;
    handleClose?: any;
    navigateUrl: string;
}
const CompleteProfileModal = ({ isProfileModalOpen, handleClose, navigateUrl }: CompleteProfileModaltype) => {
    const navigate = useNavigate()
    return (
        <>
            <div className="modal-backdrop" />
            <div
                className={`modal fade ${isProfileModalOpen ? "show" : ""} complete-profile-modal`}
                style={{ display: isProfileModalOpen ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header border-0 p-0">
                            <h5 className="modal-title profile-modal-title">Complete your Profile</h5>
                            {/* <img src={CloseModelIcon} alt="Close" className='close-model-icon' style={{ width: "20px" }} onClick={handleClose} /> */}
                        </div>
                        <div className="modal-body p-0">
                            <div className="complete-profile-modal-content-section">
                                <p>
                                    Complete your profile to gain access to the platform
                                </p>
                            </div>
                            <div className="complete-profile-btns pt-4">
                                {/* <button
                                    className="back-profile-theme-btn"
                                    onClick={handleClose}
                                >
                                    Back
                                </button> */}
                                <button
                                    className="rounded-save-theme-btn"
                                    onClick={() => {navigate(navigateUrl);handleClose()}}
                                >
                                    Complete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompleteProfileModal