import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../ProviderLoginFlow/WaitingScreen.scss'
import { LandingLogo, LoginPopupBG, Padlock } from '../../../../../Assets/Svgs';
import { setProviderplan } from '../../../../../Store/Slicers/commonSlicer';
import ForgotPassword from '../../../../../Components/Common/ForgotPassword';

const ProviderDemoLogin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState<boolean>(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [forgotModal, setForgotModal] = useState<any>(false)


    const handleSubmit = (e: any) => {
        dispatch(setProviderplan("clinic"));
        navigate('/provider-demo-appointments')
    };

    const handleResetPassword = () => {
        setForgotModal(true)
    }

    const handlePassword = () => {
        navigate('/forgot-password', {
            state: "demo-center"
        })
    }
    return (
        <div>
            <div className="background-container" >
                <img src={LoginPopupBG} alt="" />
            </div>
            <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div
                    className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center modal-lg`}
                >
                    <div className="modal-content bg-transparent p-0 shadow-none border-0 overflow-visible"
                        style={{ maxWidth: "628px" }}>
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup">
                                {
                                    forgotModal ?
                                        <div className="waiting-login">
                                            <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Let’s setup a new password <img src={Padlock} alt="" />
                                            </h1>
                                            <p className="desc">Let’s setup a new password
                                            </p>
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <form
                                                        className="login-form d-flex flex-column"
                                                        onSubmit={handlePassword}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                        >
                                                            Continue
                                                        </button>
                                                    </form>
                                                </div>
                                                <div
                                                    className={`text-center login-logo`}
                                                >
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="waiting-login">
                                            <h1 className="waiting-header">Welcome Back!
                                            </h1>
                                            <p className="desc">Sign in to start seeing your patients
                                            </p>
                                            <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                                <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                                    <h1 className="signin-header">Sign In</h1>
                                                    <form
                                                        className="login-form d-flex flex-column"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your Email Address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            autoComplete="new-email"
                                                        />
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            autoComplete="new-password"
                                                        />
                                                        <div className="remember d-flex align-items-center">
                                                            <input type="checkbox" />
                                                            <span>Remember me on this computer</span>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                        >
                                                            Sign In
                                                        </button>
                                                        <p
                                                            onClick={handleResetPassword}
                                                            className="forgotpassword-title">Forgot Password
                                                        </p>
                                                    </form>
                                                </div>
                                                <div className='signUp-tag'>
                                                    Don’t have a Telehealth account <span onClick={() => navigate('/provider-demo-signup')}>Signup</span> today
                                                </div>
                                                <div
                                                    className={`text-center login-logo`}
                                                >
                                                    <img src={LandingLogo} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProviderDemoLogin