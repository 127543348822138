import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiUrl } from "../../../../Network/ApiUrl";
import { get } from "../../../../Network/ApiService";
import { RootState } from "../../..";

interface StatusParams {
    from_date_time: string
    to_date_time: string
    status: "canceled" | "requested" | "pending", authToken: string
}

export const fetchAppointmentByDateStatus = createAsyncThunk<any, StatusParams, { state: RootState }>(
    "appointment/fetchAppointmentByDateStatus",
    async ({ from_date_time, to_date_time, status, authToken }, { dispatch }) => {
        try {
            let ApiEndPoint = `${ApiUrl.appointments.get_appointment_by_date_status}?status=${status}&from_date_time=${from_date_time}&to_date_time=${to_date_time}`;
            const response = await get(ApiEndPoint, authToken);
            if (response) {
                return response.data;
            } else {
                throw new Error("Failed to fetch Appointment Cancellation Data");
            }
        } catch (error: any) {
            throw error;
        }
    }
);