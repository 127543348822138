import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";

// utils components
import './sidebar.css'
import ReactDOM from "react-dom";
import { medicalRecordIcon } from "../../../../Assets/Svgs";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";


const SidebarButtons = ({ themeColor, className }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [isMedicalRecords, setIsMedicalRecords] = useState(false)

  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const pathname = location.pathname;
    setCurrentPath(pathname);
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [location, navigate]);

  useEffect(() => {
    const handleScroll = () => {
      setIsMedicalRecords(false)
    };
    const innerScrollElement = document.querySelector('.sidebar-wrapper');
    if (innerScrollElement) {
      innerScrollElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (innerScrollElement) {
        innerScrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (tooltipRef?.current) {
      const rect: any = tooltipRef?.current?.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top + window.scrollY + rect.height,
        left: rect.left + window.scrollX,
      });
    }
  }, [isMedicalRecords]);

  return (
    <>
      {ReactDOM.createPortal(
        <Tooltip isVisible={isMedicalRecords} position={tooltipPosition} title={'The 21st Century Cures Act enables effortless access and organization of health data from various care sources. Simply log into your healthcare providers among the 26,000+ connected facilities to consolidate and categorize your records, ensuring your providers have updated information.'} />,
        document.body
      )}
      <div className={`sidebar-buttons-box patient-btn-box ${themeColor}-${className}`}>
        <button
          className={
            currentPath === "/connect-my-medical-records"

              ? "btn patientSidebtn active"
              : "btn patientSidebtn"
          }
          onClick={() => navigate("/connect-my-medical-records")}
          data-bs-dismiss={deviceWidth > 767 ? "" : "offcanvas"}
        >
          {/* <img src={PatientSidebarCloud} alt="" height="64px" width="64px"/> */}
          <span className="btn-text">Consolidate my Medical Records</span>
          <div className="d-flex gap-2 align-items-center pt-1">
            <p className="btn-below-text" >Let's Connect</p>
            <FontAwesomeIcon icon={faArrowRight} className="medicalRecordIcon" />
            <img src={medicalRecordIcon} alt="" className="SelectInfo-icon " />
          </div>
          {/* <div className="d-flex position-relative"
            id="medicalRecordsTooltip"
            onMouseEnter={() => setIsMedicalRecords(true)}
            onMouseLeave={() => setIsMedicalRecords(false)}
            ref={tooltipRef}
          >
          </div> */}
        </button>

      </div>
    </>
  );
};

export default SidebarButtons;
